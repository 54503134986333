
app.controller('followCtrl', ['$scope', 'Session','Toast','$mdDialog','BackendService','$q','$filter',
    '$rootScope',
    function ($scope, Session,Toast,$mdDialog,BackendService,$q,$filter,
              $rootScope) {

        $scope.form = {};

        $scope.title = "Follow";

        $scope.follow = undefined;
        $scope.loading = true;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        var user = Session.getSession().getUser();
        $scope.save = function () {
            if ($scope.users.selectedItem !== null) {
                console.log($scope.users.selectedItem);
                BackendService.doFollowUserReq(user.id,$scope.users.selectedItem.userId, function (response) {
                    console.log("follow user!",response);
                    Toast.success("User was successfully followed.");
                    $rootScope.$broadcast("profile-refresh",{type:"following"});
                    $mdDialog.hide($scope.follow);
                }, function (error) {
                    console.log('Follow user error: ', error);
                });
            }
        };

        $scope.users = {
            selectedItem: null,
            searchText: '',
            deferred: undefined,
            all:null,
            getSearch: function () {
                if(this.all === null) {
                    this.deferred = $q.defer();
                    var d = this.deferred;
                    var thiz = this;
                    BackendService.doGetUserReq(100, null, function (response, message) {
                        thiz.all = response.users;
                        _.forEach(thiz.all,function (item) {
                            item.fullName = item.firstName + " " + item.lastName;
                        });
                        d.resolve(thiz.getSearch());
                    }, function (error) {
                        console.log('Get users error: ', error);
                        thiz.all = [];
                        d.resolve(thiz.all);
                    });
                    return this.deferred.promise;
                }else{
                    var s = this.searchText.toLowerCase().split(" ");
                    return $filter('filter')(this.all, {fullName: this.searchText}, false);
                }
            }
        };


    }]);
