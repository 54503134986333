
app.controller('educationUpdateCtrl', ['$scope', 'Session','Toast','$mdDialog','BackendService','$q','$mdConstant',
    'currentEducation','$rootScope',
    function ($scope, Session,Toast,$mdDialog,BackendService,$q,$mdConstant,
              currentEducation,$rootScope) {

        $scope.form = {};

        $scope.isAdd = currentEducation === null;

        $scope.title = $scope.isAdd?"Add education":"Update education";

        if($scope.isAdd){
            $scope.education = {
                id: UUID.generate(),
                school: "",
                schoolId: "",
                degree: "",
                fos: "",
                description: "",
                yearFrom: undefined,
                yearTo: undefined,
                urls: []
            };
        }else{
            $scope.education = currentEducation;
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        var user = Session.getSession().getUser();
        $scope.save = function () {
            if ($scope.form.education.$valid) {
                console.log($scope.education);
                BackendService.doUpdateEducation(user.id,$scope.education, function (response) {
                    console.log("update user education!",response);
                    if($scope.isAdd){
                        Toast.success("Education was successfully added.");
                    }else{
                        Toast.success("Education was successfully updated.");
                    }
                    $rootScope.$broadcast("profile-refresh",{type:"education"});
                    $mdDialog.hide($scope.education);
                }, function (error) {
                    console.log('Update Education error: ', error);
                });
            }
        };

        $scope.fos = {
            selectedItem: undefined,
            searchText: undefined,
            deferred: undefined,
            getSearch: function () {
                this.deferred = $q.defer();
                var dto = {
                    value: angular.isDefined(this.searchText) ? this.searchText.toLowerCase() : undefined,
                    top: 10,
                    type: Search.SearchDataType.FOS
                };
                var d = this.deferred;
                BackendService.doSearchVocabularyReq(dto, function (response, message) {
                    console.log(response);
                    if(_.isObject(response) && _.isArray(response.keywords)){
                        var results = response.keywords;
                        d.resolve(_.pluck(results,'name'));
                    }
                    d.resolve([]);
                }, function (error) {
                    console.log('FOS Vocabulary error: ', error);
                    d.resolve([]);
                });
                return this.deferred.promise;

            }

        };


    }]);
