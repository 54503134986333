'use strict';

app.service('Session', [
    'SessionModel', 'localstorage','$cookies','$rootScope','BackendService','$window','config',
    function (SessionModel, localstorage,$cookies,$rootScope,BackendService,$window,config) {
        var service = this;

        service.session = null;

        service.isUserLoggedIn = function (cb) {
            cb(service.getSession().isLoggedIn());
        };

        service.getSession = function () {
            if (service.session === null) {
                service.session = localstorage.getObj('session', new SessionModel(service));
            }

            return service.session;
        };

        service.getSessionToken = function () {
            if (service.session !== null && service.session.isLoggedIn()) {
                return service.session.getToken();
            }
            var c = $cookies.get("sessionToken");
            if(c){
                return c;
            }
            return null;
        };

        service.saveSession = function () {
            localstorage.setObj('session', service.session);
            if(service.session && service.session.token && service.session.tokenExpirationTime){
                $cookies.put("sessionToken",service.session.token,{ expires: new Date(service.session.tokenExpirationTime)});
            }
            return service.session;
        };

        service.logout = function () {
            BackendService.doLogout(function () {
                $cookies.remove("sessionToken");
                service.session = null;
                $window.localStorage.clear();
                // successful logout

                console.log("session");
                $rootScope.$broadcast(config.signin.service_base.logout_event, {
                    provider: config.signin.providers.google
                });
            }, function () {
                // TODO implement
                // error while logout
            });
        };
    }
]);