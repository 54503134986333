'use strict';

app.controller('reviewsCtrl', [
    '$scope', 'config', 'Session', 'BackendService','ResearchItemModel','ReviewService','$mdDialog','$state','$q','Toast',
            'ReviewModel',
    function ($scope, config, Session, BackendService,ResearchItemModel,ReviewService,$mdDialog,$state,$q,Toast,
              ReviewModel) {

        $scope.loading = true;
        $scope.user = Session.getSession().getUser();
        $scope.control = {};
        $scope.items = {
            all: [],
            other: [],
            publish: [],
            pending: []
        };
        $scope.users = {};

        $scope.tabs = {
            selectedIndex: 0,
            all: [{id: 'all', name: 'All'},
                {id: 'other', name: 'Other'},
                {id: 'pending', name: 'Pending'},
                {id: 'publish', name: 'Published'}]
        };

        $scope.ReviewService = ReviewService;


        var getArticles = function (ids) {
            var deferred = $q.defer();
            BackendService.doGetEsReq(ids,
                function (response) {
                    console.log("Success in getArticles", response);
                    deferred.resolve(response.documents);
                }, function (error) {
                    console.log("Error in getArticles", error);
                    deferred.reject();
                });
            return deferred.promise;
        };

        $scope.getMyReviews = function () {
            $scope.loading = true;
            $scope.items = {
                all: [],
                other: [],
                publish: [],
                pending: []
            };
            BackendService.doGetReviewReq($scope.user.id,null,
                function (response) {
                    console.log("Success in getMyReviews", response);
                    var reviews = response.reviews;
                    if(reviews && reviews.length>0) {
                        var ids = _.pluck(reviews,"articleId");
                        getArticles(ids).then(function (documents) {
                            console.log("Success in getMyReviews 2", documents);
                            $scope.items.all = [];
                            for(var i = 0; i < reviews.length; i++){
                                var r = reviews[i];
                                var a = _.findWhere(documents,{id: r.articleId});
                                $scope.items.all.push(new ReviewModel(r,{article: {data: new ResearchItemModel(a)}, user: $scope.user}));
                            }
                            $scope.items.other = _.filter($scope.items.all,function (item) {
                                return item.editStatus < Publish.EditStatus.COMPLETED;
                            });
                            $scope.items.publish = _.filter($scope.items.all,function (item) {
                                return item.publishStatus === Publish.PublishStatus.BC_COMPLETED;
                            });
                            $scope.items.pending = _.filter($scope.items.all,function (item) {
                                return item.publishStatus === Publish.PublishStatus.PENDING;
                            });
                            $scope.loading = false;
                        },function () {
                            console.log("Error in getMyReviews 2");
                            $scope.loading = false;
                        });
                    } else{
                        $scope.loading = false;
                    }
                }, function (error) {
                    console.log("Error in getMyReviews", error);
                    $scope.loading = false;
                });
        };
        
        
        $scope.refresh = function () {
            $scope.getMyReviews();
        };
        $scope.refresh();




    }
]);