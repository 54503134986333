'use strict';

app.factory('UserModel', [
    function () {
        function UserModel(object) {
            if (object) {
                this.update(object);
            }
        }

        UserModel.prototype.update = function(object){
            this.id = object.userId;
            this.firstName = object.firstName;
            this.lastName = object.lastName;
            this.pictureUrl = object.pictureUrl;
            this.active = object.active;
            this.bcAddress = object.bcAddress;
            this.confirmed = object.confirmed;
            this.flow = object.flow;
            this.fos = object.fos;
            this.keywords = object.keywords;
            this.layoutSettings = object.layoutSettings;
            this.lockedTime = object.lockedTime;
            this.phone = object.phone;
            this.profile = object.profile;
            this.role = object.role;

            return this;
        };

        return UserModel;
    }
]);

app.factory('SessionModel', [
    'UserModel',
    function (UserModel) {
        var service = null;

        function SessionModel(s,object) { //s is service
            if(object) {
                this.id = object.id;
                this.token = object.token;
                this.tokenExpirationTime = object.tokenExpirationTime;
                this.user = object.user || new UserModel();
            }else{
                this.user = UserModel();
            }
            if(s){
                service = s;
            }
        }

        var saveSession = function () {
            if(service) service.saveSession();
        };

        /**
         * Checks whether user is logged in.
         *
         * @return {boolean} true if user is logged in, false otherwise
         */
        SessionModel.prototype.isLoggedIn = function () {
            return this.getToken() !== null && this.getToken() !== undefined;
        };

        /**
         * Sets User object to Session.
         *
         * @param {Object} user The User object
         */
        SessionModel.prototype.setUser = function (user) {
            this.user = new UserModel(user);
            saveSession();
        };

        /**
         * Gets User object.
         *
         * @return {UserModel} The User object
         */
        SessionModel.prototype.getUser = function () {
            return this.user;
        };

        /**
         * Sets Session ID.
         *
         * @param {String} id The Session ID
         */
        SessionModel.prototype.setId = function (id) {
            this.id = id;
            saveSession();
        };

        /**
         * Gets Session ID.
         *
         * @return {String} The Session ID
         */
        SessionModel.prototype.getId = function () {
            return this.id;
        };

        /**
         * Sets Session token.
         *
         * @param {String} token The token
         */
        SessionModel.prototype.setToken = function (token,tokenExpirationTime) {
            this.tokenExpirationTime = tokenExpirationTime;
            this.token = token;
            saveSession();
        };

        /**
         * Gets Session token.
         *
         * @return {String} The token
         */
        SessionModel.prototype.getToken = function () {
            return this.token;
        };

        return SessionModel;
    }
]);