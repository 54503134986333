/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("commentInList", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'CommentModel', '$rootScope','BackendService','CommentService','EntityService','Session',
    function ($timeout, $state, $mdDialog, $window, config, CommentModel, $rootScope,BackendService,CommentService,EntityService,Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/comment/comment-in-list.directive.html',
            scope: {
                control: '=',
                item: '='
            },
            link: function (scope, element, attrs) {
                scope.CommentService = CommentService;
                scope.EntityService = EntityService;

                // if(!(scope.item instanceof CommentModel)){
                //     scope.item = new CommentModel(scope.item);
                // }

                scope.onInitiateChat = function (userId) {
                    $rootScope.$broadcast('ChatService.initiateChat', {
                        userId: userId
                    });
                };


            }
        };
    }]);
