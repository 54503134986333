'use strict';

app.directive('chatboxContent', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'app/pages/chat/chatbox-content/chatbox-content.html',
            replace: true,
            link: link,
            scope: {
                chat: '='
            }
        };

        function link(scope, element, attrs) {
            var userId = scope.chat && scope.chat.userId ? scope.chat.userId.replace(/@/, '-') : '';
            scope.id = 'chatbox-content-' + userId;
            scope.testValue = '123456';

            scope.isSameDirectionAsPrevious = function (currentIndex) {
                var previousIndex = currentIndex - 1;
                if (previousIndex > 0) {
                    var previous = scope.chat.messages[previousIndex];
                    var current = scope.chat.messages[currentIndex];

                    return previous.direction === current.direction;
                }

                return false;
            };

            scope.prepareCssClasses = function (index, historyClass, directionClass) {
                var obj = {};
                var message = scope.chat.messages[index];
                if(message) {
                    obj[historyClass] = message.isHistory;
                    obj[directionClass] = !scope.isSameDirectionAsPrevious(index);
                }
                return obj;
            };

            scope.getMessageTime = function (msg) {
                var m = moment(msg.date);
                return m.format('HH:mm');
            };

            scope.isSameDayAsPrevious = function (currentIndex) {
                var previousIndex = currentIndex - 1;
                if (previousIndex > 0) {
                    var previous = scope.chat.messages[previousIndex];
                    var current = scope.chat.messages[currentIndex];

                    if (previous.date && current.date) {
                        if (previous.date.getFullYear() === current.date.getFullYear() &&
                            previous.date.getMonth() === current.date.getMonth() &&
                            previous.date.getDay() === current.date.getDay()) {
                            return true;
                        }
                    }
                }

                return false;
            };

            scope.getMessageDate = function (msg) {
                var m = moment(msg.date);
                return m.format('dddd Do');
            };
        }
    }
]);