
app.controller('researchItemDetailsCtrl', ['$scope', 'Session','currentArticle','Toast','$mdDialog','BackendService',
    function ($scope, Session, currentArticle,Toast,$mdDialog,BackendService) {


        $scope.article = currentArticle;


        $scope.cancel = function () {
            $mdDialog.cancel();
        };

    }]);
