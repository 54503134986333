'use strict';

app.service('ConnectionService', [
    '$q', 'config', '$location','$cookies', '$rootScope', '$window',
    function ($q, config, $location, $cookies, $rootScope, $window) {
        var self = this;
        var ConnectionStatus = {
            CLOSED: 1,
            CONNECTING: 2,
            CONNECTED: 3
        };

        var requestId = 0;
        var requestsArray = [];

        var connection = {
            socket: null,
            status: ConnectionStatus.CLOSED,
            sessionId: null
        };

        self.getConnection = function () {
            var defer = $q.defer();

            if (isConnected(connection) || isConnecting(connection)) {
                // we are already connected or already connecting
                defer.resolve();
                return defer.promise;
            }

            connection.status = ConnectionStatus.CONNECTING;
            console.log('Connecting...');

            try {
                connection.socket = new WebSocket(getWebSocketAddress());
                connection.socket.binaryType = "arraybuffer";
            } catch (err) {
                // TODO use logger instead
                console.error(err);
            }

            connection.socket.onopen = function (event) {
                console.log('Connected.');
                connection.status = ConnectionStatus.CONNECTED;
                connection.sessionId = UUID.generate();
                defer.resolve();
            };

            connection.socket.onclose = function (event) {
                console.log('Connection closed.')
                // TODO should we logout?
                connection.status = ConnectionStatus.CLOSED;
                defer.reject('Connection closed.');
            };

            connection.socket.onerror = function (event) {
                console.error('Connection error', event)
                // TODO put error into log?
                defer.reject(event);
            };

            connection.socket.onmessage = function (event) {
                onMessage(event.data);
            };

            return defer.promise;
        };

        self.sendMessage = function (mType, data, onResponse, onError) {
            self.getConnection()
                .then(function () {
                    sendMessage(mType, data, onResponse, onError);
                }, function (error) {
                    // TODO use log
                    console.log('Error while retrieving connection', error)
                });
        };

        function isConnected(connection) {
            return connection.status === ConnectionStatus.CONNECTED;
        }

        function isConnecting(connection) {
            return connection.status === ConnectionStatus.CONNECTING;
        }

        /**
         * Gets the next request ID number.
         * @return {number} The request ID
         */
        function nextRequestId() {
            return requestId++;
        }

        function onMessage(rawMessage) {
            // TODO put in log
            console.log('On message triggered')
            var serializedMessage = new Thrift.TBinaryProtocol({});
            serializedMessage.buffer = new Uint8Array(rawMessage);

            var message = new Msg.Msg();
            message.read(serializedMessage);

            var serializedValue = new Thrift.TBinaryProtocol({});
            serializedValue.buffer = new Uint8Array(message.value);

            var valueObject = getValueObject(message.type);
            if (valueObject !== null) {
                valueObject.read(serializedValue);
                message.value = valueObject;
            } else {
                message.value = null;
            }

            // TODO put in log
            console.log('RECEIVED', message)
            var requestData = popRequestData(message.reqId);
            if (requestData !== null) {
                if (message.type === Msg.MsgType.ERROR && angular.isFunction(requestData.onErrorCallback)) {
                    $rootScope.$apply(function () {
                        // calls callback within angular scope
                        requestData.onErrorCallback(message.value, message);
                    });
                } else if (angular.isFunction(requestData.onResponseCallback)) {
                    $rootScope.$apply(function () {
                        // calls callback within angular scope
                        requestData.onResponseCallback(message.value, message);
                    });
                }
            } else {
                // TODO put in log
                console.error('No request data for request: ' + message.reqId);
            }
        }

        function sendMessage(mType, data, onResponse, onError) {
            var requestId = nextRequestId();

            // prepare serialized payload
            var serializedData = new Thrift.TBinaryProtocol({});
            data.write(serializedData);


            var token = $cookies.get("sessionToken");
            if(token && mType === Msg.MsgType.LOGIN_REQ && data.email !== null){
                token = undefined;
            }

            var message = new Msg.Msg({
                type: mType,
                value: new Uint8Array(serializedData.buffer),
                reqId: requestId,
                sessionId: connection.sessionId,
                sessionToken: token
            });

            var serializedMessage = new Thrift.TBinaryProtocol({});
            message.write(serializedMessage);

            var preparedMessage = new Uint8Array(serializedMessage.buffer);

            // push request details to requestsArray so we can match response properly
            pushRequestData({
                requestId: requestId,
                onResponseCallback: onResponse,
                onErrorCallback: onError
            });

            // TODO use log
            console.log('Sending message', JSON.stringify(new Msg.Msg({
                type: mType,
                value: data,
                reqId: requestId,
                sessionId: connection.sessionId,
                sessionToken: token
            })));

            connection.socket.send(preparedMessage, {binary: true});
        }

        function pushRequestData(data) {
            requestsArray.push(data);
        }

        function popRequestData(requestId) {
            for (var i = 0; i < requestsArray.length; i++) {
                if (requestsArray[i].requestId === requestId) {
                    return requestsArray.splice(i, 1)[0];
                }
            }

            return null;
        }

        function getValueObject(type) {
            switch (type) {
                case Msg.MsgType.ERROR:
                    return new Msg.ErrorMsg();
                case Msg.MsgType.EMPTY:
                    return null;
                case Msg.MsgType.LOGIN_RESP:
                    return new User.LoginResp();
                case Msg.MsgType.GET_USER_RESP:
                    return new User.GetUserResp();
                case Msg.MsgType.DELETE_USER:
                    return new User.DeleteUser();
                case Msg.MsgType.SIGNUP_RESP:
                    return new User.SignupResp();
                case Msg.MsgType.GET_CAPTCHA_RESP:
                    return new User.GetCaptchaResp();
                case Msg.MsgType.GET_SESSION_RESP:
                    return new User.GetSessionResp();
                case Msg.MsgType.GET_EDUCATION_RESP:
                    return new User.GetEducationResp();
                case Msg.MsgType.GET_EXPERIENCE_RESP:
                    return new User.GetExperienceResp();
                case Msg.MsgType.GET_FOLLOWERS_RESP:
                    return new User.GetFollowersResp();
                case Msg.MsgType.GET_FOLLOWING_RESP:
                    return new User.GetFollowingResp();
                case Msg.MsgType.GET_FOLLOWING_TIMELINE_RESP:
                    return new User.GetFollowingTimelineResp();
                case Msg.MsgType.FOLLOW_USER_RESP:
                    return new User.FollowUserResp();
                case Msg.MsgType.UNFOLLOW_USER_RESP:
                    return new User.UnfollowUserResp();
                case Msg.MsgType.GET_USER_BY_USER_ID_RESP:
                    return new User.GetUserByUserIdResp();
                case Msg.MsgType.GET_NOTIFICATION_RESP:
                    return new User.GetNotificationResp();
                case Msg.MsgType.SEARCH_ES_RESP:
                    return new Search.SearchEsResp();
                case Msg.MsgType.GET_WEBSHOT_RESP:
                    return new Webshot.GetWebshotResp();
                case Msg.MsgType.GET_ES_RESP:
                    return new Search.GetEsResp();
                case Msg.MsgType.SEARCH_VOCABULARY_RESP:
                    return new Search.SearchVocabularyResp();
                case Msg.MsgType.SET_USER_FLOW_RESP:
                    return new User.SetUserFlowResp();
                case Msg.MsgType.GET_USER_FLOW_RESP:
                    return new User.GetUserFlowResp();
                case Msg.MsgType.GET_MY_PUBLISH_RESP:
                    return new Publish.GetMyPublishResp();
                case Msg.MsgType.UPDATE_REVIEW_RESP:
                    return new Publish.UpdateReviewResp();
                case Msg.MsgType.GET_REVIEW_RESP:
                    return new Publish.GetReviewResp();
                case Msg.MsgType.GET_PUBLISH_REVIEW_RESP:
                    return new Publish.GetPublishReviewResp();
                case Msg.MsgType.UPDATE_ARTICLE_RESP:
                    return new Publish.UpdateArticleResp();
                case Msg.MsgType.DELETE_ARTICLE_RESP:
                    return new Publish.DeleteArticleResp();
                case Msg.MsgType.GET_ARTICLE_RESP:
                    return new Publish.GetArticleResp();
                case Msg.MsgType.GET_PUBLISH_ARTICLE_RESP:
                    return new Publish.GetPublishArticleResp();
                case Msg.MsgType.SET_SOCIAL_OPERATION_RESP:
                    return new Publish.SetSocialOperationResp();
                case Msg.MsgType.GET_USER_IDS_FOR_SOCIAL_OPERATION_RESP:
                    return new Publish.GetUserIdsForSocialOperationResp();
                case Msg.MsgType.GET_SOCIAL_OPERATION_FOR_USER_ID_RESP:
                    return new Publish.GetSocialOperationForUserIdResp();
                case Msg.MsgType.GET_COMMENT_RESP:
                    return new Publish.GetCommentResp();
                case Msg.MsgType.UPDATE_COMMENT_RESP:
                    return new Publish.UpdateCommentResp();
                case Msg.MsgType.GET_COMMENT_FOR_ENTITY_RESP:
                    return new Publish.GetCommentForEntityResp();
            }
            return null;
        }

        function getWebSocketAddress() {
            var ws = $window.localStorage.getItem('ws');
            if (ws && angular.isString(ws) && ws.trim().length > 0) {
                console.log('Using ws=' + ws);
                return ws;
            }
            return config.connection.ws;
        }

        return this;
    }
]);