'use strict';

app.factory('loginProviderFacebook', [
    '$rootScope', 'config', 'BackendService', '$interpolate',
    function ($rootScope, config, BackendService, $interpolate) {

        function doInit() {
            // nothing to do for now
        }

        function doLogout() {
            // nothing to do for now
        }

        function doLogin() {
            var context = {
                appId: config.signin.providers.facebook.app_id,
                redirectUri: config.connection.root + config.signin.providers.facebook.redirectUrl,
                state: ''
            };

            var exp = $interpolate(config.signin.providers.facebook.loginRedirect);
            var redirectUrl = exp(context);

            // do a redirect to Facebook
            window.location.href = redirectUrl;
        }

        function doLoginToBackend(token, email, responseCallback, errorCallback) {
            BackendService.doLoginWithFacebook(token, email, responseCallback, errorCallback);
        }


        return {
            init: doInit,
            logout: doLogout,
            login: doLogin,
            loginToBackend: doLoginToBackend
        };
    }
]);