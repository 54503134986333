/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("researchItem", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'ResearchItemModel', '$rootScope','BackendService','ResearchItemService','Toast',
    'Session','ReviewService','EntityService',
    function ($timeout, $state, $mdDialog, $window, config, ResearchItemModel, $rootScope,BackendService,ResearchItemService,Toast,
              Session,ReviewService,EntityService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/research-item/research-item.directive.html',
            scope: {
                control: '=',
                item: '='
            },
            link: function (scope, element, attrs) {

                scope.ResearchItemService = ResearchItemService;
                scope.EntityService = EntityService;
                scope.ReviewService = ReviewService;


                scope.openMenu = function ($mdMenu, $event) {
                    $mdMenu.open($event);
                };

                scope.comments = {
                    show: false,
                    load: false
                };
                scope.onOpenComments = function (ev) {
                    scope.comments.load = true;
                    scope.comments.show = !scope.comments.show;
                }


            }
        };
    }]);
