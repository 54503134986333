app.factory("ReviewService", function(config,$window, $timeout,$http,Session,$q, Web3Service,$rootScope,$mdDialog,
                                       Toast) {

    var thiz = this;

    thiz.onAdd = function (item,ev) {
        $mdDialog.show({
            controller: 'commentUpdateCtrl',
            templateUrl: 'app/module/review/comment-update.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            locals: {
                currentReview: { articleId: item.id}
            }
        }).then(function (answer) {

        });
    };

    var user = Session.getSession().getUser();
    thiz.canReview = function(item){
        if(item===undefined){
            return false;
        }
        return Web3Service.isStatusDone() && item.claimedAuthorIds !== null && item.claimedAuthorIds.length>0
            && !_.contains(item.claimedAuthorIds,user.id);
    };

    thiz.getEditStatusText = function (status) {
        switch (status){
            case Publish.EditStatus.PENDING: return "Pending";
            case Publish.EditStatus.REVIEW: return "Review";
            case Publish.EditStatus.COMPLETED: return "Completed";
        }
        return "";
    };

    thiz.onUpdate = function (item,ev) {
        $mdDialog.show({
            controller: 'commentUpdateCtrl',
            templateUrl: 'app/module/review/comment-update.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            locals: {
                currentReview: angular.copy(item)
            }
        }).then(function (answer) {
            item.text = answer.text;
            item.editStatus = answer.editStatus;
            item.publishStatus = answer.publishStatus;
        });
    };

    thiz.onComplete = function (item,ev) {
        var r = {
            reviewId: item.reviewId,
            userId: item.userId,
            articleId: item.articleId,
            text: item.text,
            status: item.editStatus
        };

        Web3Service.doCreateEndorsementReview(r).then(function(response){
            console.log("Success in onCompletedReview",response);
            item.editStatus = response.editStatus;
            item.publishStatus = response.publishStatus;
            Toast.success("Review completed successfully!");
        },function () {
            console.log("Error in onCompletedReview");
            Toast.error("Completing review failed!");
        })
    }

    thiz.getBcData = function (item,$event) {
        console.log(item);
        Web3Service.doGetEndorsementReviewData(item.reviewId).then(function(response){
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title('Review on blockchain.')
                    .htmlContent('<div>Review Hash: '+response.rhash+'</div>'+
                        '<div>Article Hash: '+response.ahash+'</div>'+
                        '<div>Owner: '+response.owner+'</div>')
                    .ariaLabel('Review on blockchain')
                    .ok('Got it')
                    .targetEvent($event)
            );
        },function (error) {
            console.log("Error in getReviewBcData",error);
        });
    };

    return thiz;
});
