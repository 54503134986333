'use strict';

app.directive('userAvatar', [
    'UserCacheService',
    function (UserCacheService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/user/user-avatar.directive.html',
            scope: {
                userId: '=',
                type: '@'
            },
            link: function (scope, element, attrs) {
                scope.userCache = UserCacheService;

                switch(scope.type){
                    case "large":
                        scope.class = "user-avatar-max";
                        break;
                    default:
                        scope.class = "user-avatar-mini";
                }
            }
        };
    }]);
