app.factory("Toast", function($mdToast, $filter) {

        var thiz = this;

        var toast = function(text,cls){
            $mdToast.show(
                $mdToast.simple()
                    .textContent(text)
                    .position("top right")
                    .toastClass(cls)
                //.hideDelay(50000)
            );
        };

        thiz.info = function(text){
            toast(text,'info');
        };

        thiz.success = function(text){
            toast(text,'success');
        };

        thiz.error = function(text){
            toast(text,'error');
        };

        thiz.warning = function(text){
            toast(text,'warning');
        };

        return thiz;
    });
