
var web3_provider = undefined;
window.addEventListener('load', function() {
    // Checking if Web3 has been injected by the browser (Mist/MetaMask)
    if (typeof web3 !== 'undefined') {
        // Use the browser's ethereum provider
        web3_provider = web3.currentProvider;
    } else {
        web3_provider = null;
    }
});

app.factory("Web3Service", function(config,$window, $timeout,$http,Session,$q, BackendService,$rootScope,$interval) {

    var thiz = this;

    var Web3Statuses = {
        UNKNOWN: -100,
        NO_METAMASK: -10,
        WRONG_RPC: -9,
        NOT_LOGGED_IN: -6,
        WRONG_ADDRESS: -5,
        UNKNOWN_ADDRESS: -3,
        NOT_REGISTERED: -2,
        PENDING_REGISTRATION: -1,
        DONE: 1
    };

    var data = {
        web3: null,
        status: Web3Statuses.UNKNOWN
    };
    var accountLast;
    var accountInterval;
    var registrationInterval;

    var contracts = $q.all({
        "RegisteredUsers": $http.get('js/bower_components/contract_artifacts/RegisteredUsersDatabase.json'),
        "Article": $http.get('js/bower_components/contract_artifacts/ArticleDatabase.json'),
        "Review": $http.get('js/bower_components/contract_artifacts/ReviewDatabase.json')
    }).then(function(results){
        return _.mapValues(results, function(item) { return { abi: item.data.abi, address: item.data.networks[config.web3.id].address}; });
    }, function (error) {
        console.log("Error in getting contracts:",error);
        return {};
    });

    var getContract = function(name){
        return contracts.then(function(results){
            if(results[name] && data.web3!==null){
                return data.web3.eth.contract(results[name].abi).at(results[name].address);
            }else{
                return null;
            }
        });
    }


    var setWeb3 = function(){
        if(web3_provider!==undefined){
            if(web3_provider === null){
                data.web3 = null;
                data.status = Web3Statuses.NO_METAMASK;
            }else{
                if(data.web3 === null) data.web3 = new Web3(web3_provider);
                data.web3.version.getNetwork( function(err, netId){
                    if(config.web3.id === netId) {
                        var session = Session.getSession();
                        if(session === null){
                            data.status = Web3Statuses.DONE;
                        }else{
                            var user = session.getUser();
                            if(user.bcAddress === null){
                                data.status = Web3Statuses.UNKNOWN_ADDRESS;
                            }else {
                                if (angular.isDefined(accountInterval)) {
                                    $interval.cancel(accountInterval);
                                    accountInterval = undefined;
                                    accountLast = undefined;
                                }
                                accountInterval = $interval(function() {
                                    if(accountLast !== data.web3.eth.accounts[0]){
                                        accountLast = data.web3.eth.accounts[0];
                                        data.status = Web3Statuses.UNKNOWN;
                                        console.log("Switched MetaMask account to "+accountLast);
                                        if(accountLast === undefined){
                                            data.status = Web3Statuses.NOT_LOGGED_IN;
                                        }else if (user.bcAddress !== accountLast.toLowerCase()) {
                                            data.status = Web3Statuses.WRONG_ADDRESS;
                                        } else if (user.flow <  User.UserFlow.BC_USER_REGISTER_PENDING) {
                                            thiz.doIsRegisteredUser().then(function (response) {
                                                if (response) {
                                                    BackendService.doSetUserFlowReq(user.id, User.UserFlow.BC_USER_REGISTER_COMPLETED,
                                                        function (response) {
                                                            if (response.err === null) {
                                                                user.flow = response.flow;
                                                                data.status = Web3Statuses.DONE;
                                                                $interval.cancel(registrationInterval);
                                                                console.log("Success in set user flow", response);
                                                            } else {
                                                                data.status = Web3Statuses.NOT_REGISTERED;
                                                                console.log("Error set user flow", response);
                                                            }
                                                            $timeout(function () {
                                                                $rootScope.$apply();
                                                            });
                                                        }, function (error) {
                                                            data.status = Web3Statuses.NOT_REGISTERED;
                                                            console.log("Error set user flow", error);
                                                            $timeout(function () {
                                                                $rootScope.$apply();
                                                            });
                                                        });
                                                } else {
                                                    data.status = Web3Statuses.NOT_REGISTERED;
                                                }
                                                $timeout(function () {
                                                    $rootScope.$apply();
                                                });
                                            }, function (error) {
                                                console.log("Error in checking if registered.", error);
                                                data.status = Web3Statuses.NOT_REGISTERED;
                                                $timeout(function () {
                                                    $rootScope.$apply();
                                                });
                                            });
                                        } else if (user.flow < User.UserFlow.BC_USER_REGISTER_COMPLETED) {
                                            if (angular.isDefined(registrationInterval)) {
                                                $interval.cancel(registrationInterval);
                                                registrationInterval = undefined;
                                            }
                                            registrationInterval = $interval(function() {
                                                console.log("Registration interval running...");
                                                thiz.doIsRegisteredUser().then(function (response) {
                                                    if (response) {
                                                        //data.status = Web3Statuses.DONE;
                                                        BackendService.doSetUserFlowReq(user.id, User.UserFlow.BC_USER_REGISTER_COMPLETED,
                                                            function (response) {
                                                                if (response.err === null) {
                                                                    user.flow = response.flow;
                                                                    data.status = Web3Statuses.DONE;
                                                                    $interval.cancel(registrationInterval);
                                                                    console.log("Success in set user flow", response);
                                                                } else {
                                                                    data.status = Web3Statuses.PENDING_REGISTRATION;
                                                                    console.log("Error set user flow", response);
                                                                }
                                                                $timeout(function () {
                                                                    $rootScope.$apply();
                                                                });
                                                            }, function (error) {
                                                                data.status = Web3Statuses.PENDING_REGISTRATION;
                                                                console.log("Error set user flow", error);
                                                                $timeout(function () {
                                                                    $rootScope.$apply();
                                                                });
                                                            });
                                                    } else {
                                                        data.status = Web3Statuses.PENDING_REGISTRATION;
                                                    }
                                                    $timeout(function () {
                                                        $rootScope.$apply();
                                                    });
                                                }, function (error) {
                                                    console.log("Error in checking if registered.", error);
                                                    data.status = Web3Statuses.NOT_REGISTERED;
                                                    $timeout(function () {
                                                        $rootScope.$apply();
                                                    });
                                                });
                                            },1000);
                                        } else {
                                            data.status = Web3Statuses.DONE;
                                        }


                                    }
                                }, 500);
                            }
                        }
                    }else{
                        data.status = Web3Statuses.WRONG_RPC;
                    }
                    $timeout(function(){$rootScope.$apply();});
                });
            }
        }else{
            data.web3 = null;
            data.status = Web3Statuses.UNKNOWN;
        }
    };

    var getOptions = function () {
        var user = Session.getSession().getUser();
        var options = {from: user.bcAddress, gasPrice: config.web3.gasPrice};
        return options;
    };


    thiz.getStatus = function(){
        return data.status;
    };
    thiz.isStatusDone = function(){
        return data.status === Web3Statuses.DONE;
    };
    thiz.getAllStatuses = function(){
        return Web3Statuses;
    };
    thiz.getWeb3 = function(){
        return data.web3;
    };
    thiz.getCurrentAccount = function(){
        if(data.web3 === null) return null;
        return data.web3.eth.accounts[0].toLowerCase();
    };
    thiz.checkWeb3 = _.debounce(function () {
        if(web3_provider!==undefined){
            setWeb3();
        }else{
            window.addEventListener('load', function() {
                if (typeof web3 !== 'undefined') {
                    web3_provider = web3.currentProvider;
                } else {
                    web3_provider = null;
                }
                setWeb3();
            });
        }
    }, 1000);


    thiz.doRegisterUser = function(){
        var deferred = $q.defer();
        if(data.status === Web3Statuses.NOT_REGISTERED) {
            getContract("RegisteredUsers").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                var user = Session.getSession().getUser();
                console.log("Transaction to contract at address ",contract.address, "with params:",
                    user.firstName,",",user.lastName,",",getOptions());
                contract.register(user.firstName,user.lastName,getOptions(),
                    function(error, result){
                        if(!error) {
                            console.log("Success in doRegisterUser",result);
                            BackendService.doSetUserFlowReq(user.id, User.UserFlow.BC_USER_REGISTER_PENDING,
                                function (response) {
                                    if (response.err === null) {
                                        user.flow = response.flow;
                                        thiz.checkWeb3();
                                        console.log("Success in set user flow", response);
                                    } else {
                                        console.log("Error set user flow", response);
                                    }
                                }, function (error) {
                                    console.log("Error set user flow", error);
                                });
                            deferred.resolve(result);
                        }else{
                            console.log("Error in doRegisterUser");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("User not allowed in doRegisterUser");
            deferred.reject();
        }
        return deferred.promise;
    };

    thiz.doIsRegisteredUser = function(){
        var deferred = $q.defer();
        var bcAddress = Session.getSession().getUser().bcAddress;
        if(angular.isString(bcAddress) && data.web3 !== null) {
            getContract("RegisteredUsers").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                contract.isRegisteredUser(bcAddress,
                    function(error, result){
                        if(!error) {
                            console.log("Success in doIsRegisteredUser",result);
                            deferred.resolve(result);
                        }else{
                            console.log("Error in doIsRegisteredUser");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("User does not have defined bcAddress!");
            deferred.reject();
        }

        return deferred.promise;
    };

    thiz.doGetUserData = function(){
        var deferred = $q.defer();
        var bcAddress = Session.getSession().getUser().bcAddress;
        if(angular.isString(bcAddress) && data.status === Web3Statuses.DONE) {
            getContract("RegisteredUsers").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                contract.getUserData(bcAddress,
                    function(error, result){
                        if(!error) {
                            console.log("Success in doGetUserData",result);
                            deferred.resolve(result);
                        }else{
                            console.log("Error in doGetUserData");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("User does not have defined bcAddress or Web3 status is not DONE!");
            deferred.reject();
        }

        return deferred.promise;
    };

    function uuid2bn(str) {

    }

    thiz.uuid2hash = function(str) {
        var str1 = str.replace(/-/g,"");
        return new BN(str1,16).toString(10);
    };

    thiz.doClaimArticle = function(dto){
        var deferred = $q.defer();
        if(data.status === Web3Statuses.DONE && dto.aid && dto.hash && dto.ownerAddresses.length > 0) {
            getContract("Article").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                var user = Session.getSession().getUser();
                console.log("Transaction to contract at address ",contract.address, "with params:",
                    dto.hash,",",dto.ownerAddresses,",",dto.doi,",",getOptions());
                contract.claimArticle(dto.hash,dto.ownerAddresses,dto.doi,getOptions(),
                    function(error, result){
                        if(!error) {
                            console.log("Success in doClaimArticle",result);
                            BackendService.doInitiateClaimArticle(dto.aid, user.id,
                                function (response) {
                                    console.log("Success in claimArticle", response);
                                    deferred.resolve(result);
                                }, function (error) {
                                    console.log("Error claimArticle", error);
                                    deferred.reject();
                                });
                        }else{
                            console.log("Error in claimArticle");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("User not allowed in doClaimArticle");
            deferred.reject();
        }
        return deferred.promise;
    };

    thiz.doGetArticleData = function(aid){
        var deferred = $q.defer();
        var hash = thiz.uuid2hash(aid);
        if(angular.isString(hash) && data.status === Web3Statuses.DONE) {
            getContract("Article").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                contract.getArticleData(hash,
                    function(error, result){
                        if(!error) {
                            var r = {
                                hash: hash,
                                status: result[0].toNumber(),
                                owners: result[1],
                                doi: result[2]
                            };
                            console.log("Success in doGetArticleData",r);
                            deferred.resolve(r);
                        }else{
                            console.log("Error in doGetArticleData");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("Hash is not string or Web3 status is not DONE!");
            deferred.reject();
        }

        return deferred.promise;
    };

    thiz.doCreateEndorsementReview = function(review){
        var deferred = $q.defer();
        if(data.status === Web3Statuses.DONE) {
            getContract("Review").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                var aHash = thiz.uuid2hash(review.articleId);
                var rHash = thiz.uuid2hash(review.reviewId);
                review.editStatus = Publish.EditStatus.COMPLETED;
                console.log("Transaction to contract at address ",contract.address, "with params:",
                    aHash,",",rHash,",",getOptions());
                contract.createEndorsementReview(aHash,rHash,getOptions(),
                    function(error, result){
                        if(!error) {
                            console.log("Success in doCreateEndorsementReview",result);
                            BackendService.doUpdateReview(review,
                                function (response) {
                                    console.log("Success in doUpdateReview", response);
                                    deferred.resolve(response);
                                }, function (error) {
                                    console.log("Error doUpdateReview", error);
                                    deferred.reject();
                                });
                        }else{
                            console.log("Error in doCreateEndorsementReview");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("User not allowed in doCreateEndorsementReview");
            deferred.reject();
        }
        return deferred.promise;
    };

    thiz.doGetEndorsementReviewData = function(rid){
        var deferred = $q.defer();
        var hash = thiz.uuid2hash(rid);
        if(angular.isString(hash) && data.status === Web3Statuses.DONE) {
            getContract("Review").then(function (contract) {
                if(contract === null) {
                    deferred.reject();
                    return deferred.promise;
                }
                contract.getEndorsementReviewData(hash,
                    function(error, result){
                        if(!error) {
                            var r = {
                                rhash: hash,
                                ahash: result[0].toString(10),
                                owner: result[1]
                            };
                            console.log("Success in doGetEndorsementReviewData",r);
                            deferred.resolve(r);
                        }else{
                            console.log("Error in doGetEndorsementReviewData");
                            deferred.reject();
                        }
                    });
            })
        }else{
            console.log("Hash is not string or Web3 status is not DONE!");
            deferred.reject();
        }

        return deferred.promise;
    };


    return thiz;
});
