'use strict';

app.service('LoginService', [
    'config', '$injector', '$rootScope', 'localstorage', '$state',
    function (config, $injector, $scope, localstorage, $state) {
        var self = this;
        var usedLoginProvider = null;

        self.initProviders = function () {
            var providers = Object.getOwnPropertyNames(config.signin.providers);
            providers.forEach(function (providerPropertyName) {
                var providerName = config.signin.providers[providerPropertyName].provider;
                var loginProvider = $injector.get(providerName);

                if (validateProvider(providerName, loginProvider)) {
                    loginProvider.init();
                }
            });
        };

        self.getCurrentLoginProvider = function () {
            var provider = null;
            if (usedLoginProvider !== null) {
                provider = usedLoginProvider;
            }
            if (provider === null) {
                var l = localstorage.get('loginprovider');
                if(l){
                    var savedLoginProvider = JSON.parse(l);
                    if (savedLoginProvider != null) {
                        provider = savedLoginProvider;
                    }
                }
            }

            if (provider !== null) {
                return $injector.get(provider.provider);
            }

            return null;
        };

        self.getLoginProvider = function (providerName) {
            return $injector.get(providerName);
        };

        $scope.$on(config.signin.service_base.login_event, function (event, args) {
            console.log("provider",args);
            usedLoginProvider = args.provider;
            localstorage.set('loginprovider', JSON.stringify(usedLoginProvider));
        });

        $scope.$on(config.signin.service_base.logout_event, function (event, data) {
            console.log("login service");
            $state.go('accounts.login');
        });

        function validateProvider(name, provider) {
            if (!angular.isFunction(provider.init)) {
                console.error('No init() function implemented in provider ' + provider);
                return false;
            }

            if (!angular.isFunction(provider.logout)) {
                console.error('No logout() function implemented in provider ' + provider);
                return false;
            }

            return true;
        }

        return this;
    }
]);