/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("review", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'ReviewModel', '$rootScope','BackendService','ReviewService','Toast','Session',
    function ($timeout, $state, $mdDialog, $window, config, ReviewModel, $rootScope,BackendService,ReviewService,Toast,Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/review/review.directive.html',
            scope: {
                control: '=',
                item: '='
            },
            link: function (scope, element, attrs) {
                scope.ReviewService = ReviewService;

                if(!(scope.item instanceof ReviewModel)){
                    scope.item = new ReviewModel(scope.item);
                }


            }
        };
    }]);
