'use strict';

app.factory('loginProviderGoogle', [
    '$rootScope', 'config', 'BackendService',
    function ($rootScope, config, BackendService) {

        function doInit() {
            gapi.load('auth2', function () {
                gapi.auth2.init({
                    client_id: config.signin.providers.google.client_id,
                    fetch_basic_profile: true,
                    ux_mode: 'redirect',
                    redirect_uri: config.connection.root + config.signin.providers.google.redirectUrl,
                    response_type: 'permission',
                    scope: 'email profile openid'
                });
            });
        }

        function doLogout() {
            gapi.load('auth2', function () {
                gapi.auth2.init({
                    client_id: config.signin.providers.google.client_id
                }).then(function () {
                    var auth2 = gapi.auth2.getAuthInstance();
                    auth2.signOut().then(function () {
                        // TODO check if we need to do something?
                    });
                });
            });
        }

        function doLogin() {
            var auth2 = gapi.auth2.getAuthInstance();
            auth2.signIn().then(function (user) {
                $rootScope.$broadcast(config.signin.service_base.login_event, {
                    provider: config.signin.providers.google,
                    data: {
                        token: user.Zi.id_token,
                        email: user.w3.U3
                    }
                });
            });
        }

        function doLoginToBackend(token, email, responseCallback, errorCallback) {
            BackendService.doLoginWithGoogle(token, email, responseCallback, errorCallback);
        }

        function getLoginData() {
            var auth2 = gapi.auth2.getAuthInstance();
            var userData = auth2.currentUser.get();
            if (userData && angular.isDefined(userData.Zi) && userData.Zi !== null && angular.isDefined(userData.w3) && userData.w3 !== null) {
                return {
                    token: userData.Zi.id_token,
                    email: userData.w3.U3
                }
            }

            return null;
        }

        return {
            /**
             * @description Initializes login provider.
             */
            init: doInit,

            /**
             * @description Logout current user on provider.
             */
            logout: doLogout,

            /**
             * @description Login user using provider.
             */
            login: doLogin,

            /**
             * @description Sends login data to backend.
             *
             * @param {String} token The token
             * @param {String} email User's email address or identification
             * @param {Function} cb The callback
             */
            loginToBackend: doLoginToBackend,

            /**
             * @description Gets the data of currently logged used.
             * @return {Object} The data
             */
            getLoginData: getLoginData
        };
    }
]);