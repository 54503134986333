'use strict';

app.factory('localstorage', [
    '$window',
    function ($window) {
        return {
            get: function (key, defaultValue) {
                return $window.localStorage[key] || defaultValue;
            },

            set: function (key, value) {
                $window.localStorage[key] = value;
            },

            getObj: function (key, defaultValue) {
                var value = $window.localStorage[key];
                return angular.isObject(value) ? JSON.parse(value) : defaultValue;
            },

            setObj: function (key, value) {
                $window.localStorage[key] = JSON.stringify(value);
            }
        }
    }
]);