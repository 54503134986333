/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("timeline", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', '$rootScope','BackendService','TimelineService','Toast',
    'Session',
    function ($timeout, $state, $mdDialog, $window, config, $rootScope,BackendService,TimelineService,Toast,
              Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/timeline/timeline.directive.html',
            scope: {},
            link: function (scope, element, attrs) {
                scope.TimelineService = TimelineService;
                scope.TimelineService.reset();
            }
        };
    }]);
