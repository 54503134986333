'use strict';

app.controller('profileCtrl', [
    '$scope', 'BackendService', 'config', 'Session', 'UserService','$rootScope','UserCacheService','$state','$stateParams',
    '$mdDialog',
    function ($scope, BackendService, config, Session, UserService,$rootScope,UserCacheService,$state,$stateParams,
              $mdDialog) {

        $scope.user = UserCacheService.getUser($stateParams.id);
        $scope.isMe = $scope.user.userId === Session.getSession().getUser().id;

        $scope.education = [];
        $scope.experience = [];
        $scope.following = [];
        $scope.followers = [];
        $scope.loading = {
            education: true,
            experience: true,
            following: true,
            followers: true
        };


        $scope.UserService = UserService;

        $scope.onUpdateProfile = function (ev) {
            $mdDialog.show({
                controller: 'profileUpdateCtrl',
                templateUrl: 'app/module/user/profile-update.dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {}
            }).then(function (user) {
                $scope.user.user = user;
            });
        };


        var getEducation = function(){
            $scope.education = [];
            $scope.loading.education = true;
            BackendService.doGetEducationReq($scope.user.userId, function (response) {
                console.log("education!",response);
                $scope.education = response.education;
                $scope.loading.education = false;
            }, function (error) {
                console.log('Get Education error: ', error);
                $scope.loading.education = false;
            });
        };

        var getExperience = function(){
            $scope.experience = [];
            $scope.loading.experience = true;
            BackendService.doGetExperienceReq($scope.user.userId, function (response) {
                console.log("experience!",response);
                $scope.experience = response.experience;
                $scope.loading.experience = false;
            }, function (error) {
                console.log('Get Experience error: ', error);
                $scope.loading.experience = false;
            });
        };

        var getFollowing = function(){
            $scope.following = [];
            $scope.loading.following = true;
            BackendService.doGetFollowingReq($scope.user.userId, function (response) {
                console.log("following!",response);
                $scope.following = UserCacheService.getUsers(response.followingIds);
                $scope.loading.following = false;
            }, function (error) {
                console.log('Get following error: ', error);
                $scope.loading.following = false;
            });
        };

        var getFollowers = function(){
            $scope.followers = [];
            $scope.loading.followers = true;
            BackendService.doGetFollowersReq($scope.user.userId, function (response) {
                console.log("followers!",response);
                $scope.followers = UserCacheService.getUsers(response.followersIds);
                $scope.loading.followers = false;
            }, function (error) {
                console.log('Get followers error: ', error);
                $scope.loading.followers = false;
            });
        };

        $scope.refresh = function(){
            getEducation();
            getExperience();
            getFollowing();
            getFollowers();
        };
        $scope.refresh();


        $scope.$on("profile-refresh",function(ev,args){
            switch(args.type){
                case "education": getEducation(); break;
                case "experience": getExperience(); break;
                case "following": getFollowing(); break;
                case "followers": getFollowers(); break;
                default: $scope.refresh();
            }
        });

    }
]);