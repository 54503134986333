'use strict';

app.controller('dashboardCtrl', [
    '$scope', 'QueryModel', 'config', 'Session', 'Web3Service','$rootScope','BackendService','$state','$stateParams',
    function ($scope, QueryModel, config, Session, Web3Service,$rootScope,BackendService,$state,$stateParams) {

        $scope.keywords = new QueryModel(undefined,"search");

        $scope.redirect = function () {};

        $scope.listControl = {
            redirect: $scope.redirect,
            keywords: $scope.keywords,
            type: "simple"
        };



    }
]);