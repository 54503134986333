
app.controller('experienceUpdateCtrl', ['$scope', 'Session','Toast','$mdDialog','BackendService','$q','$mdConstant',
    'currentExperience','$rootScope',
    function ($scope, Session,Toast,$mdDialog,BackendService,$q,$mdConstant,
              currentExperience,$rootScope) {

        $scope.form = {};

        $scope.isAdd = currentExperience === null;

        $scope.title = $scope.isAdd?"Add experience":"Update experience";

        if($scope.isAdd){
            $scope.experience = {
                id: UUID.generate(),
                title: "",
                company: "",
                location: "",
                description: "",
                monthFrom: undefined,
                yearFrom: undefined,
                monthTo: undefined,
                yearTo: undefined,
                urls: []
            };
        }else{
            $scope.experience = currentExperience;
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        var user = Session.getSession().getUser();
        $scope.save = function () {
            if ($scope.form.experience.$valid) {
                console.log($scope.experience);
                BackendService.doUpdateExperience(user.id,$scope.experience, function (response) {
                    console.log("update user experience!",response);
                    if($scope.isAdd){
                        Toast.success("Experience was successfully added.");
                    }else{
                        Toast.success("Experience was successfully updated.");
                    }
                    $rootScope.$broadcast("profile-refresh",{type:"experience"});
                    $mdDialog.hide($scope.experience);
                }, function (error) {
                    console.log('Update Experience error: ', error);
                });
            }
        };


    }]);
