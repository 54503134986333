'use strict';

app.controller('inviteCtrl', [
    '$scope', '$state', 'config', 'Toast', '$timeout', '$cookies', 'BackendService', '$stateParams', 'Session',
    function ($scope, $state, config, Toast, $timeout, $cookies, BackendService, $stateParams, Session) {
        $scope.form = {};
        $scope.title = 'infinitcodex invite';
        $scope.loading = false;
        $scope.code = '';




        $scope.doSubmit = function(){
            if($scope.form.invite.$valid){
                var hash = hashCode($scope.code).toString();
                console.log($scope.code,hash);
                if(hash === config.invite){
                    $cookies.put("dave",hash);
                    $state.go('accounts.login');
                }else{
                    Toast.error("Wrong invite code.")
                }
            }
        };

        var hashCode = function(str){
            var hash = 0;
            if (str.length === 0) return hash;
            for (var i = 0; i < str.length; i++) {
                var char = str.charCodeAt(i);
                hash = ((hash<<5)-hash)+char;
                hash = hash & hash; // Convert to 32bit integer
            }
            return hash;
        }
    }
]);