
/* global angular, app */

app.factory('CommentModel', [
    function() {

        /**
         * Create new CommentModel.
         * @param object represents CommentModel
         * @constructor
         */
        
        function CommentModel(object) {
            if(!(object instanceof Object)) object = {};

            this.commentId = object.commentId;
            this.entityId = object.entityId;
            this.entityType = object.entityType;
            this.userId = object.userId;
            this.text = object.text;
            this.createdTime = object.createdTime;
            this.updatedTime = object.updatedTime;
            this.counters = angular.isObject(object.counters)? object.counters:{likes:0,dislikes:0,counters:0};
            if(this.counters.dislikes === null){
                this.counters.dislikes = 0;
            }

        }
        

        // Return constructor
        return CommentModel;
    }
]);