'use strict';

app.constant('config', {
    connection: {
        ws: 'wss://dev.infinitcodex.com/websocket',
        root: 'https://dev.infinitcodex.com/',
        chat: {
            host: 'wss://chat.infinitcodex.com',
            domain: '@infinitcodex.com'
        },
        previewUrl: 'https://dev.infinitcodex.com'
    },
    signin: {
        providers: {
            google: {
                provider: 'loginProviderGoogle',
                dependencies: ['google-signin'],
                client_id: '487614378308-t1aa1duj5vhle6lp1hmg51a9np7iuj4o.apps.googleusercontent.com',
                redirectUrl: 'login/google'
            },
            facebook: {
                provider: 'loginProviderFacebook',
                dependencies: ['facebook-signin'],
                app_id: '614426732230972',
                app_secret: '282fd0bffb5465045bbed53ac6e4f69f',
                loginRedirect: 'https://www.facebook.com/v3.0/dialog/oauth?client_id={{appId}}&redirect_uri={{redirectUri}}&state={{state}}&response_type=token',
                redirectUrl: 'login/facebook'
            },
            orcid: {
                provider: 'loginProviderOrcid',
                client_id: 'APP-LGII59U24DWYNKBK',
                redirectUrl: 'login/orcid',
                loginRedirect: 'https://orcid.org/oauth/authorize?client_id={{clientId}}&response_type=code&scope=/authenticate&redirect_uri={{redirectUri}}'
            }
        },
        service_base: {
            login_event: 'signin.services.events.login',
            logout_event: 'signin.services.events.logout',
            error_event: 'signin.services.events.error'
        }
    },
    web3: {
        id: '1994',
        gasPrice: 1e12
    },
    invite: "693603556",
    searchList: {
        journalLength: 50,
        authorLength: 80,
        abstractLength: 300
    },
    searchInput: {
        searchSize: 20
    },
    moment: {
        longDateFormat : {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY-MM-DD",
            l: "YYYY-MM-DD",
            LL: "MMMM Do YYYY",
            ll: "MMM D YYYY",
            LLL: "L LT",
            lll: "L LT",
            LLLL: "dddd, MMMM Do YYYY LT",
            llll: "ddd, MMM D YYYY LT"
        },
        calendar : {
            lastDay : '[Yesterday at] LT',
            sameDay : '[Today at] LT',
            nextDay : '[Tomorrow at] LT',
            lastWeek : '[last] dddd [at] LT',
            nextWeek : 'dddd [at] LT',
            sameElse : 'LLL'
        }
    }
});

/**
 * The dependencies used for lazy loading.
 */
app.constant('APP_DEPENDENCIES', {
    dependencies: {
        'google-signin': [
            'https://apis.google.com/js/platform.js'
        ],
        'facebook-signin': [
            'https://connect.facebook.net/en_US/sdk.js'
        ]
    }
});