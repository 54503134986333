/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("comment", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'CommentModel', '$rootScope','BackendService','CommentService','EntityService','Session',
    function ($timeout, $state, $mdDialog, $window, config, CommentModel, $rootScope,BackendService,CommentService,EntityService,Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/comment/comment.directive.html',
            scope: {
                control: '=',
                item: '='
            },
            link: function (scope, element, attrs) {
                scope.CommentService = CommentService;
                scope.EntityService = EntityService;


            }
        };
    }]);
