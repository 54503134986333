'use strict';

app.controller('contentCtrl', [
    '$scope', 'Web3Service', 'config', 'Session', 'BackendService','$rootScope','$transitions',
    '$state','$mdDialog','$mdMedia','NotificationService',
    function ($scope, Web3Service, config, Session, BackendService,$rootScope,$transitions,
              $state,$mdDialog,$mdMedia,NotificationService) {

        $scope.$mdMedia = $mdMedia;
        $scope.web3Service = Web3Service;
        $scope.web3Service.checkWeb3($scope);

        $scope.NotificationService = NotificationService;

        var user = Session.getSession().getUser();
        if (user && user.pictureUrl) {
            $scope.avatar = user.pictureUrl;
        }


        $scope.onLogo = function(){
            $state.go("content.dashboard");
        };

        $scope.search = {
            text: "",
            show: $state.current.name !== "content.search"
        };
        $scope.onSearch = function(ev){
            $(event.target).find("input").blur();
            $state.go("content.search",{q: $scope.search.text});
            $scope.search.text = "";
        };
        $scope.onIconSearch = function(){
            $state.go("content.search",{q: ''});
        };

        $scope.openMenu = function ($mdMenu, $event) {
            $mdMenu.open($event);
        };

        $scope.onMyProfile = function (ev) {
            $state.go("content.profile",{ id: user.id});
        };

        $scope.onMyResearch = function (ev) {
            $state.go("content.my.research");
        };

        $scope.onMyReviews = function (ev) {
            $state.go("content.my.reviews");
        };

        $scope.onLogout = function () {
            Session.logout();
        };

        $scope.onAllMessages = function (ev) {
            $state.go("content.my.messages");
        };

        $scope.onAllNotifications = function (ev) {
            $state.go("content.my.notifications");
        };

        $transitions.onSuccess({}, function(transition) {
            console.log(transition.to().name);
            if(transition.to().name === "content.search"){
                $scope.search.show = false;
            }else{
                $scope.search.show = true;
            }
        });
    }
]);