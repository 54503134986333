'use strict';

app.controller('researchCtrl', [
    '$scope', 'config', 'Session', 'BackendService','ResearchItemModel','ResearchItemService','$mdDialog','$state','$q','Toast',
    function ($scope, config, Session, BackendService,ResearchItemModel,ResearchItemService,$mdDialog,$state,$q,Toast) {

        $scope.loading = true;
        $scope.control = {};
        $scope.user = Session.getSession().getUser();
        $scope.items = {
            all: [],
            other: [],
            publish: [],
            pending: []
        };

        $scope.tabs = {
            selectedIndex: 0,
            all: [{id: 'all', name: 'All'},
                {id: 'other', name: 'Other'},
                {id: 'pending', name: 'Pending'},
                {id: 'publish', name: 'Published'}]
        };

        $scope.ResearchItemService = ResearchItemService;

        $scope.getMyArticles = function () {
            $scope.loading = true;
            $scope.items = {
                all: [],
                other: [],
                publish: [],
                pending: []
            };
            BackendService.doGetArticleReq($scope.user.id,null,null,
                function (response) {
                    $scope.items.all = _.map(response.articles, function (item) {
                        return { data: ResearchItemModel.fromPublishArticle(item) };
                    });
                    console.log("ALL",$scope.items.all);
                    $scope.items.other = _.filter($scope.items.all,function (item) {
                        return item.data.extra.article.editStatus < Publish.EditStatus.COMPLETED;
                    });
                    $scope.items.publish = _.filter($scope.items.all,function (item) {
                        return item.data.extra.article.publishStatus === Publish.PublishStatus.BC_COMPLETED;
                    });
                    $scope.items.pending = _.filter($scope.items.all,function (item) {
                        return item.data.extra.article.publishStatus === Publish.PublishStatus.PENDING;
                    });

                    BackendService.doGetSocialOperationForUserIdReq($scope.user.id, _.pluck(response.articles,"articleId"), function (resp) {
                        console.log('Likes loaded', resp);
                        resp.data.forEach(function (d) {
                            var item = _.find($scope.items.all, function (item) {
                                return item.data.extra.article.articleId === d.entityId;
                            });
                            if(d.oper === Publish.SocialOperation.SET_LIKE){
                                item.liked = true;
                                item.disliked = false;
                            }else if(d.oper === Publish.SocialOperation.SET_DISLIKE){
                                item.liked = false;
                                item.disliked = true;
                            }
                        });
                    }, function (error) {
                        console.error('Error happened while loading likes', error);
                    });

                    console.log("Success in getMyArticles",$scope.items);
                    $scope.loading = false;
            }, function (error) {
                $scope.loading = false;
                console.log("Error in getMyArticles", error);
            });
        };
        
        
        $scope.refresh = function () {
            $scope.getMyArticles();
        };
        $scope.refresh();

        $scope.$on("research-refresh",function(ev,args){
            if(args && args.type){
                if(args.type === "publish"){
                    $scope.tabs.selectedIndex = 2;
                }
            }
            $scope.refresh();
        });


        $scope.onAdd = function(ev){
            ResearchItemService.onAdd(ev);
        }
    }
]);