app.factory("EntityService", function(config,$window, $timeout,$http,Session,$q, Web3Service,$rootScope,$mdDialog,
                                       Toast,BackendService) {

    var thiz = this;
    var user = Session.getSession().getUser();

    thiz.setLike = function (item,ev) {
        console.log(item);
        var responseCallback = function (response) {
            if(response.err === null) {
                if(item.disliked === true) item.data.counters.dislikes--;
                item.data.counters.likes++;
                item.liked = true;
                item.disliked = false;
            }else{
                Toast.error(response.err);
            }
        };
        var errorCallback = function (error) {
            console.log("Error like.",error);
        };

        if(item.liked === true){
            setSocial(item.entityId, Publish.SocialOperation.RESET_LIKE,function (response) {
                if(response.err === null) {
                    item.liked = undefined;
                    item.disliked = undefined;
                    item.data.counters.likes--;
                }else{
                    Toast.error(response.err);
                }
            },errorCallback);
        }else if(item.disliked === true){
            setSocial(item.entityId, Publish.SocialOperation.RESET_DISLIKE,function () {
                setSocial(item.entityId, Publish.SocialOperation.SET_LIKE,responseCallback,errorCallback);
            },errorCallback);
        }else {
            setSocial(item.entityId, Publish.SocialOperation.SET_LIKE,responseCallback,errorCallback);
        }
    };

    thiz.setDislike = function (item,ev) {
        var responseCallback = function (response) {
            if(response.err === null) {
                if(item.liked === true) item.data.counters.likes--;
                item.data.counters.dislikes++;
                item.liked = false;
                item.disliked = true;
            }else{
                Toast.error(response.err);
            }
        };
        var errorCallback = function (error) {
            console.log("Error dislike.",error);
        };

        if(item.disliked === true){
            setSocial(item.entityId, Publish.SocialOperation.RESET_DISLIKE,function (response) {
                if(response.err === null) {
                    item.liked = undefined;
                    item.disliked = undefined;
                    item.data.counters.dislikes--;
                }else{
                    Toast.error(response.err);
                }
            },errorCallback);
        }else if(item.liked === true){
            setSocial(item.entityId, Publish.SocialOperation.RESET_LIKE,function () {
                setSocial(item.entityId, Publish.SocialOperation.SET_DISLIKE,responseCallback,errorCallback);
            },errorCallback);
        }else {
            setSocial(item.entityId, Publish.SocialOperation.SET_DISLIKE,responseCallback,errorCallback);
        }
    };

    var setSocial = function(entityId,type, responseCallback, errorCallback){
        BackendService.doSetSocialOperationReq(user.id,entityId, type,responseCallback,errorCallback);
    };



    thiz.onCounterList = function (item,type,ev) {
        return $mdDialog.show({
            controller: 'entityCounterListCtrl',
            templateUrl: 'app/module/entity/entity-counter-list.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                currentEntity: item,
                currentType: type
            }
        });
    };

    return thiz;
});
