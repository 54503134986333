'use strict';

app.service('SearchFilterService', [
    'BackendService','ResearchItemModel','EntityCacheService',
    function (BackendService,ResearchItemModel,EntityCacheService) {
        var SearchFilterService = function(scope){
            this.scope = scope;
            this.update();
        };

        SearchFilterService.prototype.update = function(){
            this.filters = [];
            this.busy = true;
            //this.loadMore(true);
        };

        SearchFilterService.prototype.loadMore = _.debounce(function (first) {
            var thiz = this;
            this.busy = true;
            var dto = thiz.scope.control.keywords.toDto(0, 0, {});
            dto.aggregation = [Search.AggregationType.YEAR,Search.AggregationType.FOS,Search.AggregationType.KEYWORDS,
                Search.AggregationType.AUTHORS];
            console.log("filter:",dto);

            BackendService.doSearchEs(dto, function (data, message) {
                console.log(data,message);
                thiz.busy = false;
            }, function (error) {
                console.log('Search filter error: ', error);
                thiz.busy = false;
            });
        },300);

        return SearchFilterService;
    }
]);