
app.controller('researchItemOwnersCtrl', ['$scope', 'Session','currentArticle','Toast','$mdDialog','BackendService','$q',
    function ($scope, Session, currentArticle,Toast,$mdDialog,BackendService,$q) {


        $scope.article = currentArticle;
        $scope.loading = true;
        $scope.owners = [];

        $scope.user = Session.getSession().getUser();
        $scope.now = new Date();

        $scope.getUsers = function () {
            $scope.loading = true;
            $scope.owners = [];
            if($scope.article.claimedAuthorIds === null || $scope.article.claimedAuthorIds.length===0){
                $scope.loading = false;
                return;
            }
            BackendService.doGetUserByUserIdReq($scope.article.claimedAuthorIds,
                function (response) {
                    console.log("Success in getUsers", response);
                    $scope.owners = response.users;
                    $scope.loading = false;
                }, function (error) {
                    console.log("Error in getUsers", error);
                    $scope.loading = false;
                });
        };
        $scope.getUsers();


        $scope.cancel = function () {
            $mdDialog.cancel();
        };


    }]);
