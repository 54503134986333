app.factory("NotificationService", function(config,$window, $timeout,$http,Session,$q, Web3Service,$rootScope,$mdDialog,
                                       Toast,BackendService,UserCacheService,EntityCacheService) {

    var thiz = this;
    var user = Session.getSession().getUser();
    var pageSize = 20;
    var history = true;

    thiz.pageState = null;
    thiz.data = [];
    thiz.loading = true;
    thiz.countNew = -1;


    thiz.loadMore = function () {
        thiz.loading = true;
        BackendService.doGetNotificationReq(user.id,history,null,pageSize,thiz.pageState, function (response) {
            console.log('notification loadMore', response);
            for(var i = 0; i< response.notifications.length; i++){
                var e = response.notifications[i];
                e.user = UserCacheService.getUser(e.triggeredByUserId);
                e.entity = EntityCacheService.getEntity(e.entityId, e.entityType);
                thiz.data.push(e);
            }
            if(thiz.countNew === -1){
                setCounter();
            }
            thiz.pageState = response.pageState;
            thiz.loading = false;
        }, function (error) {
            console.log('timeline error: ', error);
            thiz.loading = false;
        });
    };
    thiz.loadMore();


    thiz.acceptNotification = function(item,ev){
        if(item.ackTime === null) {
            BackendService.doAckNotification(item.userId, item.createdTime, item.notifType, function (response) {
                console.log('notification accept', response);
                if (response === null) {
                    item.ackTime = moment().format("x");
                    console.log(item.ackTime);
                    setCounter();
                }
            }, function (error) {
                console.log('accept notification error: ', error);
            });
        }
    };

    var setCounter = function () {
        var c = 0;
        for(var i = 0; i<thiz.data.length;i++){
            if(thiz.data[i].ackTime === null){
                c++;
            }else{
                break;
            }
        }
        thiz.countNew = c;
    };

    return thiz;
});
