'use strict';

app.directive('chatbox', [
    '$timeout', 'ChatService', 'BackendService', 'I8XLogger',
    function ($timeout, ChatService, BackendService, I8XLogger) {
        var logger = I8XLogger.loggerFor('Chat.chatbox');

        return {
            restrict: 'E',
            templateUrl: 'app/pages/chat/chatbox/chatbox.html',
            replace: true,
            link: link,
            scope: {
                chat: '='
            }
        };

        function link(scope, element, attrs) {
            scope.id = ('chatbox-' + scope.chat.userId).replace(/@/, '-');
            scope.isLoading = true;
            scope.userData = null;

            scope.onInputMessage = function (text) {
                sendMessage(text);
            };

            scope.closeChat = function () {
                scope.chat.close();
            };

            scope.getTitle = function () {
                if(scope.userData !== null) {
                    return scope.userData.firstName + ' ' + scope.userData.lastName;
                }

                return scope.userId;
            };

            scope.swapMinimize = function () {
                $timeout(function () {
                    var chat = ChatService.getActiveChat(scope.chat.userId);
                    chat.isMinimized = !chat.isMinimized;
                });
            };

            function onUserDataLoaded(data) {
                $timeout(function () {
                    if(data) {
                        scope.userData = data;
                    }

                    scope.isLoading = false;
                });
            }

            function initialLoad() {
                BackendService.doGetUserByUserIdReq([scope.chat.userId], function (response) {
                    logger.debug('User loaded', response);
                    onUserDataLoaded(response.users[0]);
                }, function (error) {
                    logger.error('User loading error', error);
                    onUserDataLoaded(null);
                });

                ChatService.loadChatHistory(scope.chat.userId, function () {
                    // history loaded, just refresh data
                    $timeout(function () {
                        // nothing to do, just used to refresh data
                    });
                });
            }

            initialLoad();

            function sendMessage(text) {
                logger.debug('Sending', text);

                ChatService.sendMessage(scope.chat.userId, text);
            }
        }
    }
]);

app.directive('chatboxScroll', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                chatboxScroll: '='
            }
        };

        function link(scope, element, attrs) {
            if (scope.$parent.$last){
                $timeout(function () {
                    scrollToBottom(scope.chatboxScroll);
                });
            }

            function scrollToBottom(id) {
                var element = document.getElementById(id);
                if(element) {
                    $(element).stop().animate({scrollTop:$(element)[0].scrollHeight}, 200, 'swing');
                }
            }
        }
    }
]);