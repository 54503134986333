
/* global angular, app */

app.factory('ReviewModel', [
    function() {

        /**
         * Create new ReviewModel.
         * @param object represents ReviewModel
         * @constructor
         */
        
        function ReviewModel(object,extra) {
            if(!(object instanceof Object)) object = {};
            if(!(extra instanceof Object)) extra = {};

            this.reviewId = object.reviewId;
            this.articleId = object.articleId;
            this.userId = object.userId;
            this.text = object.text;
            this.createdTime = object.createdTime;
            this.updatedTime = object.updatedTime;
            this.editStatus = object.editStatus;
            this.publishStatus = object.publishStatus;

            this.extra = {};
            this.extra.user = extra.user;
            this.extra.article = extra.article;

        }
        

        // Return constructor
        return ReviewModel;
    }
]);