'use strict';

app.factory('ChatModel', [
    'ChatMessageModel',
    function (ChatMessageModel) {
        function ChatModel(userId) {
            this.userId = userId;
            this.chatId = null;
            this.messages = [];
            this.isClosed = true;
            this.isMinimized = false;
            this.firstName = '';
            this.lastName = '';
            this.isHistoryLoaded = false;
        }

        ChatModel.prototype.addMessage = function (text, date, isRead, direction) {
            var message = new ChatMessageModel(text, date, isRead, direction);
            this.messages.push(message);

            return message;
        };

        ChatModel.prototype.createHistoryMessage = function (text, date, isRead, direction) {
            var message = new ChatMessageModel(text, date, isRead, direction);
            message.isHistory = true;

            return message;
        };

        ChatModel.prototype.open = function () {
            // mark all messages as read when we open chat
            _.each(this.messages, function (message) {
                message.isRead = true;
            });

            this.isClosed = false;
        };

        ChatModel.prototype.close = function () {
            this.isClosed = true;
        };

        return ChatModel;
    }
]);