'use strict';

app.service('ChatService', [
    'I8XChat', '$rootScope', 'Session', 'config', 'ChatModel', 'I8XLogger', 'UserCacheService',
    function (I8XChat, $rootScope, Session, config, ChatModel, I8XLogger, UserCacheService) {
        var logger = I8XLogger.loggerFor('Chat.Service');
        var service = this;
        service.chats = [];
        service.contacts = [];
        service.contactsLoaded = false;

        service.connect = function () {
            // first we attach listeners/handlers
            I8XChat.setOnContactsLoadedHandler(function (contacts) {
                onContactsLoaded(contacts);
            });

            I8XChat.setOnUserPresenceChangedHandler(function (jid, isOnline) {
                UserCacheService.updatePresence(getBareUserId(jid), isOnline);
            });

            // then we can connect
            var host = config.connection.chat.host;
            var user = Session.getSession().user.id + config.connection.chat.domain;
            var password = Session.getSessionToken();

            I8XChat.connect(host, user, password);
        };

        service.sendMessage = function (to, message) {
            if(!service.isUserInContacts(getBareUserId(to))) {
                // TODO check if user is in contacts and not subscribed, then subscribe to him
                // when we send a message, if user is not in contacts, let's add him
                logger.info('Adding user to contacts:', to);
                service.addUserToContacts(to);
            }
            var chat = service.getActiveChat(to);
            var chatId = chat.chatId || prepareUserId(chat.userId);

            // TODO add status of message "pending" until it is sent/received
            chat.addMessage(message, new Date(), true, 'out');

            I8XChat.sendMessage(service.getChatId(), chatId, message);
        };

        service.getChatId = function () {
            return I8XChat.getChatId();
        };

        service.isUserInContacts = function (userId) {
            var found = _.find(service.contacts, function (contact) {
                return contact.userId === userId;
            });

            return found !== undefined;
        };

        service.getUserFromContacts = function (userId) {
            var found = _.find(service.contacts, function (contact) {
                return contact.userId === userId;
            });

            return found;
        };

        service.addUserToContacts = function (userId) {
            I8XChat.addUserToContacts(prepareUserId(userId));
        };

        service.removeUserFromContacts = function (userId) {
            I8XChat.removeUserFromContacts(prepareUserId(userId));
        };

        service.loadChatHistory = function (userId, callback) {
            var chat = service.getActiveChat(userId);

            if(chat.isHistoryLoaded === false) {
                // history not loaded, lets load it
                chat.isHistoryLoaded = true; // to prevent multiple loadings
                var preparedUserId = prepareUserId(userId);
                I8XChat.getChatHistory(preparedUserId, function (messages) {
                    var chat = service.getActiveChat(preparedUserId);

                    var historyMessages = [];
                    _.each(messages, function (message) {
                        // first check based on archivedId if message is already added into our array
                        if(!service.findMessageByArchivedId(userId, message.archivedId)) {
                            // if message.from === me, meaning we sent out that message
                            var inout = getBareUserId(message.from) === getBareUserId(service.getChatId()) ? 'out' : 'in';
                            historyMessages.push(chat.createHistoryMessage(message.text, message.date, true, inout));
                        }
                    });

                    _.each(chat.messages, function (message) {
                        historyMessages.push(message);
                    });

                    chat.messages = historyMessages;

                    callback();
                });
            } else {
                // history already loaded, just call callback
                callback();
            }
        };

        service.loadChatLastMessage = function (userId, callback) {
            I8XChat.getChatLastMessage(prepareUserId(userId), callback);
        };

        service.findMessageByArchivedId = function (userId, archivedId) {
            var chat = service.getActiveChat(userId);
            var msg = _.find(chat.messages, function (message) {
                return message.archivedId === archivedId;
            });

            return msg;
        };

        service.getActiveChats = function () {
            return service.chats;
        };

        service.getContacts = function () {
            return service.contacts;
        };

        service.areContactsLoaded = function () {
            return service.contactsLoaded;
        };

        service.getActiveChat = function(userId) {
            var bareUserId = getBareUserId(userId);
            var chat = _.find(service.getActiveChats(), function (chat) {
                return chat.userId === bareUserId;
            });

            if (chat === undefined) {
                chat = new ChatModel(bareUserId);

                service.getActiveChats().push(chat);
            }

            return chat;
        };

        service.openChat = function (userId) {
            var chat = service.getActiveChat(userId);
            chat.isMinimized = false;
            chat.open();
        };

        service.countUnreadMessages = function () {
            var count = 0;
            _.each(service.chats, function (chat) {
                _.each(chat.messages, function (message) {
                    if (!message.isRead) {
                        count++;
                    }
                });
            });

            return count;
        };

        service.subscribe = function (userId) {
            I8XChat.subscribe(prepareUserId(userId));
        };

        service.unsubscribe = function (userId) {
            I8XChat.unsubscribe(prepareUserId(userId));
        };

        service.authorize = function (userId) {
            I8XChat.authorize(prepareUserId(userId));
        };

        service.unauthorize = function (userId) {
            I8XChat.unauthorize(prepareUserId(userId));
        };

        I8XChat.onMessage(function (message) {
            if(!service.isUserInContacts(getBareUserId(message.from))) {
                // TODO check if user is in contacts and not subscribed, then subscribe to him
                // when we receive a message, if user is not in contacts, let's add him
                logger.info('Adding user to contacts:', message.from);
                service.addUserToContacts(message.from);
            }

            var chat = service.getActiveChat(message.from);
            chat.chatId = message.id;

            var msg = chat.addMessage(message.text, message.date, !chat.isClosed, 'in');
            msg.archivedId = message.archivedId;

            // to notify all listeners
            $rootScope.$broadcast('ChatService.onMessage', msg);
        });

        function onContactsLoaded(contacts) {
            logger.debug('Contacts received', contacts);
            _.each(contacts, function (contact) {
                contact.userId = getBareUserId(contact.userId);
            });

            service.contacts = contacts;
            service.contactsLoaded = true;
        }

        function prepareUserId(userId) {
            if (userId && !userId.endsWith(config.connection.chat.domain)) {
                return userId + config.connection.chat.domain;
            }

            return userId;
        }

        function getBareUserId(userId) {
            if (userId) {
                var index = userId.indexOf('@');
                if (index > -1) {
                    return userId.substring(0, index);
                }
            }

            return userId;
        }
    }
]);