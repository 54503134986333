'use strict';

/**
 * Used for Caching Research entity data.
 */
app.service('EntityCacheService', [
    'BackendService', 'I8XLogger', '$timeout','ResearchItemModel','Session','CommentModel','UserCacheService',
    function (BackendService, I8XLogger, $timeout,ResearchItemModel,Session,CommentModel,UserCacheService) {
        var logger = I8XLogger.loggerFor('EntityCacheService');

        var user = Session.getSession().getUser();
        var service = this;
        service.entities = {};

        /**
         * Add the Entity for specified entity ID. It will return object with "entity" property which might be null in case if
         * Entity is not yet loaded. Property "isLoaded" indicates if entity is already loaded.
         *
         * @param {String} entityId The entity ID
         * @return {{entityId: String, entity: Object, isLoaded: boolean, isLoading: boolean}} Cached Entity object
         */
        service.addLoadedEntity = function (entityId, entityType, data, loadDependencies) {
            if (angular.isString(entityId)) {
                var e = createDefaultEntity(entityId,entityType);
                e.data = data;
                e.isLoaded = true;

                if(loadDependencies === true){
                    if(entityType === Publish.PublishType.ARTICLE){
                        _loadSocialForUser([entityId]);
                        e.user = UserCacheService.getUser(data.userId);
                    }else if(entityType === Publish.PublishType.COMMENT){
                        _loadSocialForUser([entityId]);
                        e.parent = service.getEntity(data.entityId,data.entityType);
                        e.user = UserCacheService.getUser(data.userId);
                    }
                }

                service.entities[entityId] = e;
                return service.entities[entityId];
            }
            // in case of error return default entity which is not loaded (and will never be)
            return createDefaultEntity(entityId,entityType);
        };

        /**
         * Gets the Entity for specified entity ID. It will return object with "entity" property which might be null in case if
         * Entity is not yet loaded. Property "isLoaded" indicates if entity is already loaded.
         *
         * @param {String} entityId The entity ID
         * @return {{entityId: String, entity: Object, isLoaded: boolean, isLoading: boolean}} Cached Entity object
         */
        service.getEntity = function (entityId, entityType) {
            if (angular.isString(entityId)) {
                return service.getEntities([entityId],entityType)[0]; // retrieving single entity, we will get array of 1
            }

            // in case of error return default entity which is not loaded (and will never be)
            return createDefaultEntity(entityId,entityType);
        };

        /**
         * Helper function to determine if specified entity is loaded. If it is not loaded <b><i>it will be automatically loaded</i></b>
         * after this function call.
         *
         * @param {String} entityId The Entity ID
         * @return {boolean} true in case if entity is already loaded, otherwise false
         */
        service.isLoaded = function (entityId) {
            return service.getEntity(entityId).isLoaded;
        };

        /**
         * Gets the Entities for specified entities IDs. It will return Array of objects with "entity" property which might be null in case if
         * Entity is not yet loaded. Property "isLoaded" indicates if entity is already loaded.
         *
         * @param {Array.<String>} entityIds The entity ID
         * @return {Array.<{entityId: String, entity: undefined, isLoaded: boolean, isLoading: boolean}>}
         */
        service.getEntities = function (entityIds, entitiesType) {
            var entities = [];

            if (angular.isArray(entityIds) && entityIds.length > 0) {
                var toLoad = []; // entities intended for loading
                entityIds.forEach(function (entityId) {
                    var entity = _getEntity(entityId,entitiesType);
                    if (!entity.isLoaded && !entity.isLoading) {
                        toLoad.push(entityId);
                        // mark as in Loading to prevent multiple unneeded calls to the backend
                        entity.isLoading = true;
                    }

                    entities.push(entity);
                });

                if (toLoad.length > 0) {
                    _loadEntitiesData(toLoad,entitiesType);
                }
            }

            return entities;
        };

        /**
         * Loads specified Entity from the backend.
         *
         * @param {Array.<String>} entityIds The Entities IDs
         * @private
         */
        function _loadEntitiesData(entityIds,entitiesType) {
            logger.debug('Loading entities', entityIds,entitiesType);
            if(angular.isUndefined(entitiesType) || entitiesType === 1) {
                BackendService.doGetEsReq(entityIds, function (response) {
                    response.documents.forEach(function (entity) {
                        _onEntityLoaded(entity.id, entity);
                    });
                }, function (error) {
                    logger.error('Error happened while loading entity doGetEsReq', error);
                    entityIds.forEach(function (id) {
                        _onEntityLoaded(id, null);
                    });
                });
            }else if(entitiesType === 100){
                BackendService.doGetCommentReq(entityIds, function (response) {
                    response.comments.forEach(function (entity) {
                        _onEntityLoaded(entity.commentId, entity);
                    });
                }, function (error) {
                    // TODO implement retry after some period of time to prevent flooding server with request in case of error (e.g. if entity does not exists)
                    logger.error('Error happened while loading entity doGetCommentReq', error);
                    entityIds.forEach(function (id) {
                        _onEntityLoaded(id, null);
                    });
                });
            }

            _loadSocialForUser(entityIds);
        }

        function _loadSocialForUser(entityIds){
            BackendService.doGetSocialOperationForUserIdReq(user.id, entityIds, function (response) {
                logger.debug('Likes loaded', response);
                response.data.forEach(function (d) {
                    var entity = service.entities[d.entityId];
                    if(d.oper === Publish.SocialOperation.SET_LIKE){
                        entity.liked = true;
                        entity.disliked = false;
                    }else if(d.oper === Publish.SocialOperation.SET_DISLIKE){
                        entity.liked = false;
                        entity.disliked = true;
                    }
                })
            }, function (error) {
                // TODO implement retry after some period of time to prevent flooding server with request in case of error (e.g. if entity does not exists)
                logger.error('Error happened while loading likes', error);
            });
        }

        /**
         * Handles Entity data after Entity is loaded from the backend.
         *
         * @param {String} entityId The Entity ID
         * @param {Object} entity The Entity data or null in case if error happened
         * @private
         */
        function _onEntityLoaded(entityId, entity) {
            if(angular.isUndefined(service.entities[entityId])){
                return;
            }
            var d = service.entities[entityId];
            if(angular.isObject(entity)){
                switch (d.entityType){
                    case 1: d.data = new ResearchItemModel(entity); break;
                    case 100:
                        d.data = new CommentModel(entity);
                        d.parent = service.getEntity(entity.entityId,entity.entityType);
                        break;
                }
            }else{
                d.data = null;
            }
            logger.debug('_onEntityLoaded', entityId, JSON.stringify(d.data));

            // it is loaded if it is not null and is object
            d.isLoaded = angular.isObject(entity);

            // it is not anymore in loading if it is not null and is object. If it is null it means it was not loaded (e.g. error happened).
            d.isLoading = !angular.isObject(entity);
        }

        /**
         * Gets specified Entity without loading if Entity is not loaded yet.
         *
         * @param {String} entityId The Entity ID
         * @return {*}
         * @private
         */
        function _getEntity(entityId,entityType) {
            if (service.entities[entityId] === undefined) {
                service.entities[entityId] = createDefaultEntity(entityId,entityType);
            }

            return service.entities[entityId];
        }

        /**
         * Creates Object representing default not yet loaded entity.
         *
         * @param {String} entityId The Entity ID
         * @return {{entityId: String, entity: undefined, isLoaded: boolean, isLoading: boolean}}
         */
        function createDefaultEntity(entityId,entityType) {
            return {
                entityId: entityId,
                entityType: entityType? entityType: 1,//if undefined Article
                data: undefined,
                isLoaded: false,
                isLoading: false,
                liked: undefined,
                disliked: undefined,
                parent: undefined
            };
        }
    }
]);