'use strict';

app.controller('publicationCtrl', [
    '$scope', 'QueryModel', 'config', 'Session','$rootScope','EntityCacheService','$state','$stateParams',
    function ($scope, QueryModel, config, Session,$rootScope,EntityCacheService,$state,$stateParams) {


        $scope.publication = EntityCacheService.getEntity($stateParams.id);

        $scope.controls = {
            documentdetails: {
                onWordClick: function (word) {
                    $state.go("content.search",{q:word});
                }
            }
        };



    }
]);