'use strict';

app.service('I8XLogger', [
    function () {
        var service = this;
        service.loggers = {};

        service.loggerFor = function (tag) {
            if (!service.loggers[tag]) {
                service.loggers[tag] = new I8XLoggerModel(tag);
            }

            return service.loggers[tag];
        };

        function I8XLoggerModel(tag) {
            this.tag = tag;
            this.logLevel = '';

            this.info = this._prepareWrapper(console.info, 'info');
            this.debug = this._prepareWrapper(console.debug, 'debug');
            this.error = this._prepareWrapper(console.error, 'error');
            this.warn = this._prepareWrapper(console.warn, 'warn');
        }

        I8XLoggerModel.prototype._prepareWrapper = function (obj, level) {
            if (this.isLogLevelEnabled(level)) {
                return obj.bind(window.console, this._getTag());
            }

            return this._empty();
        };

        I8XLoggerModel.prototype._empty = function () {
            // do nothing
        };

        I8XLoggerModel.prototype.isLogLevelEnabled = function () {
            // TODO implement
            return true;
        };

        I8XLoggerModel.prototype._getTag = function () {
            return '[' + this.tag + ']';
        };
    }
]);

