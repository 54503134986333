app.controller('chatController', [
    '$scope', '$state', '$timeout', 'config', 'ChatService', 'UserCacheService', 'I8XLogger',
    function ($scope, $state, $timeout, config, ChatService, UserCacheService, I8XLogger) {
        var logger = I8XLogger.loggerFor('ChatController');
        $scope.messagesWaiting = 0;
        $scope.userCache = UserCacheService;

        $scope.openMenu = function ($mdMenu, $event) {
            $mdMenu.open($event);
        };

        $scope.onAllMessages = function (ev) {
            $state.go("content.my.messages");
        };

        $scope.$on('ChatService.onMessage', function (event, args) {
            $timeout(function () {
                onMessageReceived(args);
            });
        });

        $scope.$on('ChatService.initiateChat', function (event, args) {
            $timeout(function () {
                ChatService.openChat(args.userId);
            });
        });

        $scope.openChat = function (userId) {
            $timeout(function () {
                ChatService.openChat(userId);

                // after chat is opened, refresh counters
                $scope.messagesWaiting = ChatService.countUnreadMessages();
            });
        };

        $scope.getActiveChats = ChatService.getActiveChats;
        $scope.getContacts = ChatService.getContacts;

        function onMessageReceived(message) {
            $scope.messagesWaiting = ChatService.countUnreadMessages();
        }
    }
]);