'use strict';

/**
 * Used for Caching Users data.
 */
app.service('UserCacheService', [
    'BackendService', 'I8XLogger', '$timeout',
    function (BackendService, I8XLogger, $timeout) {
        var logger = I8XLogger.loggerFor('UserCacheService');
        var service = this;
        service.users = {};

        /**
         * Gets the User for specified user ID. It will return object with "user" property which might be null in case if
         * User is not yet loaded. Property "isLoaded" indicates if user is already loaded.
         *
         * @param {String} userId The user ID
         * @return {{userId: String, user: Object, isOnline: boolean, isLoaded: boolean, isLoading: boolean}} Cached User object
         */
        service.getUser = function (userId) {
            if (angular.isString(userId)) {
                return service.getUsers([userId])[0]; // retrieving single user, we will get array of 1
            }

            // in case of error return default user which is not loaded (and will never be)
            return createDefaultUser(userId);
        };

        /**
         * Helper function to determine if specified user is loaded. If it is not loaded <b><i>it will be automatically loaded</i></b>
         * after this function call.
         *
         * @param {String} userId The User ID
         * @return {boolean} true in case if user is already loaded, otherwise false
         */
        service.isLoaded = function (userId) {
            return service.getUser(userId).isLoaded;
        };

        /**
         * Gets the Users for specified users IDs. It will return Array of objects with "user" property which might be null in case if
         * User is not yet loaded. Property "isLoaded" indicates if user is already loaded.
         *
         * @param {Array.<String>} userIds The user ID
         * @return {Array.<{userId: String, user: undefined, isOnline: boolean, isLoaded: boolean, isLoading: boolean}>}
         */
        service.getUsers = function (userIds) {
            var users = [];

            if (angular.isArray(userIds) && userIds.length > 0) {
                var toLoad = []; // users intended for loading
                userIds.forEach(function (userId) {
                    var user = _getUser(userId);
                    if (!user.isLoaded && !user.isLoading) {
                        toLoad.push(userId);
                        // mark as in Loading to prevent multiple unneeded calls to the backend
                        user.isLoading = true;
                    }

                    users.push(user);
                });

                if (toLoad.length > 0) {
                    _loadUsersData(toLoad);
                }
            }

            return users;
        };

        /**
         * Updates user presence.
         *
         * @param userId - The user ID
         * @param isOnline - Te presence status
         */
        service.updatePresence = function (userId, isOnline) {
            var user = service.getUser(userId);
            user.isOnline = isOnline;
        };

        /**
         * Loads specified User from the backend.
         *
         * @param {Array.<String>} userIds The Users IDs
         * @private
         */
        function _loadUsersData(userIds) {
            logger.debug('Loading users', userIds);
            BackendService.doGetUserByUserIdReq(userIds, function (response) {
                response.users.forEach(function (user) {
                    _onUserLoaded(user.userId, user);
                });
            }, function (error) {
                // TODO implement retry after some period of time to prevent flooding server with request in case of error (e.g. if user does not exists)
                logger.error('Error happened while loading user', error);
                userIds.forEach(function (id) {
                    _onUserLoaded(id, null);
                });
            });
        }

        /**
         * Handles User data after User is loaded from the backend.
         *
         * @param {String} userId The User ID
         * @param {Object} user The User data or null in case if error happened
         * @private
         */
        function _onUserLoaded(userId, user) {
            service.users[userId].user = angular.isObject(user) ? user : null;
            logger.debug('_onUserLoaded', userId, JSON.stringify(service.users[userId].user));

            // it is loaded if it is not null and is object
            service.users[userId].isLoaded = angular.isObject(user);

            // it is not anymore in loading if it is not null and is object. If it is null it means it was not loaded (e.g. error happened).
            service.users[userId].isLoading = !angular.isObject(user);
        }

        /**
         * Gets specified User without loading if User is not loaded yet.
         *
         * @param {String} userId The User ID
         * @return {*}
         * @private
         */
        function _getUser(userId) {
            if (service.users[userId] === undefined) {
                service.users[userId] = createDefaultUser(userId);
            }

            return service.users[userId];
        }

        /**
         * Creates Object representing default not yet loaded user.
         *
         * @param {String} userId The User ID
         * @return {{userId: String, user: undefined, isOnline: boolean, isLoaded: boolean, isLoading: boolean}}
         */
        function createDefaultUser(userId) {
            return {
                userId: userId,
                user: undefined,
                isOnline: false,
                isLoaded: false,
                isLoading: false
            };
        }
    }
]);