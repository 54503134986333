
app.controller('researchItemClaimCtrl', ['$scope', 'Session','currentArticle','Toast','$mdDialog','Web3Service','$mdConstant',
    'BackendService',
    function ($scope, Session, currentArticle,Toast,$mdDialog,Web3Service,$mdConstant,
              BackendService) {

        $scope.claim = {
            aid: currentArticle.id,
            hash: Web3Service.uuid2hash(currentArticle.id),
            ownerAddresses: [],
            doi: currentArticle.doi
        };

        $scope.user = Session.getSession().getUser();
        $scope.separatorKeys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA, $mdConstant.KEY_CODE.SPACE];


        $scope.transformChip = function($chip) {
            if (/^(0x){1}[0-9a-fA-F]{40}$/i.test($chip)) {
                var a = $chip.toLowerCase();
                if(a === $scope.user.bcAddress){
                    return null;
                }
                return a;
            }
            return null;
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.claim.$valid) {
                var dto = angular.copy($scope.claim);
                dto.ownerAddresses.unshift($scope.user.bcAddress);
                console.log(dto);
                BackendService.doInitiateClaimArticle(dto.aid, $scope.user.id,
                    function (response) {
                        console.log("Success in claimArticle", response);
                        Toast.success("Article claimed successfully!");
                        $mdDialog.hide(response);
                    }, function (error) {
                        console.log("Error claimArticle", error);
                    });
                // Web3Service.doClaimArticle(dto).then(function(response){
                //     console.log("Success in onClaimArticle",response);
                //     Toast.success("Article claimed successfully!");
                //     $mdDialog.hide(dto);
                // },function () {
                //     console.log("Error in onClaimArticle");
                //     Toast.error("Claiming article failed!");
                // });
            }
        };
    }]);
