'use strict';

app.controller('signupCtrl', [
    '$scope', '$state', 'config', 'localstorage', '$timeout', 'LoginService', 'BackendService', '$stateParams','Session',
    function ($scope, $state, config, localstorage, $timeout, LoginService, BackendService, $stateParams,Session) {

        $scope.form = {};
        $scope.title = 'infinitcodex signup';
        $scope.agreeToTermsOfService=false;

        var user = Session.getSession().getUser();
        $scope.user = {
            userId: user.id,
            firstName: user.firstName===null? undefined: user.firstName,
            lastName: user.lastName===null? undefined: user.lastName,
            profile: user.profile===null? undefined: user.profile
        };


        $scope.userProfiles = [
            {
                id: User.UserProfile.RESEARCHER,
                text: 'Researcher'
            }, {
                id: User.UserProfile.PUBLISHER,
                text: 'Publisher'
            }, {
                id: User.UserProfile.JOURNALIST,
                text: 'Journalist'
            }, {
                id: User.UserProfile.MARKETING,
                text: 'Marketing'
            }
        ];

        $scope.onCancel = function () {
            Session.logout();
            if ($stateParams.providerSignup === true) {
                var provider = LoginService.getCurrentLoginProvider();
                if (provider) {
                    provider.logout();
                }
            } else {
                $state.go('accounts.login');
            }
        };

        $scope.onNext = function () {
            if($scope.form.user.$valid){
                sendSignUpToServer($scope.user);
            }
        };

        function sendSignUpToServer(dto) {
            $scope.loading = true;
            BackendService.doSignUp(dto, function (response) {
                console.log("sign up success!",response);
                onSignUpResponse(response)
            }, function (error) {
                console.log('Login error: ', error);
                $scope.loading = false;
            });
        }

        function onSignUpResponse(response) {
            Session.getSession().setUser(response.user);
            $state.go('content.dashboard');
        }
    }
]);