app.factory("CommentService", function(config,$window, $timeout,$http,Session,$q, Web3Service,$rootScope,$mdDialog,
                                       Toast) {

    var thiz = this;

    var user = Session.getSession().getUser();
    thiz.canComment = function(item){
        if(item===undefined){
            return false;
        }
        return item.userId === user.id;
        //return item.claimedAuthorIds !== null && item.claimedAuthorIds.length>0
        //    && !_.contains(item.claimedAuthorIds,user.id);
    };

    thiz.canUpdate = function(item){
        if(item===undefined){
            return false;
        }
        return item.data.userId === user.id;
        //return item.claimedAuthorIds !== null && item.claimedAuthorIds.length>0
        //    && !_.contains(item.claimedAuthorIds,user.id);
    };

    thiz.onUpdate = function (item,ev) {
        return $mdDialog.show({
            controller: 'commentUpdateCtrl',
            templateUrl: 'app/module/comment/comment-update.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            locals: {
                currentComment: item
            }
        }).then(function (response) {
            item.data.text = response.text;
            item.data.updatedTime = response.updatedTime;
        });
    };

    return thiz;
});
