'use strict';

app.provider('LazyLoader', [
    function () {
        return {
            resolve: loadDependency,
            resolveProviderDependencies: loadProviderDependencies,

            $get: function () {
                return {
                    resolve: loadDependency,
                    resolveProviderDependencies: loadProviderDependencies
                }
            }
        };

        function loadDependency() {
            var args = arguments;

            return {
                dependencies: [
                    '$ocLazyLoad', '$q', 'APP_DEPENDENCIES',
                    function ($ocLazyLoad, $q, APP_DEPENDENCIES) {
                        var promises = [];

                        for (var i = 0; i < args.length; i++) {
                            var dependencies = APP_DEPENDENCIES.dependencies[args[i]];
                            if (dependencies) {
                                dependencies.forEach(function (dependency) {
                                    promises.push($ocLazyLoad.load(dependency));
                                });
                            }
                        }

                        return $q.all(promises);
                    }
                ]
            }
        }

        function loadProviderDependencies() {
            // TODO load dependencies from provider
            return loadDependency('google-signin');
        }
    }
]);
