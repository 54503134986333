'use strict';

app.service('BackendService', [
    'ConnectionService',
    function (ConnectionService) {
        var self = this;



        ////////////////////////////////////////////////////////////////
        //                            USER
        ////////////////////////////////////////////////////////////////
        self.doLoginWithGoogle = function (token, email, responseCallback, errorCallback) {
            var request = new User.LoginReq({
                email: email,
                provider: User.LoginProvider.GOOGLE,
                idToken: token
            });

            ConnectionService.sendMessage(Msg.MsgType.LOGIN_REQ, request, responseCallback, errorCallback);
        };

        self.doLoginWithFacebook = function (token, email, responseCallback, errorCallback) {
            var request = new User.LoginReq({
                email: email,
                provider: User.LoginProvider.FB,
                idToken: token
            });

            ConnectionService.sendMessage(Msg.MsgType.LOGIN_REQ, request, responseCallback, errorCallback);
        };

        self.doLoginWithOrcid = function (token, responseCallback, errorCallback) {
            var request = new User.LoginReq({
                provider: User.LoginProvider.ORCID,
                idToken: token
            });

            ConnectionService.sendMessage(Msg.MsgType.LOGIN_REQ, request, responseCallback, errorCallback);
        };

        self.doLoginWithToken = function (responseCallback, errorCallback) {
            var request = new User.LoginReq({});

            ConnectionService.sendMessage(Msg.MsgType.LOGIN_REQ, request, responseCallback, errorCallback);
        };

        self.doSignUp = function (dto, responseCallback, errorCallback) {
            var request = new User.SignupReq(dto);
            console.log(request);
            ConnectionService.sendMessage(Msg.MsgType.SIGNUP_REQ, request, responseCallback, errorCallback);
        };

        self.doLogout = function (responseCallback, errorCallback) {
            var request = new User.Logout();
            ConnectionService.sendMessage(Msg.MsgType.LOGOUT, request, responseCallback, errorCallback);
        };

        self.doUpdateUser = function (user,responseCallback, errorCallback) {
            var request = new User.UpdateUser({ user: user });
            console.log("doUpdateUser", request);
            ConnectionService.sendMessage(Msg.MsgType.UPDATE_USER, request, responseCallback, errorCallback);
        };

        self.doSetUserFlowReq = function (uid,flow,responseCallback, errorCallback) {
            var request = new User.SetUserFlowReq({ userId: uid, flow: flow });
            console.log("doSetUserFlowReq", request);
            ConnectionService.sendMessage(Msg.MsgType.SET_USER_FLOW_REQ, request, responseCallback, errorCallback);
        };

        self.doGetUserReq = function (pageSize,pageState,responseCallback, errorCallback) {
            var request = new User.GetUserReq({
                pageSize: pageSize,
                pageState: pageState});
            console.log("doGetUserReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_USER_REQ, request, responseCallback, errorCallback);
        };


        self.doGetUserByUserIdReq = function (userIds,responseCallback, errorCallback) {
            var request = new User.GetUserByUserIdReq({ userIds: userIds });
            console.log("doGetUserByUserIdReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_USER_BY_USER_ID_REQ, request, responseCallback, errorCallback);
        };

        self.doGetEducationReq = function (userId, responseCallback, errorCallback) {
            var request = new User.GetEducationReq({
                userId: userId
            });
            console.log("doGetEducationReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_EDUCATION_REQ, request, responseCallback, errorCallback);
        };

        self.doUpdateEducation = function (userId, education, responseCallback, errorCallback) {
            var request = new User.UpdateEducation({
                userId: userId,
                education: education
            });
            console.log("doUpdateEducation", request);
            ConnectionService.sendMessage(Msg.MsgType.UPDATE_EDUCATION, request, responseCallback, errorCallback);
        };

        self.doDeleteEducation = function (userId, id, responseCallback, errorCallback) {
            var request = new User.DeleteEducation({
                userId: userId,
                id: id
            });
            console.log("doDeleteEducation", request);
            ConnectionService.sendMessage(Msg.MsgType.DELETE_EDUCATION, request, responseCallback, errorCallback);
        };

        self.doGetExperienceReq = function (userId, responseCallback, errorCallback) {
            var request = new User.GetExperienceReq({
                userId: userId
            });
            console.log("doGetExperienceReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_EXPERIENCE_REQ, request, responseCallback, errorCallback);
        };

        self.doUpdateExperience = function (userId, experience, responseCallback, errorCallback) {
            var request = new User.UpdateExperience({
                userId: userId,
                experience: experience
            });
            console.log("doUpdateExperience", request);
            ConnectionService.sendMessage(Msg.MsgType.UPDATE_EXPERIENCE, request, responseCallback, errorCallback);
        };

        self.doDeleteExperience = function (userId, id, responseCallback, errorCallback) {
            var request = new User.DeleteExperience({
                userId: userId,
                id: id
            });
            console.log("doDeleteExperience", request);
            ConnectionService.sendMessage(Msg.MsgType.DELETE_EXPERIENCE, request, responseCallback, errorCallback);
        };

        self.doFollowUserReq = function (userId, followUserId, responseCallback, errorCallback) {
            var request = new User.FollowUserReq({
                userId: userId,
                followUserId: followUserId
            });
            console.log("doFollowUserReq", request);
            ConnectionService.sendMessage(Msg.MsgType.FOLLOW_USER_REQ, request, responseCallback, errorCallback);
        };

        self.doUnfollowUserReq = function (userId, followUserId, responseCallback, errorCallback) {
            var request = new User.UnfollowUserReq({
                userId: userId,
                followUserId: followUserId
            });
            console.log("doUnfollowUserReq", request);
            ConnectionService.sendMessage(Msg.MsgType.UNFOLLOW_USER_REQ, request, responseCallback, errorCallback);
        };

        self.doGetFollowingReq = function (userId, responseCallback, errorCallback) {
            var request = new User.GetFollowingReq({
                userId: userId
            });
            console.log("doGetFollowingReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_FOLLOWING_REQ, request, responseCallback, errorCallback);
        };

        self.doGetFollowersReq = function (userId, responseCallback, errorCallback) {
            var request = new User.GetFollowersReq({
                userId: userId
            });
            console.log("doGetFollowersReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_FOLLOWERS_REQ, request, responseCallback, errorCallback);
        };

        self.doGetFollowingTimelineReq = function (userId, date, responseCallback, errorCallback) {
            var request = new User.GetFollowingTimelineReq({
                userId: userId,
                date: date
            });
            console.log("doGetFollowingTimelineReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_FOLLOWING_TIMELINE_REQ, request, responseCallback, errorCallback);
        };

        self.doGetNotificationReq = function (userId, history, fromTime, pageSize, pageState, responseCallback, errorCallback) {
            var request = new User.GetNotificationReq({
                userId: userId,
                history: history,
                fromTime: fromTime,
                pageSize: pageSize,
                pageState: pageState
            });
            console.log("doGetNotificationReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_NOTIFICATION_REQ, request, responseCallback, errorCallback);
        };

        self.doAckNotification = function (userId, createdTime, notifType, responseCallback, errorCallback) {
            var request = new User.AckNotification({
                userId: userId,
                createdTime: createdTime,
                notifType: notifType
            });
            console.log("doAckNotification", request);
            ConnectionService.sendMessage(Msg.MsgType.ACK_NOTIFICATION, request, responseCallback, errorCallback);
        };


        ////////////////////////////////////////////////////////////////
        //                            SEARCH
        ////////////////////////////////////////////////////////////////
        self.doSearchEs = function (dto,responseCallback, errorCallback) {
            var request = new Search.SearchEsReq(dto);
            console.log("doSearchEs", request);
            ConnectionService.sendMessage(Msg.MsgType.SEARCH_ES_REQ, request, responseCallback, errorCallback);
        };

        self.doSearchVocabularyReq = function (dto,responseCallback, errorCallback) {
            var request = new Search.SearchVocabularyReq(dto);
            console.log("doSearchVocabularyReq", request);
            ConnectionService.sendMessage(Msg.MsgType.SEARCH_VOCABULARY_REQ, request, responseCallback, errorCallback);
        };

        self.doGetEsReq = function (ids,responseCallback, errorCallback) {
            var request = new Search.GetEsReq({
                indexName: "document",
                type: "article",
                ids: ids
            });
            console.log("doGetEsReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_ES_REQ, request, responseCallback, errorCallback);
        };

        ////////////////////////////////////////////////////////////////
        //                            WEBSHOT
        ////////////////////////////////////////////////////////////////
        self.doGetWebshotReq = function (id, url, responseCallback, errorCallback) {
            var request = new Webshot.GetWebshotReq({
                id: id,
                url: url
            });
            console.log("doGetWebshotReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_WEBSHOT_REQ, request, responseCallback, errorCallback);
        };

        ////////////////////////////////////////////////////////////////
        //                            PUBLISH
        ////////////////////////////////////////////////////////////////
        self.doGetMyPublishReq = function (uid,responseCallback, errorCallback) {
            var request = new Publish.GetMyPublishReq({ userId: uid });
            console.log("doGetMyPublishReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_MY_PUBLISH_REQ, request, responseCallback, errorCallback);
        };

        self.doInitiateClaimArticle = function (aid,uid,responseCallback, errorCallback) {
            var request = new Publish.InitiateClaimArticle({ articleId: aid, userId: uid });
            console.log("doInitiateClaimArticle", request);
            ConnectionService.sendMessage(Msg.MsgType.INITIATE_CLAIM_ARTICLE, request, responseCallback, errorCallback);
        };
        self.doUpdateArticle = function (article,responseCallback, errorCallback) {
            var request = new Publish.UpdateArticleReq({ article:article});
            console.log("doUpdateArticleReq", request);
            ConnectionService.sendMessage(Msg.MsgType.UPDATE_ARTICLE_REQ, request, responseCallback, errorCallback);
        };

        self.doDeleteArticle = function (articleId,responseCallback, errorCallback) {
            var request = new Publish.DeleteArticleReq({ articleId:articleId});
            console.log("doDeleteArticle", request);
            ConnectionService.sendMessage(Msg.MsgType.DELETE_ARTICLE_REQ, request, responseCallback, errorCallback);
        };

        self.doGetArticleReq = function (userId,articleIds,updatedTime,responseCallback, errorCallback) {
            var request = new Publish.GetArticleReq({
                userId: userId,
                articleIds: articleIds,
                updatedTime: updatedTime });
            console.log("doGetArticleReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_ARTICLE_REQ, request, responseCallback, errorCallback);
        };

        self.doGetPublishArticleReq = function (userId,articleIds,responseCallback, errorCallback) {
            var request = new Publish.GetPublishArticleReq({
                userId: userId,
                articleIds: articleIds });
            console.log("doGetPublishArticleReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_PUBLISH_ARTICLE_REQ, request, responseCallback, errorCallback);
        };

        self.doUpdateCommentReq = function (comment,responseCallback, errorCallback) {
            var request = new Publish.UpdateCommentReq({ comment:comment});
            console.log("doUpdateComment", request);
            ConnectionService.sendMessage(Msg.MsgType.UPDATE_COMMENT_REQ, request, responseCallback, errorCallback);
        };
        self.doGetCommentReq = function (commentIds, responseCallback, errorCallback) {
            var request = new Publish.GetCommentReq({ commentIds: commentIds});
            console.log("doGetCommentReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_COMMENT_REQ, request, responseCallback, errorCallback);
        };

        self.doGetCommentForEntityReq = function (entityId, pageSize, pageState, responseCallback, errorCallback) {
            var request = new Publish.GetCommentForEntityReq({
                entityId: entityId,
                pageSize: pageSize,
                pageState: pageState
            });
            console.log("doGetCommentForEntityReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_COMMENT_FOR_ENTITY_REQ, request, responseCallback, errorCallback);
        };

        self.doUpdateReview = function (review,responseCallback, errorCallback) {
            var request = new Publish.UpdateReviewReq({ review:review});
            console.log("doUpdateReview", request);
            ConnectionService.sendMessage(Msg.MsgType.UPDATE_REVIEW_REQ, request, responseCallback, errorCallback);
        };
        self.doGetReviewReq = function (userId, reviewIds, responseCallback, errorCallback) {
            var request = new Publish.GetReviewReq({ userId: userId, reviewIds: reviewIds});
            console.log("doGetReviewReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_REVIEW_REQ, request, responseCallback, errorCallback);
        };

        self.doGetPublishReviewReq = function (articleId, reviewIds, responseCallback, errorCallback) {
            var request = new Publish.GetPublishReviewReq({ articleId: articleId, reviewIds: reviewIds});
            console.log("doGetPublishReviewReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_PUBLISH_REVIEW_REQ, request, responseCallback, errorCallback);
        };

        self.doSetSocialOperationReq = function (userId, entityId, oper, responseCallback, errorCallback) {
            var request = new Publish.SetSocialOperationReq({
                userId: userId,
                entityId: entityId,
                oper: oper
            });
            console.log("doSetSocialOperationReq", request);
            ConnectionService.sendMessage(Msg.MsgType.SET_SOCIAL_OPERATION_REQ, request, responseCallback, errorCallback);
        };

        self.doGetUserIdsForSocialOperationReq = function (entityId, oper, pageSize, pageState, responseCallback, errorCallback) {
            var request = new Publish.GetUserIdsForSocialOperationReq({
                entityId: entityId,
                oper: oper,
                pageSize: pageSize,
                pageState: pageState
            });
            console.log("doGetUserIdsForSocialOperationReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_USER_IDS_FOR_SOCIAL_OPERATION_REQ, request, responseCallback, errorCallback);
        };

        self.doGetSocialOperationForUserIdReq= function (userId, entityIds, responseCallback, errorCallback) {
            var request = new Publish.GetSocialOperationForUserIdReq({
                userId: userId,
                entityIds: entityIds
            });
            console.log("doGetSocialOperationForUserIdReq", request);
            ConnectionService.sendMessage(Msg.MsgType.GET_SOCIAL_OPERATION_FOR_USER_ID_REQ, request, responseCallback, errorCallback);
        };

        return this;
    }
]);