'use strict';

app.controller('loginCtrl', [
    '$scope', '$state', 'config', 'localstorage', '$timeout', 'LoginService', 'BackendService', '$stateParams', 'Session', 'ChatService',
    function ($scope, $state, config, localstorage, $timeout, LoginService, BackendService, $stateParams, Session, ChatService) {
        $scope.title = 'infinitcodex login';
        $scope.loading = false;
        $scope.config = config;

        if ($stateParams.autologin === true) {
            $scope.loading = true;

            console.log("AUTOLOGIN");
            var token = Session.getSessionToken();
            if(token !== null){
                BackendService.doLoginWithToken(function (response, message) {
                    onLoginResponse(response, message)
                }, function (error) {
                    console.log('Login error: ', error);
                    $scope.loading = false;
                });
            }else {
                var provider = LoginService.getLoginProvider($stateParams.provider);
                provider.loginToBackend($stateParams.token, null, function (response, message) {
                    onLoginResponse(response, message)
                }, function (error) {
                    console.log('Login error: ', error);
                    $scope.loading = false;
                });
            }
            return;
        }

        LoginService.initProviders();

        $scope.$on(config.signin.service_base.login_event, function (event, args) {
            $timeout(function () {
                $scope.loading = true;

                sendLoginToServer(args.provider, args.data);
            });
        });

        $scope.onLogin = function (providerName) {
            var provider = LoginService.getLoginProvider(providerName);
            provider.login();
        };

        $scope.doSignUp = function () {
            $state.go('accounts.signup', {
                providerSignup: false
            });
        };

        function sendLoginToServer(service, userData) {
            var provider = LoginService.getLoginProvider(service.provider);
            provider.doLoginToBackend(userData.token, userData.email, function (response, message) {
                onLoginResponse(response, message);
            }, function (error) {
                console.log('Login error: ', error);
                $scope.loading = false;
            });
        }

        function onLoginResponse(data, message) {
            console.log(data);
            if (data && data.user && (data.user.flow === User.UserFlow.BC_USER_REGISTER_COMPLETED ||
                    data.user.flow === User.UserFlow.BC_USER_REGISTER_PENDING ||
                    data.user.flow === User.UserFlow.COMPLETED || data.user.flow === User.UserFlow.SIGNUP_COMPLETED)) {
                Session.getSession().setUser(data.user);
                Session.getSession().setToken(message.sessionToken,data.sessionTokenExpirationTime);
                Session.getSession().setId(message.sessionId);

                console.log('Login COMPLETED: ', data);

                ChatService.connect();

                redirect();
            } else if (data && data.user && data.user.flow === User.UserFlow.SIGNUP_REQUIRED) {
                Session.getSession().setUser(data.user);
                Session.getSession().setToken(message.sessionToken,data.sessionTokenExpirationTime);
                Session.getSession().setId(message.sessionId);

                console.log('Login SIGNUP_REQUIRED: ', data);
                $state.go('accounts.signup', {
                    providerSignup: true,
                    email: data.user.email,
                    firstName: data.user.firstName,
                    lastName: data.user.lastName
                });
            } else if (data && data.error) {
                $scope.loading = false;
                console.error('Failed to login: ', data.error);
                $state.go('accounts');
            }

            // TODO handle other responses

            // TODO redirect to failed?
            //$state.go('content');
        }

        function redirect(){
            if($stateParams.redirect){
                var r = JSON.parse($stateParams.redirect);
                $state.go(r.state,r.params);
            }else{
                $state.go('content.dashboard');
            }
        }
    }
]);