'use strict';

app.directive('chatboxInput', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'app/pages/chat/chatbox-input/chatbox-input.html',
            replace: true,
            link: link,
            scope: {
                onMessage: '&'
            }
        };

        function link(scope, element, attrs) {
            scope.text = '';

            scope.onMsgKeyPress = function (event) {
                if (event.charCode === 13) {
                    var text = getText();
                    if (text.length > 0) {
                        onMessage(text);
                    }
                }
            };

            function getText() {
                return scope.text.trim();
            }

            function onMessage(text) {
                // reset input text
                scope.text = '';

                invokeCallback(text);
            }

            function invokeCallback(text) {
                if (angular.isFunction(scope.onMessage)) {
                    scope.onMessage({text: text});
                }
            }
        }
    }
]);