
/* global angular, app */

app.factory('QueryModel', ['KeywordModel', 'Session', 'Toast', 'config',
    function (KeywordModel, Session, Toast, config) {

        QueryModel.addUserData = function (isEmpty) {
            var arr = [];
            var user = Session.getSession().getUser();
            var ks = user.keywords;
            if(ks!== null && ks.length > 0){
                for(var i=0;i<ks.length;i++){
                    var k = new KeywordModel(ks[i]);
                    k.setType((isEmpty && i===0)?"m":"s");
                    if (k.isValid())
                        arr.push(keyword2SearchData(k));
                }
            }

            var fos = user.fos;
            if(fos!== null && fos.length > 0){
                for(var i=0;i<fos.length;i++){
                    var k = new KeywordModel("f:"+fos[i]+"_s");
                    if (k.isValid())
                        arr.push(keyword2SearchData(k));
                }
            }
            return arr;
        };

        
        /**
         * Create new Query.
         * @param object represents response data from logedin rest
         * @constructor
         */

        function QueryModel(string,type) {
            this.type = type;
            if(this.type === undefined){
                this.type = "search";
            }
            this.fields = ["title", "summary"];
            this.data = [];
            if (angular.isString(string)) {
                var arr = string.split(",");
                for (var i in arr) {
                    var k = new KeywordModel(arr[i]);
                    if (k.isValid())
                        this.data.push(k);
                }
                this.setValid()
            }
        }

        QueryModel.prototype.toString = function () {
            var arr = [];
            for (var i in this.data) {
                arr.push(this.data[i].toString());
            }
            return arr.join(",");
        };

        QueryModel.prototype.toDto = function (start,size,options) {
            var dto = {
                indexName: "document",
                start: start,
                size: size,
                search: []
            };
            if(options.sortBy && options.sortBy !== 'relevance'){
               dto.sortType = options.sortBy;
               dto.sortOrder = "desc";
            }
            if(options.range !== undefined){
               dto.range = options.range;
            }

            dto.search = QueryModel.addUserData(false);//this.isSearchEmpty());

            dto.search.push(new Search.SearchData({
                value: "article",
                type: Search.SearchDataType.TYPE,
                operation: Search.SearchOperation.MUST
            }));

            for (var i in this.data) {
                var k = this.data[i];
                if (k.source.range === true) {
                    dto.range = k.dto;
                } else {
                    dto.search.push(keyword2SearchData(k));
                }
            }
            console.log(dto);
            return dto;
        };

        QueryModel.prototype.isEmpty = function () {
            return this.data.length === 0;
        };

        QueryModel.prototype.isSearchEmpty = function () {
            if(this.data.length === 0) return true;

            for(var i in this.data){
                if(this.data[i].range!==true) return false;
            }

            return true;
        };

        QueryModel.prototype.clone = function () {
            var cloned = angular.copy(this);

            // Remove as it's angular specific.
            delete cloned.$$hashKey;

            return cloned;
        };

        QueryModel.prototype.removeKeyword = function (keyword) {
            var index = this.data.indexOf(keyword);
            if (index > -1) {
                this.data.splice(index, 1);
            }
            return this;
        };

        QueryModel.prototype.containsKeyword = function (string) {
            return angular.isDefined(this.getKeyword(string));
        };

        /**
         * Checks whether specified Keyword Model is already presented in data array (matching word and type).
         * @param {KeywordModel} model
         * @return {boolean} true if model already presents in data, otherwise false
         */
        QueryModel.prototype.containsKeywordModel = function (model) {
            return angular.isDefined(
                _.find(this.data, function (item) {
                    return item.word === model.word && item.type === model.type;
                })
            );
        };

        QueryModel.prototype.getKeyword = function (string) {
            return _.find(this.data, function (item) {
                return item.word === string;
            });
        };

        QueryModel.prototype.addKeyword = function (string) {
            var arr = string.split(" ");
            var b = false;
            for(var i in arr){
                var k = arr[i];
                if (!this.containsKeyword(k)) {
                    var k = new KeywordModel(k);
                    if (k.isValid()) {
                        // if (this.data.length > 0) {
                        //     k.nextType();
                        // }
                        this.data.push(k);
                        b = true;
                    }
                }
            }
            return b;
        };

        QueryModel.prototype.addSingleKeyword = function (string) {
            var keywordModel = new KeywordModel(string);
            if (keywordModel.isValid() && !this.containsKeywordModel(keywordModel)) {
                this.data.push(keywordModel);
            }
        };

        QueryModel.prototype.nextType = function (keyword,reverse) {
            if (this.containsKeyword(keyword.word)) {
                if (keyword.type.short !== "m" || _.filter(this.data, function (item) {
                    return item.type.short === "m" && item.source.range!==true;
                }).length > 1) {
                    keyword.nextType(reverse);
                    return true;
                }
            }
            Toast.info("At least one keyword must have type \"filter\".");
            return false;
        };

        QueryModel.prototype.clear = function (string) {
            this.data = [];
            return this;
        };
        
        QueryModel.prototype.setValid = function () {
            var isValid = true;
            for(var i=0;i<this.data.length;i++){
                var d = this.data[i];
                if(d.word === ''){
                    isValid = false;
                    this.data.splice(i, 1);
                    i--;
                }else if(d.soureProvidedInWord){
                    // don't split
                } else {
                    if(d.word.indexOf(" ")>-1){
                        isValid = false;
                        var arr = d.word.split(" ");
                        var type = d.type.short;
                        for(var j in arr){
                            if(arr[j].length > 0) {
                                this.data.push(new KeywordModel(arr[j] + "_" + type));
                            }
                        }
                        this.data.splice(i,1);
                        i--;
                    }
                }
            }

            //remove forbidden keywords for this type!
            for(var i=0;i<this.data.length;i++) {
                if(!_.contains(this.data[i].source.showIn,this.type)) {
                    this.data.splice(i, 1);
                    i--;
                }
            }


            //at least one must!
            if (_.filter(this.data, function (item) { return (item.type.short === "m" && item.source.range!==true);}).length === 0) {
                for(var i=0;i<this.data.length;i++) {
                    if(this.data[i].source.range!==true) {
                        isValid = false;
                        this.data[i].setType('m');
                        break;
                    }
                }
            }

            //max one keyword for range!
            var foundFirst = false;
            for(var i=0;i<this.data.length;i++) {
                if(this.data[i].source.range===true) {
                    if(foundFirst){
                        isValid = false;
                        this.data.splice(i, 1);
                        i--;
                    }else{
                        foundFirst = true;
                    }
                }
            }
            return isValid;
        };

        QueryModel.prototype.setFields = function (arr) {
            if (_.isArray(arr)) {
                this.fields = arr;
            }
        };

        /**
         * Converts the specified keyword into Search Data.
         *
         * @param keyword The keyword
         * @returns {SearchData} The Search Data
         */
        function keyword2SearchData(keyword) {
            return new Search.SearchData({
                value: keyword.word,
                type: keyword2SearchDataType(keyword),
                operation: keyword2SearchOperation(keyword)

            });



            /**
             * Gets The Search Data Type from specified keyword.
             *
             * @param keyword The keyword
             * @returns {number} The Search Data Type
             */
            function keyword2SearchDataType(keyword) {
                switch (keyword.source.short) {
                    case 'w':
                        return Search.SearchDataType.KEYWORD;
                    case 'a':
                        return Search.SearchDataType.AUTHOR;
                    case 'o':
                        return Search.SearchDataType.ORG;
                    case 's':
                        return Search.SearchDataType.ASSIGNEE;
                    case 'f':
                        return Search.SearchDataType.FOS;
                    case 't':
                        return Search.SearchDataType.TWEET_TEXT;

                    default:
                        console.error('Unknown keyword source: ' + keyword.source.short);
                        return Search.SearchDataType.KEYWORD;
                }
            }
        }

        /**
         * Gets the Search Operation from specified keyword.
         *
         * @param keyword The keyword
         * @returns {number} The Search Operation
         */
        function keyword2SearchOperation(keyword) {
            switch (keyword.type.short) {
                case 'm':
                    return Search.SearchOperation.MUST;
                case 's':
                    return Search.SearchOperation.SHOULD;
                case 'n':
                    return Search.SearchOperation.MUST_NOT;

                default:
                    console.error('Unknown keyword type: ' + keyword.type.short);
                    return Search.SearchOperation.MUST;
            }
        }

        // Return constructor
        return QueryModel;
    }
]);