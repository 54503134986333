'use strict';

app.controller('searchCtrl', [
    '$scope', 'QueryModel', 'config', 'Session', 'Web3Service','$rootScope','localstorage','$state','$stateParams',
    function ($scope, QueryModel, config, Session, Web3Service,$rootScope,localstorage,$state,$stateParams) {

        $scope.keywords = new QueryModel($stateParams.q,"search");

        $scope.redirect = function () {
            var params = {
                q: undefined
            };
            if (!$scope.keywords.isEmpty()) {
                params.q = $scope.keywords.toString();
            }

            $state.go("content.search", params, {
                reload: false,
                inherit: true,
                notify: false
            });
            //localstorage.set("keywords",$scope.keywords.toString());
        };

        $scope.listControl = {
            redirect: $scope.redirect,
            keywords: $scope.keywords,
            type: "complex"
        };

        $scope.filterControl = {
            redirect: $scope.redirect,
            keywords: $scope.keywords,
            type: "complex"
        };

    }
]);