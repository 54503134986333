'use strict';

app.config([
    '$stateProvider', 'LazyLoaderProvider',
    function ($stateProvider, LazyLoaderProvider) {
        $stateProvider
            .state('accounts', {
                url: '/accounts',
                templateUrl: 'app/pages/accounts/accounts.html',
                redirectTo: 'accounts.login'
            })
            .state('accounts.invite', {
                url: '/invite',
                templateUrl: 'app/pages/accounts/invite/invite.html'
            })
            .state('accounts.login', {
                url: '/login',
                templateUrl: 'app/pages/accounts/login/login.html',
                resolve: LazyLoaderProvider.resolve('google-signin', 'facebook-signin'),
                params: {
                    autologin: false
                }
            })
            .state('accounts.autologin', {
                url: '/autologin?token&provider?redirect',
                templateUrl: 'app/pages/accounts/login/login.html',
                params: {
                    autologin: true
                }
            })
            .state('accounts.signup', {
                url: '/signup',
                templateUrl: 'app/pages/accounts/signup/signup.html',
                params: {
                    providerSignup: false,
                    email: null,
                    firstName: null,
                    lastName: null
                }
            })
            .state('accounts.lost', {
                url: '/lost',
                templateUrl: 'app/pages/accounts/lost/lost.html'
            })

            .state('content', {
                url: '/content',
                templateUrl: 'app/pages/content/content.html',
                redirect: 'content.dashboard',
                resolve: LazyLoaderProvider.resolveProviderDependencies() // resolves current login provider dependencies
            })
            .state('content.dashboard', {
                url: '/dashboard',
                templateUrl: 'app/pages/content/dashboard/dashboard.html'
            })
            .state('content.search', {
                url: '/search?q',
                templateUrl: 'app/pages/content/search/search.html'
            })
            .state('content.profile', {
                url: '/profile/{id}',
                templateUrl: 'app/pages/content/profile/profile.html'
            })
            .state('content.publication', {
                url: '/publication/{id}',
                templateUrl: 'app/pages/content/publication/publication.html'
            })
            .state('content.my', {
                url: '/my',
                redirectTo: 'content.my.profile'
            })
            .state('content.my.profile', {
                url: '/profile',
                templateUrl: 'app/pages/content/my/profile/profile.html'
            })
            .state('content.my.research', {
                url: '/research',
                templateUrl: 'app/pages/content/my/research/research.html'
            })
            .state('content.my.reviews', {
                url: '/reviews',
                templateUrl: 'app/pages/content/my/reviews/reviews.html'
            })
            .state('content.my.messages', {
                url: '/messages?userId',
                templateUrl: 'app/pages/content/my/messages/messages.html',
                params: {
                    userId: {
                        value: undefined,
                        dynamic: true
                    }
                }
            })
            .state('content.my.notifications', {
                url: '/notifications',
                templateUrl: 'app/pages/content/my/notifications/notifications.html'
            });
    }
]);