app.factory("TimelineService", function(config,$window, $timeout,$http,Session,$q, Web3Service,$rootScope,$mdDialog,
                                       Toast,BackendService,UserCacheService,EntityCacheService) {

    var thiz = this;
    var user = Session.getSession().getUser();
    var dateFormat = "YYYY-MM-DD";
    var settings = {
        hitStep: 10,
        dateStep: 50
    };

    thiz.lastDate = moment().add(1, 'days');
    thiz.timeline = [];
    thiz.loading = true;

    thiz.loadMore = function (hitCount,dateCount) {
        if(hitCount === undefined){
            hitCount = 0;
        }
        if(dateCount === undefined){
            dateCount = 0;
        }
        if(dateCount >= settings.dateStep || hitCount >= settings.hitStep){
            thiz.loading = false;
            return;
        }
        thiz.lastDate = thiz.lastDate.subtract(1, 'days');
        thiz.loading = true;
        BackendService.doGetFollowingTimelineReq(user.id,thiz.lastDate.format(dateFormat), function (response) {
            hitCount += response.entities.length;
            for(var i = 0; i< response.entities.length; i++){
                var e = response.entities[i];
                e.user = UserCacheService.getUser(e.userId);
                e.entity = EntityCacheService.getEntity(e.entityId, e.entityType);
                thiz.timeline.push(e);
            }
            thiz.loadMore(hitCount,++dateCount);
        }, function (error) {
            console.log('timeline error: ', error);
            thiz.loading = false;
        });
    };

    thiz.reset = function () {
        thiz.lastDate = moment().add(1, 'days');
        thiz.timeline = [];
        thiz.loading = true;
        thiz.loadMore();
    };


    return thiz;
});
