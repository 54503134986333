/* global app, angular */

/**
 * Created by tkos on 8.1.2019
 */

app.directive("searchFilter", ['$timeout', '$state', '$mdDialog','$mdSidenav',
    '$window', 'config', 'ResearchItemModel', '$rootScope','BackendService','ResearchItemService','Toast','Session',
    'SearchFilterService',
    function ($timeout, $state, $mdDialog,$mdSidenav,
              $window, config, ResearchItemModel, $rootScope,BackendService,ResearchItemService,Toast,Session,
              SearchFilterService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/search/search-filter.directive.html',
            scope: {
                control: '='
            },
            link: function (scope, element, attrs) {

                scope.isSimple = scope.control.type === "simple";

                scope.ResearchItemService = ResearchItemService;

                scope.SearchFilterService = new SearchFilterService(scope);

                scope.$watch("control.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        if (oval.data.length !== nval.data.length) {
                            scope.SearchFilterService.update();
                        } else {
                            for (var i = 0; i < nval.data.length; i++) {
                                if (oval.data[i].toString() !== nval.data[i].toString()) {
                                    scope.SearchFilterService.update();
                                    return;
                                }
                            }
                        }
                    }
                }, true);




            }
        };
    }]);
