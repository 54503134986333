'use strict';

app.factory('ChatMessageModel', [
    function () {
        function ChatMessageModel(text, date, isRead, direction) {
            this.text = text;
            this.isRead = isRead;
            this.date = date;
            this.direction = direction;
            this.isHistory = false;
            this.archivedId = null;
        }

        return ChatMessageModel;
    }
]);