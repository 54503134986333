app.directive('documentdetails',
    [
        'config', '$timeout', '$stateParams', '$anchorScroll', '$location','BackendService','EntityCacheService',
        '$state',
        function (config, $timeout, $stateParams, $anchorScroll, $location,BackendService,EntityCacheService,
                  $state) {
            return {
                restrict: "E",
                replace: true,
                templateUrl: 'app/module/document-details/document-details.html',
                scope: {
                    control: "=",
                    item: "="
                },
                link: function (scope, element, attrs) {
                    scope.id = "document-details-" + UUID.generate();
                    scope.previewImage = null;
                    scope.imageId = "document-details-image" + UUID.generate();
                    scope.previewImageFullId = "document-details-image-full-div" + UUID.generate();
                    scope.timer = null;
                    scope.loading = true;
                    scope.generating = false;
                    scope.error = null;
                    scope.countGenerating = 0;
                    scope.showFullImage = false;
                    scope.references = {
                        loading: true,
                        error: undefined,
                        items: []
                    };

                    function selectDocument(item) {
                        scope.item = item;
                        scope.previewImage = null;

                        cancelTimer();

                        scope.loading = true;
                        scope.generating = false;
                        scope.error = null;

                        scope.$watch("item.data.url",function(nval){
                            if(nval !== undefined){
                                loadPreview(scope.item.data);
                            }
                        });

                        var divId = '#' + scope.id;
                        $(divId).animate({ scrollTop: 0 }, 'fast');

                        scope.$watch("item.data.references",function(nval){
                            if(nval !== undefined && nval !== null){
                                loadReferences(scope.item.data);
                                console.log(scope.item.data);
                            }
                        });

                        scrollToTop();
                    }

                    function loadPreview(document) {
                        scope.countGenerating++;
                        if (document.url !== null && document.url !== "") {
                            BackendService.doGetWebshotReq(document.id,document.url,
                                function (response) {
                                    $timeout(function () {
                                        if (response.status === Webshot.WebshotStatus.VALID) {
                                            scope.previewImage = config.connection.previewUrl + response.webUrl;
                                            scope.loading = false;
                                            scope.generating = false;
                                            scope.error = null;
                                        } else if (response.status === Webshot.WebshotStatus.PENDING
                                         && scope.countGenerating <= 10) {
                                            loadDocumentAfterTimer();
                                            scope.loading = false;
                                            scope.generating = true;
                                            scope.error = null;
                                        } else {
                                            scope.loading = false;
                                            scope.generating = false;
                                            scope.error = 'Unable to generate preview.';
                                        }
                                    });
                                },function (err) {
                                    console.error("webshot error",err);
                                }
                        );
                        }else{
                            scope.loading = false;
                        }
                    }

                    function loadDocuments(ids, type) {
                        var idsArray = [];
                        if (ids.constructor === Array) {
                            idsArray = ids;
                        } else {
                            idsArray.push(ids);
                        }

                        if(idsArray.length > 0){
                            scope.references.items = EntityCacheService.getEntities(idsArray);
                        }else{
                            scope.references.error = 'No references.';
                        }
                        scope.references.loading = false;
                    }

                    function cancelTimer() {
                        if (scope.timer !== null) {
                            clearTimeout(scope.timer);
                            scope.timer = null;
                        }
                    }

                    function loadDocumentAfterTimer() {
                        cancelTimer();

                        scope.timer = setTimeout(function () {
                            loadPreview(scope.item.data);
                        }, 2000);
                    }

                    function loadReferences(document) {
                        scope.references = {
                            loading: true,
                            error: undefined,
                            items: []
                        };

                        if (document == null || document.references == null || document.references.length == 0) {
                            scope.references.error = 'No references.';
                            scope.references.loading = false;
                        } else {
                            loadDocuments(document.references, document.type); // FIXME each reference can have different type
                        }
                    }

                    function resetItemsStack() {
                        scope.backItemsStack = [];
                        scope.forwardItemsStack = [];
                    }

                    function scrollToTop() {
                        $location.hash(scope.id + '-title');
                        $anchorScroll();
                    }

                    scope.onShowFullImage = function () {
                        scope.showFullImage = true;
                    };

                    scope.onHideFullImage = function () {
                        scope.showFullImage = false;
                    };
                    
                    scope.selectWord = function (keyword) {
                        var word = 'w:' + keyword;
                        scope.control.onWordClick(word);
                    };

                    scope.selectFos = function (keyword) {
                        var fos = 'f:' + keyword;
                        scope.control.onWordClick(fos);
                    };

                    scope.openMenu = function ($mdMenu, ev) {
                        $mdMenu.open(ev);
                    };

                    scope.onReferenceClick = function (item) {
                        console.log(item);
                        $state.go("content.publication",{id:item.id});
                    };

                    scope.selectDocument = selectDocument;

                    scope.selectDocument(scope.item);
                }
            };
        }
    ]);