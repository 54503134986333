/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("searchList", ['$timeout', '$state', '$mdDialog','$mdSidenav',
    '$window', 'config', 'ResearchItemModel', '$rootScope','BackendService','ResearchItemService','Toast','Session',
    'SearchListService',
    function ($timeout, $state, $mdDialog,$mdSidenav,
              $window, config, ResearchItemModel, $rootScope,BackendService,ResearchItemService,Toast,Session,
              SearchListService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/search/search-list.directive.html',
            scope: {
                control: '='
            },
            link: function (scope, element, attrs) {

                scope.isSimple = scope.control.type === "simple";

                scope.ResearchItemService = ResearchItemService;

                scope.SearchListService = new SearchListService(scope);


                var sortBy = [
                    {id: "year",
                        text: "Sort by year"},
                    {id: "relevance",
                        text: "Sort by relevance"}];
                scope.control.sortBy =
                        {selected: sortBy[1],
                            all: sortBy};

                scope.onOpenFilter = function(ev){
                    $mdSidenav("search-filters").toggle();
                };

                scope.$watch("control.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        if (oval.data.length !== nval.data.length) {
                            scope.SearchListService.update();
                        } else {
                            for (var i = 0; i < nval.data.length; i++) {
                                if (oval.data[i].toString() !== nval.data[i].toString()) {
                                    scope.SearchListService.update();
                                    return;
                                }
                            }
                        }
                    }
                }, true);

                scope.$watch("control.sortBy.selected", function (nval, oval) {
                    if(nval !== oval) {
                        scope.SearchListService.update();
                    }
                });


                var scroller = element.find(".infinite-list-inner");
                console.log(scroller);
                element.find(".headroom").headroom({
                    offset: 0,
                    tolerance: 1,
                    scroller: scroller[0]
                });

                scope.$on("$destroy", function () {
                    element.off("resize");
                    element.find(".headroom").headroom("destroy");
                    element.remove();
                });




            }
        };
    }]);
