'use strict';

app.config([
    '$urlRouterProvider', '$mdThemingProvider', '$mdAriaProvider',
    function ($urlRouterProvider, $mdThemingProvider, $mdAriaProvider) {
        $urlRouterProvider.otherwise('accounts');

        $mdThemingProvider.theme('default')
            .primaryPalette('grey')
            .accentPalette('light-blue');

        $mdThemingProvider.enableBrowserColor();
        $mdAriaProvider.disableWarnings();
    }
]);

app.run([
    '$transitions', '$state', '$q', 'Session','$cookies','config','$anchorScroll','$rootScope',
    function ($transitions, $state, $q, Session,$cookies,config,$anchorScroll,$rootScope) {
        console.log('[app] App started.');

        moment.updateLocale('en',config.moment);
        moment.locale('en');

        $transitions.onStart({}, function (transition) {
            console.log('[app] Transition to: ' + transition.to().name + ", from: " + transition.from().name);

            // user is not not visiting login page, check if it is logged in and in case if it is not, reject transition
            // and go to login page
            return checkLogin(transition);
        });

        function checkLogin(transition) {
            return $q(function (resolve, reject) {
                var isAutoLoginPage = transition.to().name === 'accounts.autologin';
                var isInvited = true;//$cookies.get("dave") === config.invite;
                var isInvitedPage = transition.to().name === 'accounts.invite';

                if(!isInvited){
                    if(isInvitedPage){
                        console.log('[app] invite: invite page.');
                        return resolve(true);
                    }
                    console.log('[app] invite: not invited yet.');
                    return resolve($state.target('accounts.invite'));
                } else if(isInvited && isInvitedPage) {
                    console.log('[app] invited: redirect from invite page.');
                    return resolve($state.target('accounts.login'));
                } else if(isAutoLoginPage) {
                    // user is visiting autologin page, allowed
                    console.log('[app] autologin: allowed');
                    return resolve(true);
                } else {
                    Session.isUserLoggedIn(function (isLoggedIn) {
                        console.log('[app] Session.isUserLoggedIn: ' + isLoggedIn + ' to=' + transition.to().name);
                        var isLoginPage = transition.to().name === 'accounts.login';
                        var isSignupPage = transition.to().name === 'accounts.signup';
                        var isContentPage = transition.to().name.startsWith('content');
                        if (isLoggedIn) {
                            if (isLoginPage || (isSignupPage && Session.getSession().getUser().flow > User.UserFlow.SIGNUP_REQUIRED)) {
                                // user is visiting login page and is already logged in or is visiting sign up page and is already signed up,
                                // redirect to content
                                console.log('[app] resolved+app');
                                resolve($state.target('content.dashboard'));
                            } else if(isContentPage && Session.getSession().getUser().flow === User.UserFlow.SIGNUP_REQUIRED){
                                console.log('[app] resolved+signup');
                                resolve($state.target('accounts.signup'));
                            } else {
                                // we can continue with transition, we return true
                                console.log('[app] resolved+true');
                                resolve(true);
                            }
                        } else {
                            if (Session.getSessionToken() !== null) { //has sessionToken
                                console.log('[app] resolved+autologin, redirectState=' + transition.to().name);
                                var r = {
                                    state: transition.to().name,
                                    params:  transition.params()
                                };
                                resolve($state.target('accounts.autologin',{redirect: JSON.stringify(r)}));
                            } else if (isLoginPage) {
                                console.log('[app] resolved+true');
                                // user is not logged in but he is visiting login page which is allowed
                                resolve(true);
                            } else {
                                // user is not logged in and visiting non-login page, redirect to login

                                // remember current state so we can redirect user after login
                                // TODO implement remember current state
                                // Session.setRedirectAfterLogin(transition.to().name);

                                console.log('[app] resolved+login, redirectState=' + transition.to().name);
                                resolve($state.target('accounts.login'));
                            }
                        }
                        resolve(true);
                    });
                }
            });
        }
    }
]);