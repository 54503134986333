'use strict';

app.service('I8XChat', [
    'I8XLogger', '$rootScope',
    function (I8XLogger, $rootScope) {
        var logger = I8XLogger.loggerFor('Chat.I8XChat');
        var service = this;
        var stropheStatus = Strophe.Status.DISCONNECTED;
        var stropheInstance = null;
        var jid = null;
        var onMessageCallback = null;
        var onContactsLoadedCallback = null;
        var onUserPresenceChangedCallback = null;
        var rsmQueryId = 0;

        service.connect = function (host, username, password) {
            if (service.isConnected()) {
                stropheInstance.disconnect();
                stropheInstance = null;
            }

            stropheInstance = new Strophe.Connection(host);

            logger.info('Connecting user=' + username + ', pass=' + password);
            stropheInstance.connect(username, password, onConnect);
        };

        service.isConnected = function () {
            return stropheStatus === Strophe.Status.CONNECTED;
        };

        service.sendMessage = function (from, to, message) {
            logger.debug('Sending message from=' + from + ', to=' + to + ', msg=' + message);
            var reply = $msg({to: to, from: from, type: 'chat'})
                .c('body').t(message);

            stropheInstance.send(reply.tree());
        };

        service.getChatId = function () {
            return stropheInstance.jid;
        };

        service.onMessage = function (callback) {
            onMessageCallback = callback;
        };

        service.getChatHistory = function (userId, cb) {
            logger.info('Retrieving history messages for: ' + userId);

            loadLastMessages(userId, 30, cb);
        };

        service.getChatLastMessage = function (userId, cb) {
            logger.info('Retrieving last message for: ' + userId);

            loadLastMessages(userId, 1, cb);
        };

        service.setOnContactsLoadedHandler = function (callback) {
            onContactsLoadedCallback = callback;
        };

        service.setOnUserPresenceChangedHandler = function (callback) {
            onUserPresenceChangedCallback = callback;
        };

        service.addUserToContacts = function (jid) {
            logger.info('Adding', jid, 'to roster');
            stropheInstance.roster.add(jid, jid, []);

            service.subscribe(jid);
        };

        service.removeUserFromContacts = function (jid) {
            service.unsubscribe(jid);

            logger.info('Removing', jid, 'from roster');
            stropheInstance.roster.remove(jid);
        };

        service.subscribe = function (jid) {
            logger.debug('Subscribing to', jid);
            stropheInstance.roster.subscribe(jid);
        };

        service.unsubscribe = function (jid) {
            logger.debug('Unsubscribing from', jid);
            stropheInstance.roster.unsubscribe(jid);
        };

        service.authorize = function (jid) {
            logger.debug('Authorizing subscription', jid);
            stropheInstance.roster.authorize(jid);
        };

        service.unauthorize = function (jid) {
            logger.debug('Unauthorizing subscription', jid);
            stropheInstance.roster.unauthorize(jid);
        };

        function loadLastMessages(userId, count, cb) {
            var historyMessages = [];
            var allMsgsReceived = false;

            logger.debug('Retrieving history messages for: ' + userId);

            var queryId = nextRsmQueryId(userId);

            stropheInstance.mam.query(service.getChatId(), {
                max: count,
                before: '', // both, max and before are needed, see https://stackoverflow.com/questions/32123467/most-recent-messages-with-strophe-mam
                with: userId,
                onMessage: function (msg) {
                    var qId = $(msg).find("message result").attr("queryid") || $(msg).find("message fin").attr("queryid");
                    if(qId !== queryId) {
                        // message not for our handler
                        return true;
                    }

                    var count = $(msg).find("fin set count").text();
                    if (count) {
                        allMsgsReceived = true; // we got final tag with count
                    } else {
                        var message = {
                            id: null,
                            from: Strophe.getBareJidFromJid($(msg).find("forwarded message").attr("from")),
                            to: Strophe.getBareJidFromJid($(msg).find("forwarded message").attr("to")),
                            text: $(msg).find("forwarded message body").text(),
                            date: new Date(Date.parse($(msg).find("forwarded delay").attr("stamp"))),
                            archivedId: $(msg).find("message archived").attr("id")
                        };

                        logger.debug('Retrieved history message (' + userId + '): from=' + message.from + ', to=' + message.to, message.text);

                        historyMessages.push(message);
                    }

                    if (allMsgsReceived) {
                        // we are done
                        logger.debug('History loading done.');
                        invokeCb(cb, [historyMessages]);
                    }

                    return true;
                },
                queryid: queryId // used to identify responses
            });
        }

        function onConnect(status) {
            if (status === Strophe.Status.CONNECTING) {
                logger.info('Connecting to chat server...');
            } else if (status === Strophe.Status.CONNFAIL) {
                logger.error('Connection to chat server failed.');
            } else if (status === Strophe.Status.DISCONNECTING) {
                logger.info('Disconnecting from chat server...');
            } else if (status === Strophe.Status.DISCONNECTED) {
                logger.info('Disconnected.');
            } else if (status === Strophe.Status.CONNECTED) {
                logger.info('Connected, jid=' + stropheInstance.jid);
                onConnected();
            }

            stropheStatus = status;
        }

        function onConnected() {
            stropheInstance.roster.init(stropheInstance);
            stropheInstance.roster.get(onRostersReceived);
            stropheInstance.roster.registerCallback(onRosterChanged);

            stropheInstance.mam.init(stropheInstance);

            // stropheInstance.addHandler(onStanzaReceived);
            stropheInstance.addHandler(onMessageChatStanza, null, 'message', 'chat');
            stropheInstance.addHandler(onPresenceStanza, null, 'presence');
        }

        function onMessageChatStanza(message) {
            logger.debug('Message received', message);
            var to = $(message).attr('to');
            var from = $(message).attr('from');

            // we have body in message
            var body = $(message).children('body').text();

            if (onMessageCallback !== null && body) {
                invokeCb(onMessageCallback, [{
                    id: from,
                    from: Strophe.getBareJidFromJid(from),
                    to: to,
                    text: body,
                    date: new Date(),
                    archivedId: $(message).find("message archived").attr("id")
                }]);
            }

            return true;
        }

        function onPresenceStanza(presence) {
            logger.debug('Presence stanza received', xmlToString(presence));

            var ptype = $(presence).attr('type');
            var from = $(presence).attr('from');

            if (ptype === 'subscribe') {
                logger.debug('Auto subscribing', from);

                service.authorize(from);
            } else if (ptype === 'unavailable') {
                // user went offline
                logger.debug('User went offline', from);
                invokeCb(onUserPresenceChangedCallback, [Strophe.getBareJidFromJid(from), false]);
            } else {
                // user is online
                logger.debug('User is online', from);
                var me = Strophe.getBareJidFromJid(from);
                if (Strophe.getBareJidFromJid(from) !== Strophe.getBareJidFromJid(service.getChatId())) {
                    invokeCb(onUserPresenceChangedCallback, [Strophe.getBareJidFromJid(from), true]);
                }
            }

            return true;
        }

        function onStanzaReceived(msg) {
            logger.info('Received: ' + xmlToString(msg));

            // we must return true to keep the handler alive.
            // returning false would remove it after it finishes.
            return true;
        }

        function onRosterChanged(roster) {
            logger.info('onRosterChanged', roster);
            var sub = $(roster).attr('subscription');
            var jid = $(roster).attr('jid');
            var name = $(roster).attr('name') || jid;

            if (sub === 'remove') {

            }

            return true;
        }

        function onRostersReceived(rosters) {
            var contacts = [];

            _.each(rosters, function (roster) {
                contacts.push({
                    userId: roster.jid,
                    isOnline: false // we don't know yet if user is online or not
                });
            });

            logger.debug('Roster contacts received', contacts);

            invokeCb(onContactsLoadedCallback, [contacts]);

            logger.info('Going online.');
            stropheInstance.send($pres());

            return true;
        }

        function xmlToString(xmlData) {
            var xmlString;
            //IE
            if (window.ActiveXObject) {
                xmlString = xmlData.xml;
            }
            // code for Mozilla, Firefox, Opera, etc.
            else {
                xmlString = (new XMLSerializer()).serializeToString(xmlData);
            }
            return xmlString;
        }

        /**
         * Invokes specified Callback using specified parameters within an Angular scope.
         *
         * @param cb The callback
         * @param parameters The parameters
         */
        function invokeCb(cb, parameters) {
            if (cb && angular.isFunction(cb)) {
                $rootScope.$apply(function () {
                    // executes callback within angular scope
                    cb.apply(cb, parameters);
                });
            }
        }

        function nextRsmQueryId(jid) {
            return jid + '-' + ++rsmQueryId;
        }
    }
]);