
app.controller('commentUpdateCtrl', ['$scope', 'Session','currentComment','Toast','$mdDialog','BackendService',
    function ($scope, Session, currentComment,Toast,$mdDialog,BackendService) {

        var user = Session.getSession().getUser();
        $scope.form = {};

        $scope.isAdd = currentComment.entityId === null || currentComment.entityId === undefined;
        $scope.title = $scope.isAdd ? "Add comment" : "Edit comment";

        console.log(currentComment);
        if ($scope.isAdd) {
            $scope.comment = {
                commentId: null,
                userId: user.id,
                entityId: currentComment.entityId,
                entityType: currentComment.entityType,
                text: ""
            };
        } else {
            $scope.comment = {
                commentId: currentComment.data.commentId,
                userId: currentComment.data.userId,
                entityId: currentComment.data.entityId,
                entityType: currentComment.data.entityType,
                text: currentComment.data.text
            };
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            console.log($scope.comment);
            if ($scope.form.comment.$valid) {
                BackendService.doUpdateCommentReq($scope.comment,
                    function (response) {
                        console.log("Success in updateComment", response);
                        if($scope.isAdd){
                            Toast.success("Comment was successfully added.");
                        }else{
                            Toast.success("Comment was successfully updated.");
                        }
                        response.text = $scope.comment.text;
                        $mdDialog.hide(response);
                    }, function (error) {
                        console.log("Error updateComment", error);
                    });
            }
        };
    }]);
