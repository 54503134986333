'use strict';

app.factory('loginProviderOrcid', [
    '$rootScope', 'config', 'BackendService', '$interpolate',
    function ($rootScope, config, BackendService, $interpolate) {

        function doInit() {
            // nothing to do for now
        }

        function doLogout() {
            // nothing to do for now
        }

        function doLogin() {
            var context = {
                clientId: config.signin.providers.orcid.client_id,
                redirectUri: config.connection.root + config.signin.providers.orcid.redirectUrl
            };

            var exp = $interpolate(config.signin.providers.orcid.loginRedirect);
            var redirectUrl = exp(context);

            // do a redirect to Facebook
            window.location.href = redirectUrl;
        }

        function doLoginToBackend(token, email, responseCallback, errorCallback) {
            BackendService.doLoginWithOrcid(token, responseCallback, errorCallback);
        }


        return {
            init: doInit,
            logout: doLogout,
            login: doLogin,
            loginToBackend: doLoginToBackend
        };
    }
]);