/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("tweets", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', '$rootScope','BackendService','TimelineService','Toast',
    'Session',
    function ($timeout, $state, $mdDialog, $window, config, $rootScope,BackendService,TimelineService,Toast,
              Session) {
        var URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        var HASHTAG_REGEX = /(^|\s)#(\w+)/g;
        var MENTION_REGEX = /(^|\s)@(\w+)/g;

        var tweetTextToHtml = function (text) {
            return text.replace(URL_REGEX,'<a href="$&" target="_blank">$&</a>')
                .replace(MENTION_REGEX,'<a href="https://twitter.com/$2" target="_blank"> @$2</a>')
                .replace(HASHTAG_REGEX,'<a href="https://twitter.com/hashtag/$2" target="_blank"> #$2</a>');
        };

        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/tweets/tweets.directive.html',
            scope: {},
            link: function ($scope, element, attrs) {

                $scope.tweets = [];
                var dto = {
                    indexName: "tweets",
                    start: 0,
                    size: 10,
                    type: ["twitter"],
                    search: []
                };
                console.log("loading tweets");
                BackendService.doSearchEs(dto,function (response) {
                    $scope.tweets = response.tweets;

                    $scope.tweets.forEach(function (item) {
                        item.tweetTextFormatted = tweetTextToHtml(item.tweetText);
                    })
                },function (error) {
                    console.log("tweets error",error);
                })
            }
        };
    }]);
