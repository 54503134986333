'use strict';

app.directive('emoji', [
    function () {
        return {
            restrict: "E",
            replace: true,
            scope: {
                text: '='
            },
            link: function (scope, element, attrs) {
                emojione.ascii = true;
                element.html(emojione.shortnameToImage(scope.text));
            }
        };
    }]);
