
/* global angular, app */

app.factory('KeywordModel', [
    function () {

        var nGramDelimiter = '-';
        var yearRegex = /^(0|[1-9]\d{0,3})$/;
        var dayRegex = /^([1-9]\d{0,5})d$/;
        var hourRegex = /^([1-9]\d{0,5})h$/;
        var dateRegex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
        var shortdateRegex = /^([12]\d{3}-(0[1-9]|1[0-2]))$/;
        var dateAddTime = [" 00:00:00"," 23:59:59"];
        var shortDateAddTime = ["-01 00:00:00","-31 23:59:59"];
        var yearAddDate = ["-01-01","-12-31"];
        var shortDateAddDate = ["-01","-31"];

        var types = [
            {id: 0, short: "m", text: "filter", search: "must"},
            {id: 1, short: "s", text: "rank", search: "should"},
            {id: 2, short: "n", text: "exclude", search: "mustNot"}
        ];

        var sources = [
            {id: 0, short: "w", text: "Word", icon: undefined, switchType:true, range:false, showIn:["search","news","social"]},
            {id: 1, short: "a", text: "Author", icon: 'person_outline', switchType:true, range:false, showIn:["search"]},
            {id: 2, short: "o", text: "Organization", icon: 'domain', switchType:true, range:false, showIn:["search"]},
            {id: 3, short: "s", text: "Assignee", icon: 'people_outline', switchType:true, range:false, showIn:["search"]},
            {id: 4, short: "f", text: "Field of science", icon: 'pie_chart_outline', switchType:true, range:false, showIn:["search"]},
            {id: 5, short: "y", text: "Year", icon: 'history', switchType:false, range:true, showIn:["search","news"]},
            {id: 8, short: "h", text: "Help", switchType:false, range:false, showIn:[]}
        ];
        var typesModule = 3;
        /**
         * Create new User.
         * @param object represents response data from logedin rest
         * @constructor
         */

        function KeywordModel(string) {
            this.word = "";
            this.type = types[0];
            this.source = sources[0];
            this.soureProvidedInWord = false;

            if (angular.isString(string)) {
                var withSource = false;
                if(string.length >= 2 && string.charAt(1) === ':') {
                    withSource = this.setSource(string.charAt(0).toLowerCase());
                    this.soureProvidedInWord = withSource;
                }

                var i = string.lastIndexOf("_");
                var withType = false;
                if (i !== -1) {
                    withType = this.setType(string.substring(i + 1, string.length));
                }
                if(withType && withSource) {
                    this.word = string.substring(2, i).toLowerCase().trim();
                } else if (withType) {
                    this.word = string.substring(0, i).toLowerCase().trim();
                } else if (withSource) {
                    this.word = string.substring(2).toLowerCase().trim();
                } else {
                    this.word = string.trim();
                }

                if(withSource && this.source.short === 'w') {
                    // in case if source was defined and it is word, we would like to replace spaces with '-' to have
                    // bigram, trigram, ...

                    // replace all spaces with delimiter
                    this.word = this.word.trim().replace(/ /g, nGramDelimiter);

                    // in case if there are multiple delimiters together keep just one
                    this.word = this.word.replace(/-+/g, nGramDelimiter);
                }
                if(this.source.range===true){
                    this.type = types[0]; // for years, date, time is always must
                    this.word = this.word.replace(" ","");
                    if(this.source.short === "y") {
                        var arr = this.word.split(":");
                        if (arr.length > 2) {
                            this.word = ""; return;
                        }
                        for (var i in arr) {
                            if (!yearRegex.test(arr[i])) {
                                this.word = ""; return;
                            }
                        }
                        if (arr.length === 1) {
                            this.dto = {type: "year", gte: "" + arr[0], lte: "" + arr[0]};
                        } else {
                            this.dto = {type: "year", gte: "" + arr[0], lte: "" + arr[1]};
                        }
                    }else if(this.source.short === 'd'){
                        var arr = this.word.split(":");
                        if (arr.length > 2) {
                            this.word = ""; return;
                        }
                        if(arr.length === 1){
                            if (yearRegex.test(arr[0])) {
                                this.dto = {type: "date", gte: arr[0]+yearAddDate[0], lte: arr[0]+yearAddDate[1]};
                                return;
                            } else if (shortdateRegex.test(arr[0])) {
                                this.dto = {type: "date", gte: arr[0]+shortDateAddDate[0], lte: arr[0]+shortDateAddDate[1]};
                                return;
                            } else if (dateRegex.test(arr[0])) {
                                this.dto = {type: "date", gte: arr[0], lte: arr[0]};
                                return;
                            } else {
                                this.word = ""; return;
                            }
                        }else {
                            for (var i in arr) {
                                if (yearRegex.test(arr[i])) {
                                    arr[i] = arr[i] + yearAddDate[i];
                                } else if (shortdateRegex.test(arr[i])) {
                                    arr[i] = arr[i] + shortDateAddDate[i];
                                } else if (dateRegex.test(arr[i])) {
                                    arr[i] = arr[i];
                                } else {
                                    this.word = "";
                                    return;
                                }
                            }
                            this.dto = {type: "date", gte: "" + arr[0], lte: "" + arr[1]};
                        }
                    }else if(this.source.short === 't'){
                        var arr = this.word.split(":");
                        if (arr.length > 2) {
                            this.word = ""; return;
                        }
                        if(arr.length === 1){
                            if (hourRegex.test(arr[0])) {
                                this.dto = {type: "createdTime", gte: "now-"+arr[0]+"/h", lte: "now"};
                                return;
                            } else if (dayRegex.test(arr[0])) {
                                this.dto = {type: "createdTime", gte: "now-"+arr[0]+"/d", lte: "now"};
                                return;
                            } else if (dateRegex.test(arr[0])) {
                                this.dto = {type: "createdTime", gte: arr[0]+dateAddTime[0], lte: arr[0]+dateAddTime[1]};
                                return;
                            } else if (shortdateRegex.test(arr[0])) {
                                this.dto = {type: "createdTime", gte: arr[0]+shortDateAddTime[0], lte: arr[0]+shortDateAddTime[1]};
                                return;
                            } else {
                                this.word = ""; return;
                            }
                        }else {
                            for (var i in arr) {
                                if (dateRegex.test(arr[i])) {
                                    arr[i] = arr[i]+dateAddTime[i];
                                } else if (shortdateRegex.test(arr[i])) {
                                    arr[i] = arr[i]+shortDateAddTime[i];
                                } else {
                                    this.word = ""; return;
                                }
                            }
                            this.dto = {type: "createdTime", gte: arr[0], lte: arr[1]};
                        }
                    }else{
                        this.word = "";
                    }
                }
            }
        }


        KeywordModel.getSources = function (type) {
            if(type){
                return _.filter(sources,function (item) {
                    return _.contains(item.showIn,type);
                })
            }
            return sources;
        };

        KeywordModel.getTypes = function (type) {
            return types;
        };

        KeywordModel.prototype.toString = function () {
            return this.source.short + ":" + this.word + "_" + this.type.short;
        };

        KeywordModel.prototype.nextType = function (reverse) {
            if(this.source.range === true){
              return this.type;
            }
            var add = reverse === true ? -1 : 1;
            var newId = (this.type.id + add + typesModule) % typesModule;
            this.type = _.find(types, function (type) {
                return type.id === newId;
            });

            return this.type;
        };

        KeywordModel.prototype.setType = function (type_short) {
            if(this.source.range === true) return true;
            var type = _.find(types, function (type) {
                return type.short === type_short;
            });
            if (angular.isDefined(type)) {
                this.type = type;
                return true;
            }
            return false;
        };

        KeywordModel.prototype.setSource = function (source_short) {
            var source = _.find(sources, function (source) {
                return source.short === source_short;
            });
            if (angular.isDefined(source)) {
                this.source = source;
                return true;
            }
            return false;
        };

        KeywordModel.prototype.isValid = function () {
            return this.word !== "";
        };

        KeywordModel.prototype.clone = function () {
            var cloned = angular.copy(this);

            // Remove as it's angular specific.
            delete cloned.$$hashKey;

            return cloned;
        };

        // Return constructor
        return KeywordModel;
    }
]);