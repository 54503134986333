/* global app, angular */

app.directive("notificationItem", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', '$rootScope','BackendService','NotificationService','Toast',
    'Session',
    function ($timeout, $state, $mdDialog, $window, config, $rootScope,BackendService,NotificationService,Toast,
              Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: function (elem, attrs) {
                return attrs["type"] === "menu-item" ?
                    'app/module/notification/notification-menu-item.directive.html':
                    'app/module/notification/notification-item.directive.html';
            },
            scope: {
                item: "=item"
            },
            link: function (scope, element, attrs) {
                scope.NotificationService = NotificationService;


                scope.notificationTypes = {};

                scope.notificationTypes[User.NotificationType.ENTITY_LIKE] = "liked entity.";
                scope.notificationTypes[User.NotificationType.ENTITY_DISLIKE] = "disliked entity.";
                scope.notificationTypes[User.NotificationType.ENTITY_COMMENT] = "commented entity.";
                scope.notificationTypes[User.NotificationType.REQ_FOR_REVIEW] = "requested for review.";
                scope.notificationTypes[User.NotificationType.CHALLANGE_COMPLETED] = "completed challenge.";
                scope.notificationTypes[User.NotificationType.INVITATION_TO_CHALLANGE] = "invited to challenge.";
                scope.notificationTypes[User.NotificationType.INVITATION_TO_CLAIM] = "invited to claim.";

                scope.onInitiateChat = function (userId) {
                    $rootScope.$broadcast('ChatService.initiateChat', {
                        userId: userId
                    });
                };

            }
        };
    }]);
