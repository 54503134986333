
app.controller('researchItemUpdateCtrl', ['$scope', 'Session','Toast','$mdDialog','BackendService','$q','$mdConstant',
    'currentResearch','$filter','$rootScope',
    function ($scope, Session,Toast,$mdDialog,BackendService,$q,$mdConstant,
              currentResearch,$filter,$rootScope) {
        $scope.form = {};

        $scope.title = "Add research";
        $scope.isAdd = currentResearch === null;
        $scope.user = angular.copy(Session.getSession().getUser());

        if ($scope.isAdd) {
            $scope.research = {
                articleId: null,
                title: "",
                summary: "",
                authors: [],
                fos: [],
                keywords: [],
                sources: [],
                editStatus: Publish.EditStatus.PENDING
            };
        } else {
            $scope.research = angular.copy(currentResearch);
            $scope.research.authors = _.filter($scope.research.authors, function(item){
                return item.userId !== $scope.user.id;
            });
            if($scope.research.fos === null){
                $scope.research.fos = [];
            }
            if($scope.research.keywords === null){
                $scope.research.keywords = [];
            }
            if($scope.research.sources === null){
                $scope.research.sources = [];
            }
        }
        console.log($scope.research);

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.research.$valid) {
                var a = angular.copy($scope.research);
                a.authors.unshift({userId: $scope.user.id});
                BackendService.doUpdateArticle(a, function (response) {
                    console.log("Update article success!",response);
                    if($scope.isAdd){
                        Toast.success("Research was successfully added.");
                    }else{
                        Toast.success("Research was successfully updated.");
                    }
                    a.articleId = response.articleId;
                    a.updatedTime = response.updatedTime;
                    a.editStatus = response.editStatus;
                    a.publishStatus = response.publishStatus;
                    $rootScope.$broadcast("research-refresh",{type:"update"});
                    $mdDialog.hide(a);
                }, function (error) {
                    console.log('Update article error: ', error);
                });
            }
        };


        $scope.users = {
            selectedItem: undefined,
            searchText: undefined,
            deferred: undefined,
            transformChip: function ($chip) {
                console.log($chip);
                return {
                    userId: $chip.userId,
                    user: {user: $chip}
                };
            },
            all:null,
            getSearch: function () {
                if(this.all === null) {
                    this.deferred = $q.defer();
                    var d = this.deferred;
                    var thiz = this;
                    BackendService.doGetUserReq(100,null, function (response, message) {
                        thiz.all = response.users;
                        _.forEach(thiz.all,function (item) {
                            item.fullName = item.firstName + " " + item.lastName;
                        });
                        d.resolve(thiz.getSearch());
                    }, function (error) {
                        console.log('Get users error: ', error);
                        thiz.all = [];
                        d.resolve(thiz.all);
                    });
                    return this.deferred.promise;
                }else{
                    var s = this.searchText.toLowerCase().split(" ");
                    console.log(s);
                    console.log(this.all);
                    return $filter('filter')(this.all, {fullName: this.searchText}, false);
                }
            }
        };

        $scope.fos = {
            selectedItem: undefined,
            searchText: undefined,
            deferred: undefined,
            getSearch: function () {
                this.deferred = $q.defer();
                var dto = {
                    value: angular.isDefined(this.searchText) ? this.searchText.toLowerCase() : undefined,
                    top: 10,
                    type: Search.SearchDataType.FOS
                };
                var d = this.deferred;
                BackendService.doSearchVocabularyReq(dto, function (response, message) {
                    console.log(response);
                    if(_.isObject(response) && _.isArray(response.keywords)){
                        var results = response.keywords;
                        d.resolve(_.pluck(results,'name'));
                    }
                    d.resolve([]);
                }, function (error) {
                    console.log('FOS Vocabulary error: ', error);
                    d.resolve([]);
                });
                return this.deferred.promise;

            }

        };

        $scope.keywords = {
            selectedItem: undefined,
            searchText: undefined,
            deferred: undefined,
            keys: [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA, $mdConstant.KEY_CODE.SPACE],
            transformChip: function ($chip) {
                return $chip.toLowerCase();
            },
            getSearch: function () {
                this.deferred = $q.defer();
                var dto = {
                    value: angular.isDefined(this.searchText) ? this.searchText.toLowerCase() : undefined,
                    top: 10,
                    type: Search.SearchDataType.KEYWORD
                };
                var d = this.deferred;
                BackendService.doSearchVocabularyReq(dto, function (response, message) {
                    console.log(response);
                    if(_.isObject(response) && _.isArray(response.keywords)){
                        var results = response.keywords;
                        d.resolve(_.pluck(results,'name'));
                    }
                    d.resolve([]);
                }, function (error) {
                    console.log('Keyword Vocabulary error: ', error);
                    d.resolve([]);
                });
                return this.deferred.promise;

            }

        };


    }]);
