/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("timelineItem", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', '$rootScope','BackendService','TimelineService','Toast',
    'Session',
    function ($timeout, $state, $mdDialog, $window, config, $rootScope,BackendService,TimelineService,Toast,
              Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/timeline/timeline-item.directive.html',
            scope: {
                item: "=item"
            },
            link: function (scope, element, attrs) {
                scope.TimelineService = TimelineService;


                scope.actionTypes = {};

                scope.actionTypes[User.ActionType.ADD_REVIEW] = "added review.";
                scope.actionTypes[User.ActionType.UPDATE_REVIEW] = "updated review.";
                scope.actionTypes[User.ActionType.COMPLETE_REVIEW] = "completed review.";
                scope.actionTypes[User.ActionType.ADD_COMMENT] = "added comment.";
                scope.actionTypes[User.ActionType.UPDATE_COMMENT] = "updated comment.";
                scope.actionTypes[User.ActionType.COMPLETE_COMMENT] = "completed comment.";
                scope.actionTypes[User.ActionType.ADD_ARTICLE] = "added article.";
                scope.actionTypes[User.ActionType.UPDATE_ARTICLE] = "updated article.";
                scope.actionTypes[User.ActionType.COMPLETE_ARTICLE] = "completed article.";
                scope.actionTypes[User.ActionType.CLAIM_ARTICLE] = "claimed article.";
                scope.actionTypes[User.ActionType.ENTITY_LIKE] = "liked entity.";
                scope.actionTypes[User.ActionType.ENTITY_DISLIKE] = "disliked entity.";

                scope.onInitiateChat = function (userId) {
                    $rootScope.$broadcast('ChatService.initiateChat', {
                        userId: userId
                    });
                };

            }
        };
    }]);
