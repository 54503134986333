
app.controller('profileUpdateCtrl', ['$scope', 'Session','Toast','$mdDialog','BackendService','$q','$mdConstant',
    function ($scope, Session,Toast,$mdDialog,BackendService,$q,$mdConstant) {

        $scope.user = angular.copy(Session.getSession().getUser());
        $scope.form = {};

        $scope.title = "Update profile";

        if($scope.user.fos === null){
            $scope.user.fos = [];
        }
        if($scope.user.keywords === null){
            $scope.user.keywords = [];
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.user.$valid) {
                console.log($scope.user);
                $scope.user.userId = $scope.user.id;
                BackendService.doUpdateUser($scope.user, function (response) {
                    console.log("update user success!",response);
                    Toast.success("Profile was successfully updated.");
                    var user = Session.getSession().getUser().update($scope.user);
                    $mdDialog.hide(user);
                }, function (error) {
                    console.log('Update user error: ', error);
                });
            }
        };

        $scope.userProfiles = [
            {
                id: User.UserProfile.RESEARCHER,
                text: 'Researcher'
            }, {
                id: User.UserProfile.PUBLISHER,
                text: 'Publisher'
            }, {
                id: User.UserProfile.JOURNALIST,
                text: 'Journalist'
            }, {
                id: User.UserProfile.MARKETING,
                text: 'Marketing'
            }
        ];

        $scope.fos = {
            selectedItem: undefined,
            searchText: undefined,
            deferred: undefined,
            getSearch: function () {
                this.deferred = $q.defer();
                var dto = {
                    value: angular.isDefined(this.searchText) ? this.searchText.toLowerCase() : undefined,
                    top: 10,
                    type: Search.SearchDataType.FOS
                };
                var d = this.deferred;
                BackendService.doSearchVocabularyReq(dto, function (response, message) {
                    console.log(response);
                    if(_.isObject(response) && _.isArray(response.keywords)){
                        var results = response.keywords;
                        d.resolve(_.pluck(results,'name'));
                    }
                    d.resolve([]);
                }, function (error) {
                    console.log('FOS Vocabulary error: ', error);
                    d.resolve([]);
                });
                return this.deferred.promise;

            }

        };

        $scope.keywords = {
            selectedItem: undefined,
            searchText: undefined,
            deferred: undefined,
            keys: [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA, $mdConstant.KEY_CODE.SPACE],
            transformChip: function ($chip) {
                return $chip.toLowerCase();
            },
            getSearch: function () {
                this.deferred = $q.defer();
                var dto = {
                    value: angular.isDefined(this.searchText) ? this.searchText.toLowerCase() : undefined,
                    top: 10,
                    type: Search.SearchDataType.KEYWORD
                };
                var d = this.deferred;
                BackendService.doSearchVocabularyReq(dto, function (response, message) {
                    console.log(response);
                    if(_.isObject(response) && _.isArray(response.keywords)){
                        var results = response.keywords;
                        d.resolve(_.pluck(results,'name'));
                    }
                    d.resolve([]);
                }, function (error) {
                    console.log('Keyword Vocabulary error: ', error);
                    d.resolve([]);
                });
                return this.deferred.promise;

            }

        };


    }]);
