'use strict';

(function(module) {
    try {
        angular.module('partials');
    } catch (e) {
        angular.module('partials', []);
    }
})();

var app = angular.module('infinitcodex', [
    'ngCookies',
    'ngMessages',
    'ngSanitize',
    'ui.router',
    'ngMaterial',
    'oc.lazyLoad',
    'partials'
]);