app.factory("ResearchItemService", function(config,$window, $timeout,$http,Session,$q, Web3Service,$rootScope,$mdDialog,
                                       Toast,BackendService) {

    var thiz = this;
    var user = Session.getSession().getUser();

    thiz.onShowDetails = function (item,ev) {
        $mdDialog.show({
            controller: 'researchItemDetailsCtrl',
            templateUrl: 'app/module/research-item/research-item-details.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                currentArticle: angular.copy(item)
            }
        }).then(function (answer) {
            console.log(answer);
        });
    };

    thiz.onClaim = function (item,init,ev) {
        $mdDialog.show({
            controller: 'researchItemClaimCtrl',
            templateUrl: 'app/module/research-item/research-item-claim.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            locals: {
                currentArticle: angular.copy(item)
            }
        }).then(function (answer) {
            init();//TODO update just this research item
        });
    };

    thiz.onBcData = function (item,$event) {
        Web3Service.doGetArticleData(item.id).then(function(response){
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title('Research data on blockchain.')
                    .htmlContent('<div>Hash: '+response.hash+'</div>'+
                        '<div>Owners: '+response.owners.join(", ")+'</div>'+
                        '<div>Status: '+thiz.getStatusWithNumberText(response.status)+'</div>'+
                        '<div>DOI: '+response.doi+'</div>')
                    .ariaLabel('Article on blockchain')
                    .ok('Got it')
                    .targetEvent($event)
            );
        },function (error) {
            console.log("Error in getArticleBcData",error);
        });
    };



    thiz.getStatusText = function (status) {
        switch (status){
            case Publish.PublishStatus.PENDING: return "Pending";
            case Publish.PublishStatus.BC_COMPLETED: return "Completed";
            case Publish.PublishStatus.BC_CANCELED: return "Canceled";
            case Publish.PublishStatus.BC_RETRACTED: return "Retracted";
        }
        return "";
    };

    thiz.getStatusWithNumberText = function (status) {
        switch (status){
            case Publish.PublishStatus.PENDING: return "1 - Pending";
            case Publish.PublishStatus.BC_COMPLETED: return "4 - Completed";
            case Publish.PublishStatus.BC_CANCELED: return "5 - Canceled";
            case Publish.PublishStatus.BC_RETRACTED: return "6 - Retracted";
        }
        return "";
    };

    thiz.onReview = function (item,ev) {
        $mdDialog.show({
            controller: 'researchItemReviewsCtrl',
            templateUrl: 'app/module/research-item/research-item-reviews.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                currentArticle: angular.copy(item)
            }
        }).then(function (answer) {
            console.log(answer);
        });
    };

    thiz.onOwners = function (item,ev) {
        $mdDialog.show({
            controller: 'researchItemOwnersCtrl',
            templateUrl: 'app/module/research-item/research-item-owners.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                currentArticle: angular.copy(item)
            }
        }).then(function (answer) {
            console.log(answer);
        });
    };

    thiz.canClaim = function(item){
        if(item===undefined){
            return false;
        }
        return (item.claimedAuthorIds === null || item.claimedAuthorIds.length===0);
    };

    thiz.canCite = function(item){
        if(item===undefined){
            return false;
        }
        return Web3Service.isStatusDone() && item.claimedAuthorIds !== null && item.claimedAuthorIds.length>0
            && !_.contains(item.claimedAuthorIds,user.id);
    };

    thiz.onUpdate = function (item,ev) {
        return $mdDialog.show({
            controller: 'researchItemUpdateCtrl',
            templateUrl: 'app/module/research-item/research-item-update.dialog.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            locals: {
                currentResearch: item? angular.copy(item.extra.article): null
            }
        }).then(function (answer) {
            console.log(answer);
        });
    };

    thiz.onAdd = function (ev) {
        return thiz.onUpdate(undefined,ev);
    };

    thiz.canUpdate = function(item){
        if(item && item.extra && item.extra.article && item.extra.article.editStatus !== null){
            return item.extra.article.editStatus < Publish.EditStatus.COMPLETED;
        }
        return false;
    };

    thiz.onDelete = function (item,ev) {
        console.log(item);
        var confirm = $mdDialog.confirm()
            .title('Are you sure you want to delete this research?')
            .textContent('')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

        return $mdDialog.show(confirm).then(function() {
            BackendService.doDeleteArticle(item.extra.article.articleId,function (response) {
                Toast.success("Research deleted successfully!");
                $rootScope.$broadcast("research-refresh",{type:"delete"});
            },function () {
                console.log("Error deleting article");
            })
        }, function() {

        });
    };

    thiz.canDelete = function(item){
        if(item && item.extra && item.extra.article && item.extra.article.editStatus !== null){
            return item.extra.article.editStatus < Publish.EditStatus.COMPLETED;
        }
        return false;
    };

    thiz.onPublish = function (item,ev) {
        console.log(item);
        var confirm = $mdDialog.confirm()
            .title('Are you sure you want to publish this research?')
            .textContent('')
            .targetEvent(ev)
            .ok('Publish')
            .cancel('Cancel');

        return $mdDialog.show(confirm).then(function() {
            var a = angular.copy(item.extra.article);
            a.editStatus = Publish.EditStatus.COMPLETED;
            BackendService.doUpdateArticle(a,function (response) {
                Toast.success("Research published successfully!");
                $rootScope.$broadcast("research-refresh",{type:"publish"});
            },function () {
                console.log("Error publishing article.");
            })
        }, function() {

        });
    };

    thiz.canPublish = function(item){
        if(item && item.extra && item.extra.article && item.extra.article.editStatus !== null){
            return item.extra.article.editStatus < Publish.EditStatus.COMPLETED;
        }
        return false;
    };

    return thiz;
});
