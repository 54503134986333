'use strict';

/**
 * Used for Caching Users data.
 */
app.service('UserService', [
    'BackendService','$mdDialog','Session','Toast','$rootScope',
    function (BackendService,$mdDialog,Session,Toast,$rootScope) {
        var service = this;

        service.onAddEducation = function (ev) {
            return service.onUpdateEducation(null,ev);
        };

        service.onUpdateEducation = function (item,ev) {
            return $mdDialog.show({
                controller: 'educationUpdateCtrl',
                templateUrl: 'app/module/user/education-update.dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentEducation: angular.copy(item)
                }
            }).then(function (answer) {
                console.log(answer);
            });
        };

        service.onDeleteEducation = function (item,ev) {
            var user = Session.getSession().getUser();
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this education?')
                .textContent('')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function() {
                BackendService.doDeleteEducation(user.id,item.id,function (response) {
                    Toast.success("Education deleted successfully!");
                    $rootScope.$broadcast("profile-refresh",{type:"education"});
                },function () {
                    console.log("Error deleting education");
                })
            }, function() {

            });
        };

        service.onAddExperience = function (ev) {
            return service.onUpdateExperience(null,ev);
        };

        service.onUpdateExperience = function (item,ev) {
            return $mdDialog.show({
                controller: 'experienceUpdateCtrl',
                templateUrl: 'app/module/user/experience-update.dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentExperience: angular.copy(item)
                }
            }).then(function (answer) {
                console.log(answer);
            });
        };

        service.onDeleteExperience = function (item,ev) {
            var user = Session.getSession().getUser();
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this experience?')
                .textContent('')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function() {
                BackendService.doDeleteExperience(user.id,item.id,function (response) {
                    Toast.success("Experience deleted successfully!");
                    $rootScope.$broadcast("profile-refresh",{type:"experience"});
                },function () {
                    console.log("Error deleting experience");
                })
            }, function() {

            });
        };

        service.onFollowDialog = function (ev) {
            return $mdDialog.show({
                controller: 'followCtrl',
                templateUrl: 'app/module/user/follow.dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {}
            }).then(function (answer) {
                console.log(answer);
            });
        };

        service.onUnfollow = function (item,ev) {
            var user = Session.getSession().getUser();
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to unfollow '+item.user.firstName + ' '+item.user.lastName+'?')
                .textContent('')
                .targetEvent(ev)
                .ok('Unfollow')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function() {
                BackendService.doUnfollowUserReq(user.id,item.user.userId,function (response) {
                    Toast.success("User unfollowed successfully!");
                    $rootScope.$broadcast("profile-refresh",{type:"following"});
                },function () {
                    console.log("Error unfollow");
                })
            }, function() {

            });
        };


    }
]);