'use strict';

app.controller('messagesCtrl', [
    '$scope', 'ChatService', 'UserCacheService', 'I8XLogger', '$stateParams', '$state',
    function ($scope, ChatService, UserCacheService, I8XLogger, $stateParams, $state) {
        var logger = I8XLogger.loggerFor('MessagesCtrl');
        $scope.getContacts = ChatService.getContacts;
        $scope.userCache = UserCacheService;
        $scope.chat = null;
        $scope.isLoading = true;

        $scope.openChat = function (userId) {
            $scope.chat = ChatService.getActiveChat(userId);
            $state.go('.', {
                userId: userId
            }, {
                location: 'replace' // used to replace last history record in browser -> so back button is not used to navigate between users
            });
            loadChatHistory();
        };

        $scope.$watch(ChatService.getContacts, function () {
            loadContactsLastMessages();
            updateIsLoading();
        });

        $scope.getActiveChatTitle = function () {
            if ($scope.chat !== null) {
                var userData = UserCacheService.getUser($scope.chat.userId);
                if (userData.isLoaded) {
                    return userData.user.firstName + ' ' + userData.user.lastName;
                }
            }

            return 'Loading...';
        };

        var loadContactsLastMessages = _.debounce(function () {
            $scope.getContacts().forEach(function (contact) {
                ChatService.loadChatLastMessage(contact.userId, function (messages) {
                    var message = _.last(messages);
                    if (message) {
                        UserCacheService.getUser(contact.userId).lastMessage = message.text;
                    }
                });
            });
        }, 500);

        function loadChatHistory() {
            ChatService.loadChatHistory($scope.chat.userId, function () {
                // history loaded, just refresh data, nothing to do here
            });
        }

        $scope.onInputMessage = function (text) {
            console.log('on input message', text);
            sendMessage(text);
        };

        function sendMessage(text) {
            logger.debug('Sending', text);

            ChatService.sendMessage($scope.chat.userId, text);
        }

        function updateIsLoading() {
            if(ChatService.areContactsLoaded()) {
                $scope.isLoading = false;
            }

            if($scope.isLoading === false) {
                // we are not anymore loading
                onContactsLoaded();
            }
        }

        /**
         * Called when Users Contacts are loaded.
         */
        function onContactsLoaded() {
            var userId = $stateParams.userId;
            if (userId) {
                // we have selected user
                $scope.openChat(userId);
            } else {
                // we don't have selected user, let's select first one from the list
                var contacts = $scope.getContacts();
                if (contacts && contacts.length > 0) {
                    $scope.openChat(contacts[0].userId);
                }
            }
        }

        updateIsLoading();
    }
]);

app.controller('chatDebugCtrl', [
    '$scope', 'ChatService', 'UserCacheService',
    function ($scope, ChatService, UserCacheService) {
        $scope.userCache = UserCacheService;
        $scope.getChatId = ChatService.getChatId;

        $scope.jid = 'dario.snajder@infinitcodex.com';

        $scope.getContacts = ChatService.getContacts;

        $scope.doSubscribe = function () {
            ChatService.subscribe($scope.jid);
        };

        $scope.doUnsubscribe = function () {
            ChatService.subscribe($scope.jid);
        };

        $scope.doAddToContacts = function () {
            ChatService.addUserToContacts($scope.jid);
        };

        $scope.doRemoveFromContacts = function () {
            ChatService.removeUserFromContacts($scope.jid);
        };

        $scope.doAuthorize = function () {
            ChatService.authorize($scope.jid);
        };

        $scope.doUnauthorize = function () {
            ChatService.unauthorize($scope.jid);
        };
    }
]);