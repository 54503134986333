'use strict';

app.directive('chatboxContainer', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'app/pages/chat/chatbox-container.html',
            replace: true
        };
    }
]);