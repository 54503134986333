/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("searchInput", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'KeywordModel', '$q','QueryModel','BackendService',
    function ($timeout, $state, $mdDialog, $window, config, KeywordModel, $q,QueryModel,BackendService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/search/search-input.directive.html',
            scope: {
                main: '='
            },
            link: function ($scope, $elm, $attrs) {
                $scope.settings = config.searchInput;
                $scope.sources = KeywordModel.getSources($scope.main.keywords.type);
                $scope.shortSources = _.pluck($scope.sources,"short");


                var sources = KeywordModel.getSources();
                var thisYear = (new Date).getFullYear()
                var s1 = _.findWhere(sources,{short:'y'});
                var rangeYears = [{ name:(thisYear), text:"This year", source: s1},
                    { name:(thisYear-1), text:"Last year",source: s1},
                    { name:(thisYear-5)+":"+(thisYear), text:"Last 5 years",source: s1},
                    { name:(thisYear-10)+":"+(thisYear), text:"Last 10 years",source: s1},
                    { name:(thisYear-30)+":"+(thisYear), text:"Last 30 years",source: s1}];

                /**
                 * SEARCH
                 */
                $scope.search = {
                    transformChip: function ($chip) {
                        var type = "m";

                        if (typeof $chip === 'string') {
                            return new KeywordModel($chip + "_" + type);
                        }else if($chip.help === true){
                            return new KeywordModel("h:"+$chip.short);
                        } else {
                            var source = $chip.source.short;
                            var name = $chip.name;
                            return new KeywordModel(source + ":" + name + "_" + type);
                        }
                    },
                    onAdd: function ($chip) {
                        if($chip.source.short === 'h'){
                            this.searchText = $chip.word+":";
                        }
                        $scope.main.keywords.setValid();
                        $scope.main.redirect();
                        scrollDown();
                        return false;
                    },
                    onRemove: function ($chip) {
                        $scope.main.keywords.setValid();
                        $scope.main.redirect();
                        return false;
                    },
                    onSelect: function ($chip) {
                        $scope.main.keywords.nextType($chip);
                        $scope.main.redirect();
                        return false;
                    },
                    selectedItem: undefined,
                    searchText: undefined,
                    deferred: undefined,
                    getSearch: function (word) {
                        if(word === ""){
                            return _.each($scope.sources,function(item){ item.help = true; });
                        } else if(word.startsWith("y:") || word.startsWith("d:") || word.startsWith("t:")){
                            if(word.trim().length>2 || !_.contains($scope.shortSources,word[0])){
                                return [];
                            }
                            switch(word){
                                case "y:": return rangeYears;
                                default: return [];
                            }
                        }else{
                            var model = new KeywordModel(word);
                            if(!_.contains($scope.shortSources,model.source.short)){
                                return [];
                            }else{
                                this.deferred = $q.defer();
                                var dto = {
                                    value: angular.isDefined(model.word) ? model.word.toLowerCase() : undefined,
                                    top: $scope.settings.searchSize,
                                    type: source2type(model.source.short, model.soureProvidedInWord)
                                };
                                var d = this.deferred;
                                //ThriftHelper.sendRequest(new SearchVocabulary2Req(dto),
                                //    MsgType.SEARCH_VOCABULARY2_REQ, config.calls.WS_WORD_SEARCH);
                                BackendService.doSearchVocabularyReq(dto, function (response, message) {
                                    console.log(response);
                                    if(_.isObject(response) && _.isArray(response.keywords)){
                                        var results = response.keywords;
                                        _.each(results, function (element) {
                                            element.source = model.source;
                                        });
                                        d.resolve(results);
                                    }
                                    d.resolve([]);
                                }, function (error) {
                                    console.log('Vocabulary error: ', error);
                                    d.resolve([]);
                                });
                                return this.deferred.promise;
                            }

                        }
                        return [];
                    }

                };

                var mdChipsElm = $elm.find('md-chips');
                var mdChipsCtrl = mdChipsElm.controller('mdChips');
                mdChipsElm.on('focus', function () {
                    scrollDown();
                });

                var scrollDown = function(){
                    $timeout(function(){
                        //$elm.scrollTop($elm[0].scrollHeight);
                    });
                };
                scrollDown();

                /**
                 * Converts KeywordModel source into Thrift's KeywordType.
                 *
                 * @param source The source
                 * @param soureProvidedInWord The flag that indicates if sourece was provided in keyword
                 * @returns {number} the Keyword Type
                 */
                function source2type(source, soureProvidedInWord) {
                    if (!soureProvidedInWord) {
                        // if source was not provided in word meaning we search for ALL
                        return Search.SearchDataType.KEYWORD;
                    }
                    switch (source) {
                        case 'w':
                            return Search.SearchDataType.KEYWORD;
                        case 'a':
                            return Search.SearchDataType.AUTHOR;
                        case 'o':
                            return Search.SearchDataType.ORG;
                        case 's':
                            return Search.SearchDataType.ASSIGNEE;
                        case 'f':
                            return Search.SearchDataType.FOS;
                        default:
                            return Search.SearchDataType.KEYWORD;
                    }
                }

                /**
                 * Converts Keyword Type into KeywordModel
                 * @param type The type
                 * @returns {*}
                 */
                function type2source(type) {
                    switch (type) {
                        case Search.SearchDataType.KEYWORD:
                            return 'w';
                        case Search.SearchDataType.AUTHOR:
                            return 'a';
                        case Search.SearchDataType.ORG:
                            return 'o';
                        case Search.SearchDataType.ASSIGNEE:
                            return 's';
                        case Search.SearchDataType.FOS:
                            return 'f';
                        default:
                            return 'w';
                    }
                }
            }
        };
    }]);
