
app.directive("researchItemComments", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'ResearchItemModel', '$rootScope','BackendService','ResearchItemService','Toast',
    'Session','UserCacheService','CommentService','EntityCacheService','CommentModel',
    function ($timeout, $state, $mdDialog, $window, config, ResearchItemModel, $rootScope,BackendService,ResearchItemService,Toast,
              Session,UserCacheService,CommentService,EntityCacheService,CommentModel) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/research-item/research-item-comments.directive.html',
            scope: {
                researchItem: '='
            },
            link: function ($scope, $element, $attrs) {
                $scope.CommentService = CommentService;

                $scope.loading = true;
                $scope.status = {
                    first: true,
                    showLoadMore: false,
                    pageState: null,
                    pageSize: 20
                };
                $scope.comments = [];


                $scope.user = Session.getSession().getUser();
                $scope.now = new Date();

                var pushComments = function(comments){
                    var arr = [];
                    for(var i = 0; i < comments.length; i++){
                        arr.push(EntityCacheService.addLoadedEntity(
                            comments[i].commentId,Publish.PublishType.COMMENT,new CommentModel(comments[i]),true));
                    }

                    $scope.comments = arr.reverse().concat($scope.comments);
                };

                $scope.getComments = function (first) {
                    $scope.loading = true;
                    // if(first) {
                    //     $scope.comments = [];
                    //     BackendService.doGetCommentReq($scope.researchItem.data.commentIds,
                    //         function (response) {
                    //             console.log("Success in getComments", response);
                    //             pushComments(response.comments);
                    //             if (response.comments.length > 9) {
                    //                 $scope.status.showLoadMore = true;
                    //             }
                    //             $scope.loading = false;
                    //         }, function (error) {
                    //             console.log("Error in getComments", error);
                    //             $scope.loading = false;
                    //         });
                    // }else{
                        var ps = $scope.status.pageState;
                        BackendService.doGetCommentForEntityReq($scope.researchItem.entityId,
                            $scope.status.pageSize,$scope.status.pageState,
                            function (response) {
                                console.log("Success in getComments2", response);
                                if(ps === null){
                                    $scope.comments = [];
                                }
                                pushComments(response.comments);
                                $scope.status.pageState = response.pageState;
                                if ($scope.status.pageState === null) {
                                    $scope.status.showLoadMore = false;
                                }
                                $scope.loading = false;
                            }, function (error) {
                                console.log("Error in getComments2", error);
                                $scope.loading = false;
                            });
                    // }
                };
                $scope.getComments(true);

                $scope.form = {};
                $scope.user = Session.getSession().getUser();
                $scope.addText = "";
                $scope.onAddComment = function (ev) {
                    if ($scope.addText.length > 0) {
                        var c = {
                            commentId: null,
                            userId: $scope.user.id,
                            entityId: $scope.researchItem.entityId,
                            entityType: Publish.PublishType.ARTICLE,
                            text: $scope.addText
                        };
                        BackendService.doUpdateCommentReq(c,
                            function (response) {
                                console.log("Success in updateComment", response);
                                Toast.success("Comment was successfully added.");
                                $scope.addText = "";
                                c.commentId = response.commentId;
                                c.updatedTime = response.updatedTime;
                                c.createdTime = response.updatedTime;
                                $scope.researchItem.data.commentIds.push(c.commentId);
                                $scope.comments.push(EntityCacheService.addLoadedEntity(
                                    c.commentId,Publish.PublishType.COMMENT,new CommentModel(c),true));
                            }, function (error) {
                                console.log("Error updateComment", error);
                            });
                    }
                };
            }
        };
}]);
