'use strict';

app.service('SearchListService', [
    'BackendService','ResearchItemModel','EntityCacheService',
    function (BackendService,ResearchItemModel,EntityCacheService) {
        var SearchList = function(scope){
            this.scope = scope;
            this.PAGE_SIZE = 20;
            this.BATCH_SIZE = this.PAGE_SIZE;
            this.MAX_SIZE = 10000;
            this.update();
        };

        SearchList.prototype.update = function(){
            this.data = [];
            this.busy = true;
            this.total = 0;
            this.page = 0;
            this.loadMore(true);
        };

        SearchList.prototype.getRealIndex = function(index){
            return index + this.page*this.PAGE_SIZE;
        };

        SearchList.prototype.getPageFromRealIndex = function(index){
            return Math.floor(index/this.PAGE_SIZE);
        };

        SearchList.prototype.getPageIndexFromRealIndex = function(index){
            return index%this.PAGE_SIZE;
        };

        SearchList.prototype.canPrevPage = function () {
            return this.page > 0;
        };

        SearchList.prototype.prevPage = function () {
            if(this.canPrevPage()) {
                this.page--;
                this.data = [];
                this.loadMore();
            }
        };

        SearchList.prototype.firstPage = function () {
            if(this.canPrevPage()) {
                this.page = 0;
                this.data = [];
                this.loadMore();
            }
        };

        SearchList.prototype.canNextPage = function () {
            //return true;
            return (this.page+1)*this.PAGE_SIZE < Math.min(this.MAX_SIZE,this.total);
        };

        SearchList.prototype.nextPage = function () {
            if(this.canNextPage()){
                this.page++;
                this.data = [];
                this.loadMore();
            }
        };

        SearchList.prototype.getPageSize = function () {
            return Math.max(0,Math.min(this.PAGE_SIZE, Math.min(this.total, this.MAX_SIZE)-this.page*this.PAGE_SIZE));
        };


        SearchList.prototype.showPagger = function () {
            //return true;
            return (this.total > 0 && !this.busy && this.data.length === this.getPageSize());
        };

        SearchList.prototype.loadMore = _.debounce(function (first) {
            var thiz = this;
            var start = this.getRealIndex(this.data.length);
            console.log("next search data:"+ start);
            if(this.data.length >= this.PAGE_SIZE ||
                (!first && (this.busy===true || start >= this.total))){
                return;
            }
            console.log("next success search data:"+ start);
            this.busy = true;
            var dto = thiz.scope.control.keywords.toDto(start, this.BATCH_SIZE, {
                sortBy: thiz.scope.control.sortBy.selected.id
            });

            BackendService.doSearchEs(dto, function (data, message) {
                var pageIndex = thiz.getPageIndexFromRealIndex(data.start);
                console.log("recived search data:"+pageIndex);
                if(thiz.getPageFromRealIndex(data.start) !== thiz.page){
                    console.log("wrong data!");
                    return;
                }
                for (var i = 0; i < data.documents.length; i++) {
                    var d = data.documents[i];
                    thiz.data[pageIndex+i] = EntityCacheService.addLoadedEntity(
                        d.id,Publish.PublishType.ARTICLE,new ResearchItemModel(d),true);
                }

                if(data.start === 0){
                    thiz.total = data.total;
                }
                thiz.busy = false;
            }, function (error) {
                console.log('Search error: ', error);
                thiz.busy = false;
            });
        },300);

        return SearchList;
    }
]);