
/* global angular, app */

app.factory('ResearchItemModel', [ 'UserCacheService',
    function(UserCacheService) {

        /**
         * Create new ResearchItemModel.
         * @param object represents ResearchItemModel
         * @constructor
         */
        
        function ResearchItemModel(object,extra) {
            if(!(object instanceof Object)) object = {};
            if(!(extra instanceof Object)) extra = {};
            this.id = object.id;
            this.title = object.title;
            this.url = object.url;
            this.url = composeUrl(object);
            this.type = object.type;
            this.summary = object.summary;
            this.authors = object.authors;
            if(!_.isArray(this.authors)) this.authors = [];
            this.authorsString = getAuthorString(this.authors);
            this.assignee = object.assignee;
            this.issn = object.issn; 
            this.keywords = object.keywords;
            if(!_.isArray(this.keywords)) this.keywords = [];
            this.year = object.year;
            this.date = object.date;
            this.dateObj = getDateObj(this.date);
            this.journal = object.journal;
            this.references = object.references;
            if(!_.isArray(this.references)) this.references = [];
            this.source = object.source;
            this.volume = object.volume;
            this.issue = object.issue;
            this.doi = object.doi;
            this.applicationDate = object.publicationDate; // wrong index name
            this.priorityDate = object.priorityDate;
            this.urls = object.urls;
            if(!_.isArray(this.urls)) this.urls = [];
            this.fos = object.fos;
            if(!_.isArray(this.fos)) this.fos = [];
            this.claimedAuthorIds = object.claimedAuthorIds;
            if(!_.isArray(this.claimedAuthorIds)) this.claimedAuthorIds = [];
            this.commentIds = object.commentIds;
            if(!_.isArray(this.commentIds)) this.commentIds = [];
            this.pageEnd = object.pageEnd;
            this.pageStart = object.pageStart;
            this.publisher = object.publisher;
            this.counters = object.counters===null? {likes:0,dislikes:0,counters:0}:object.counters;
            if(this.counters.dislikes === null){
                this.counters.dislikes = 0;
            }
            this.extra = extra;
        }


        ResearchItemModel.fromPublishArticle = function(object){
            if(angular.isArray(object.authors)){
                object.authors.forEach(function (a) {
                    if(a.userId !== null){
                        a.user = UserCacheService.getUser(a.userId);
                    }
                })
            }

            return new ResearchItemModel(object,{article: object});
        };
        
        var composeUrl = function(object) {
            if(angular.isDefined(object.url) && object.url !== "" ){
                return object.url;
            }
            if (angular.isDefined(object.urls) && object.urls.length > 0) {
                return _.first(object.urls);
            }
            return "";
        };
        
        ResearchItemModel.prototype.getUrl = function() {
            composeUrl(this);
            /*if(angular.isDefined(this.url) && this.url !== "" ){
                return this.url;
            }
            if(!angular.isDefined(this.type) || (this.type === "article")){
                return "http://dx.doi.org/"+this.id;
            }
            return "";*/
        };
        
        ResearchItemModel.prototype.exportBibTex = function() {
            var exportText = "@article{" + this.id + ",\n"+
                " title = \"" + this.title + "\",\n" +
                " author = \"" + getAuthorBibtexString(this.authors) +"\",\n";

            if(this.source){
                exportText += " journal = \"" + this.source + "\",\n";
            }
            if(this.year){
                exportText += " year = " + this.year + ",\n";
            }
            if(this.volume){
                exportText += " volume = \"" + this.volume + "\",\n";
            }
            if(this.issue){
                exportText += " number = \"" + this.issue + "\",\n";
            }
            if(this.url){
                exportText += " doi = \"" + this.url +"\", \n" + " url = \"" + this.url +"\", \n";
            }
            if(this.abstract){
                exportText += " abstract = \"" + this.abstract + "\", \n";
            }

            exportText += "}\n";

            return exportText;
        };
        
        ResearchItemModel.prototype.exportRIS = function() {
            var authors = "";
            for(var i in this.authors){
                authors += "AU  - " + this.authors[i].name+"\n";
            }
            
            var exportText = "TY  - JOUR\n" +
                "T1  - " + this.title + " \n" +
                authors;
            if(this.source){
                exportText += "JO  - " + this.source + "\n";
            }
            if(this.year){
                exportText += "PY  - " + this.year + "\n";
            }
            if(this.volume){
                exportText += "VL  - " + this.volume + "\n";
            }
            if(this.issue){
                exportText += "IS  - " + this.issue + "\n";
            }
            if(this.url){
                exportText += "DO  - " + this.url + " \n" + "UR  - " + this.url + " \n";
            }
            if(this.abstract){
                exportText += "AB  - " + this.abstract + " \n";
            }

            exportText +=    "ER  - \n";

            return exportText;
        };

        ResearchItemModel.prototype.exportPDF = function() {
            var arr = [
                { text: this.title , style: 'title'},
                { text: this.authorsString, style: 'author'}
            ];
            if(this.url){
                arr[0].link = this.url;
            }

            var d = [];
            if(this.source){
                d.push(this.source);
            }
            if(this.year){
                d.push(this.year);
            }
            if(this.volume){
                d.push(this.volume);
            }
            if(this.issue){
                d.push(this.issue);
            }
            if(d.length > 0) {
                arr.push({ text: d.join(", "), style: "details" });
            }

            if(this.abstract){
                arr.push({ text: this.abstract, style: 'abstract' });
            }
            if(this.keywords && this.keywords.length > 0){
                arr.push({ text: ["Keywords: ", this.keywords.join(", ")], style: 'keywords', margin : [0,5,0,0] });
            }

            return arr;
        };
        
        ResearchItemModel.prototype.clone = function() {
            var cloned = angular.copy(this);
            
            // Remove as it's angular specific.
            delete cloned.$$hashKey;

            return cloned;
        };

        ResearchItemModel.prototype.getAuthorString = function () {
            return getAuthorString(this.authors);
        };
        
        var getAuthorString = function(authors){
            var arr = [];
            for(var i in authors){
                var a = authors[i];
                if(a instanceof Search.Author) {
                    arr.push(a.name);
                }else if(a instanceof Publish.Author) {
                    if(a.userId === null){
                        arr.push(a.firstName + " " + a.lastName);
                    }else if(a.user.user){
                        arr.push(a.user.user.firstName + " " + a.user.user.lastName);
                    }
                }
            }
            return arr.join(", ");
        };
        
        var getAuthorBibtexString = function(authors){
            var arr = [];
            for(var a in authors){
                arr.push(authors[a].name);
            }
            return arr.join(" and ");
        };
        
        var getDateObj = function(date){
            if(!angular.isString(date)){
                return {d:'',m:'',y:''};
            }
            var arr = date.split("-");
            if(arr.length<3){
                return {d:'',m:'',y:''};
            }
            return {d:arr[2],m:arr[1],y:arr[0]}; 
        };

        // Return constructor
        return ResearchItemModel;
    }
]);