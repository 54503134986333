
app.controller('entityCounterListCtrl', ['$scope', 'Session','currentEntity','currentType','Toast','$mdDialog',
    'BackendService','UserCacheService',
    function ($scope, Session, currentEntity,currentType,Toast,$mdDialog,
              BackendService,UserCacheService) {

        $scope.loading = true;
        $scope.list = [];
        $scope.pageState = null;

        var texts = {
            1: {
                title: "Likes",
                nodata: "No likes yet..."
            },
            2: {
                title: "Dislikes",
                nodata: "No dislikes yet..."
            },
            3: {
                title: "Citations",
                nodata: "No citations yet..."
            }
        };

        $scope.text = texts[currentType];

        $scope.getUsers = function () {
            $scope.loading = true;
            BackendService.doGetUserIdsForSocialOperationReq(currentEntity.entityId,currentType,20,$scope.pageState,
                function (response) {
                    console.log("Success in getUserIdsForSocialOperationReq", response);
                    if(angular.isArray(response.users)){
                        response.users.forEach(function (item) {
                            item.user = UserCacheService.getUser(item.userId);
                        });
                        $scope.list = $scope.list.concat(response.users);
                    }
                    $scope.pageState = response.pageState;
                    $scope.loading = false;
                }, function (error) {
                    console.log("Error in getUserIdsForSocialOperationReq", error);
                    $scope.loading = false;
                });
        };
        $scope.getUsers();


        $scope.cancel = function () {
            $mdDialog.cancel();
        };





    }]);
