/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("metamaskCard", ['$timeout', '$state', '$mdDialog',
    '$window', 'config', 'Web3Service', 'Session','BackendService','Toast','$sce',
    function ($timeout, $state, $mdDialog, $window, config, Web3Service, Session,BackendService,Toast,$sce) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/module/content/metamask-card.directive.html',
            scope: {},
            link: function (scope, element, attrs) {
                scope.web3Service = Web3Service;
                var user = Session.getSession().getUser();
                var statuses = scope.web3Service.getAllStatuses();

                scope.scenarios = {};
                scope.scenarios[statuses.UNKNOWN] = {
                    text: $sce.trustAsHtml("Still connecting to MetaMask..."),
                    // buttonText: "Refresh",
                    // onClick: function ($event) {
                    //     scope.web3Service.checkWeb3();
                    // }
                };
                scope.scenarios[statuses.NO_METAMASK] = { text: $sce.trustAsHtml("No MetaMask installed. Please install <a href='https://metamask.io' target='_blank'>Metamask.io.</a>")};
                scope.scenarios[statuses.WRONG_RPC] = { text: $sce.trustAsHtml("Please switch MetaMask to https://rpc.infinitcodex.com")};
                scope.scenarios[statuses.DONE] = { text: $sce.trustAsHtml("You are registered on blockchain"),
                    buttonText: "Show my data",
                    onClick: function($event){
                        var statuses = {
                            0: "0 - Unregistered",
                            1: "1 - Registered",
                            2: "2 - Banned"
                        };
                        scope.web3Service.doGetUserData().then(function(response){
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .clickOutsideToClose(true)
                                    .title('Your data on blockchain.')
                                    .htmlContent('<div>First name: '+response[0]+'</div>'+
                                        '<div>Last name: '+response[1]+'</div>'+
                                        '<div>Status: '+statuses[response[2].toNumber()]+'</div>')
                                    .ariaLabel('Your data on blockchain')
                                    .ok('Got it')
                                    .targetEvent($event)
                            );
                        },function (error) {
                            console.log("Error in onCheck",error);
                        });
                    }
                    };
                scope.scenarios[statuses.NOT_LOGGED_IN] = {
                    text: $sce.trustAsHtml("Please log in to MetaMask!")
                };
                scope.scenarios[statuses.WRONG_ADDRESS] = {
                    text: $sce.trustAsHtml("Please switch MetaMask account to infinitcodex account \""+user.bcAddress+"\"!")
                };
                scope.scenarios[statuses.PENDING_REGISTRATION] = {
                    text: $sce.trustAsHtml("Your registration is pending. This can take a while...")
                };
                scope.scenarios[statuses.NOT_REGISTERED] = {
                    text: $sce.trustAsHtml("Please register on blockchain"),
                    buttonText: "Register",
                    onClick: function($event){
                        scope.web3Service.doRegisterUser().then(function(response){
                            console.log("Success in metamaskCard",response);
                            Toast.success("Registration on blockchain successful");
                            scope.$apply();
                        },function (error) {
                            console.log("Error in metamaskCard");
                            Toast.error("Registration on blockchain failed");
                            scope.$apply();
                        });
                    },
                    // button2Text: "Check",
                    // onClick2: function($event){
                    //     scope.web3Service.doIsRegisteredUser().then(function(response){
                    //         console.log("Success in onCheck",response);
                    //         if(response){
                    //             Toast.info("You are registered on blockchain!")
                    //         }else{
                    //             Toast.info("You are not registered on blockchain yet. This can take a while...")
                    //         }
                    //     },function (error) {
                    //         console.log("Error in onCheck",error);
                    //     });
                    // }
                };

                scope.scenarios[statuses.UNKNOWN_ADDRESS] = {
                    text: $sce.trustAsHtml("Please select MetaMask account for infinitcodex blockchain"),
                    buttonText: "Select",
                    onClick: function($event){
                        var address = scope.web3Service.getCurrentAccount();
                        var confirm = $mdDialog.confirm()
                            .title('Would you like to use MetaMask account "'+address+'"?')
                            .textContent('If you want to select other account, click cancel and switch account in MetaMask.')
                            .targetEvent($event)
                            .ok('Confirm')
                            .cancel('Cancel');

                        $mdDialog.show(confirm).then(function() {
                            var dto = angular.copy(user);
                            dto.bcAddress = address;
                            dto.userId = dto.id;
                            BackendService.doUpdateUser(dto,function (response) {
                                console.log("User updated!",response);
                                user.bcAddress = address;
                                scope.web3Service.checkWeb3(scope);
                                Toast.success("MetaMask account saved successful!");
                            },function (error) {
                                console.log("no",error);
                                Toast.error("Saving MetaMask account failed!");
                            })
                        });
                    }
                };

            }
        };
    }]);
